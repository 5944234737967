import React, { KeyboardEvent, SyntheticEvent, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment } from '@material-ui/core';
import { injectStores } from '../../../stores/factories/store-utils';
import { TypeApplicationStore } from '../../../stores/application-store';
import { HEADER_TOOLS } from '../../../constants';

import { MODULES } from '../../../modules/modules';

import TextField from '../../controls/text-field/text-field';

import './search-bar.scss';

const TOOL_NAME = HEADER_TOOLS.SEARCH_BAR;

const initialFormData = {
    searchTerm: '',
};

const useStyles = makeStyles(() => ({
    underline: {
        '&&&:before': { borderBottom: 'none' },
        '&&:after': { borderBottom: 'none' },
    },
}));

interface FormData {
    searchTerm: string;
}

interface Props {
    ApplicationStore: TypeApplicationStore;
    displayMagnifyingGlass?: boolean;
    placeHolderI18n?: string;
}

function SearchBar(props: Props) {
    const { t } = useTranslation();
    const {
        ApplicationStore: {
            headerTools: { openedHeaderTool },
        },
        displayMagnifyingGlass = false,
        placeHolderI18n = 'general:module.search-function.search-bar-placeholder',
    } = props;
    const classes = useStyles();

    const [formData, setFormData] = React.useState<FormData>(initialFormData);

    const searchRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (openedHeaderTool === TOOL_NAME) {
            setTimeout(() => searchRef?.current?.focus(), 300);
        }
    }, [openedHeaderTool]);

    const handleChange = (fieldId: keyof typeof initialFormData, event: SyntheticEvent): void => {
        const target = event.target as HTMLInputElement;
        setFormData({ ...formData, [fieldId]: target.value });
    };

    return (
        <div className='search-bar'>
            <TextField
                fullWidth
                style={{ backgroundColor: 'inherit' }}
                inputRef={searchRef}
                id='searchTerm'
                placeholder={t(placeHolderI18n)}
                value={formData.searchTerm}
                onChange={(event) => handleChange('searchTerm', event)}
                onKeyPress={(event: KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === 'Enter') {
                        const searchArray = [
                            MODULES.GENERAL.routes.FCRM_SEARCH.hardPath,
                            encodeURIComponent(formData.searchTerm),
                        ];
                        window.open(searchArray.join(''), '_self');
                    }
                }}
                variant='standard'
                InputProps={{
                    classes: { underline: classes.underline },
                    startAdornment: displayMagnifyingGlass && (
                        <InputAdornment position='start'>
                            <i className='material-icons icon-style'>search</i>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
}

export default injectStores(['ApplicationStore'])(SearchBar);
