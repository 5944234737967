import { IAnyModelType, Instance, types } from 'mobx-state-tree';

const TextLinksModel = types.model('TextLinksModel', {
    text: types.string,
    links: types.maybeNull(types.frozen({})),
    imageUrl: types.maybeNull(types.string),
});
export type TypeTextLinksModel = Instance<typeof TextLinksModel>;

const InvoiceDescriptionModel = types.compose(
    TextLinksModel,
    types.model('InvoiceDescriptionModel', {
        type: types.union(types.literal('description'), types.literal('error')),
    })
);
export type TypeInvoiceDescriptionModel = Instance<typeof InvoiceDescriptionModel>;

const InvoiceCtaModel = types.model('InvoiceCtaModel', {
    type: types.union(types.literal('primary'), types.literal('secondary')),
    label: types.string,
    link: types.maybeNull(types.string),
});
export type TypeInvoiceCtaModel = Instance<typeof InvoiceCtaModel>;

const InvoiceNoticeModel = types.compose(
    TextLinksModel,
    types.model('InvoiceNoticeModel', {
        type: types.literal('notice'),
    })
);
export type TypeInvoiceNoticeModel = Instance<typeof InvoiceNoticeModel>;

const InvoiceCtasModel = types.model('InvoiceCtasModel', {
    type: types.literal('ctas'),
    ctas: types.array(InvoiceCtaModel),
    notice: types.maybeNull(InvoiceNoticeModel),
});
export type TypeInvoiceCtasModel = Instance<typeof InvoiceCtasModel>;

const InvoiceItemModel = types.model('InvoiceItemModel', {
    id: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    quantity: types.maybeNull(types.number),
    price: types.maybeNull(types.number),
    terms: types.array(types.string),
    itemDetails: types.array(types.union(InvoiceDescriptionModel, InvoiceCtasModel)),
    items: types.array(types.late((): IAnyModelType => InvoiceItemModel)),
});

const InvoiceModel = types.model('InvoiceModel', {
    items: types.array(InvoiceItemModel),
    amount: 0,
    cardInfo: types.frozen({}),
    cardType: types.maybeNull(types.string),
    currency: '',
    date: '',
    description: '',
    invoiceUrl: '',
    itemDetails: types.frozen({}),
});

export type TypeInvoiceItemModel = Instance<typeof InvoiceItemModel>;
export type TypeInvoiceItemDetailsModel = Instance<typeof InvoiceItemModel.properties.itemDetails>;
export type TypeInvoiceModel = Instance<typeof InvoiceModel>;
export default InvoiceModel;
