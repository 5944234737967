import { Instance, types } from 'mobx-state-tree';

const TransactionModel = types.model('TransactionModel', {
    amount: types.maybeNull(types.number),
    currency: types.maybeNull(types.string),
    date: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    transactionId: types.string,
    invoiceUrl: types.maybeNull(types.string),
});

export type TypeTransactionModel = Instance<typeof TransactionModel>;
export default TransactionModel;
