import React from 'react';

import './mini-loader.scss';

function MiniLoader() {
    return (
        <div className='mini-loader'>
            <span className='mini-loader__spinner' />
        </div>
    );
}

export default MiniLoader;
