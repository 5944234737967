/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

import ApplicationStoreInstance from '../application-store';

import { createApiAction } from '../../api/api-action-factory';
import { TypedStoreConfig } from '../factories/store';

import {
    TypeBlogCategoriesProperties,
    TypeCategoryDataPropeties,
} from './models/blog-category-model';
import ExternalInformationModel, {
    TypeExternalInformationModel,
} from './models/external-information-model';

import { isDev } from '../../utils/build-utils';

import { BLOG_LIVE_INFO, BLOG_POSTS_ENDPOINT } from '../../api/endpoints';
import BlogModel from './models/blog-category-model';
import { snakeCase } from '../../utils/utils';

export interface LocalizedCategories {
    id: string;
    label: string;
}
const BlogStore = TypedStoreConfig({
    modelName: 'BlogStore',
    properties: {
        isLoading: false,
        blogPosts: types.optional(
            types.array(ExternalInformationModel),
            [] as TypeExternalInformationModel[],
            [undefined, null]
        ),
        blogLiveInfo: types.maybeNull(BlogModel),
        blogCategories: types.frozen(['']),
    },
})
    .actions((self) => ({
        getBlogPosts: createApiAction(self, {
            endpoint: BLOG_POSTS_ENDPOINT,
            target: 'blogPosts',
            payloadDataLocationId: 'blogs',
            onTrigger(self) {
                self.blogPosts = null;
            },
            onLoad(self) {
                self.buildBlogCategoryCollection();
            },
        }),
        getLiveBlogCategories: createApiAction(self, {
            endpoint: BLOG_LIVE_INFO,
            target: 'blogLiveInfo',
        }),
    }))
    .actions((self) => ({
        retrieveLiveBlogCategories() {
            if (!self.blogLiveInfo) {
                self.getLiveBlogCategories();
            }
        },
        buildBlogCategoryCollection() {
            const activeBlogCategories: string[] = ['all'];
            self.blogPosts.forEach((post) => {
                post.tags.forEach((tag) => {
                    if (activeBlogCategories.indexOf(tag) == -1) {
                        activeBlogCategories.push(tag);
                    }
                });
            });

            self.blogCategories = activeBlogCategories;
        },
    }))
    .views((self) => ({
        activeLocalizedBlogCategories(): LocalizedCategories[] {
            const userLocale = snakeCase(
                ApplicationStoreInstance.userLocale
            ) as keyof TypeCategoryDataPropeties;

            const localizedBlogCategories: LocalizedCategories[] = [];

            self.blogCategories.forEach((category) => {
                localizedBlogCategories.push({
                    id: category as keyof TypeBlogCategoriesProperties,
                    label:
                        self.blogLiveInfo?.tags?.[category as keyof TypeBlogCategoriesProperties]?.[
                            userLocale
                        ] || '',
                });
            });
            return localizedBlogCategories;
        },
    }));

const BlogStoreInstance = BlogStore.create();

declare const window: Window &
    typeof globalThis & {
        BlogStore: typeof BlogStoreInstance;
    };

if (window && isDev()) {
    window.BlogStore = BlogStoreInstance;
}

export default BlogStore;
