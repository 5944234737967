import { Instance } from 'mobx-state-tree';

import { TypedStoreConfig } from './factories/store';

import CollectionStore from './product-stores/collection-store';
import LibraryStore from './product-stores/library-store';

import { isDev } from '../utils/build-utils';

const ProductStore = TypedStoreConfig({
    modelName: 'ProductStore',
    properties: {
        CollectionStore: CollectionStore,
        LibraryStore: LibraryStore,
    },
});

export type TypeProductStore = Instance<typeof ProductStore>;

const ProductStoreInstance = ProductStore.create({
    CollectionStore: {},
    LibraryStore: {},
});

if (window && isDev()) {
    (window as any).ProductStore = ProductStoreInstance;
}

export default ProductStoreInstance;
