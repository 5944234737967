import { Instance, types } from 'mobx-state-tree';

export const InvitationResponseModel = types.model('InvitationResponseModel', {
    success: types.boolean,
    message: types.string,
    messageType: types.string,
});

export type TypeInvitationResponseModel = Instance<typeof InvitationResponseModel>;
export default InvitationResponseModel;
