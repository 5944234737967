import { Instance, types } from 'mobx-state-tree';

import { TypedStoreConfig } from '../factories/store';

import { createApiAction } from '../../api/api-action-factory';

import { GET_COLLECTION_ENDPOINT } from '../../api/endpoints';

import { CollectionModel } from './models/collection-model';

const CollectionStore = TypedStoreConfig({
    modelName: 'CollectionStore',
    properties: {
        // for the time being, we store a single collection which will be the currently viewed collection. Ideally we would want to store multiple collection data to optimize
        // numbers of calls to the backend. This will be handled in a separated ticket: HUB-648
        collections: types.map(CollectionModel),
        errors: types.array(types.number),
    },
})
    .actions((self) => ({
        fetchCollection: createApiAction(self, {
            endpoint: GET_COLLECTION_ENDPOINT,
            target: 'collections',
            targetMapId: 'urltag',
            onTrigger: (self) => {
                self.clearErrors();
            },
            onError: (self, error: any) => {
                if (error) {
                    self.errors = [error];
                }
            },
        }),
    }))
    .actions((self) => ({
        clearErrors: () => {
            self.errors.clear();
        },
    }))
    .actions((self) => ({
        retrieveCollection: (collectionTag: string) => {
            self.clearErrors();
            if (!self.collections.get(collectionTag)) {
                self.fetchCollection({ urltag: collectionTag });
            }
        },
    }))
    .views((self) => ({
        getCollection: (collectionTag: string) => {
            return self.collections.get(collectionTag);
        },
        getErrors: () => {
            return self.errors;
        },
    }));

export type TypeCollectionStore = Instance<typeof CollectionStore>;
export default CollectionStore;
