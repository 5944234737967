import { lazy } from 'react';
import { typedRoutes } from './utils/module-interfaces';

import { PATHS } from './soft-paths/products';

export const ROUTES = typedRoutes({
    PRODUCTS: {
        ...PATHS.PRODUCTS,
        id: 'PRODUCTS',
        name: 'Products',
        i18nBase: 'products:wwise',
        i18nId: 'products:wwise.menu-label',
        displayInMenu: false,
    },
    WWISE: {
        ...PATHS.WWISE,
        id: 'WWISE',
        name: 'Wwise',
        i18nBase: 'products:wwise',
        i18nId: 'products:wwise.menu-label',
        component: lazy(() => import('../components/modules/wwise/wwise')),
    },
    STRATA: {
        ...PATHS.STRATA,
        id: 'STRATA',
        name: 'Strata',
        i18nBase: 'products:strata',
        i18nId: 'products:strata.menu-label',
        component: lazy(() => import('../components/modules/products/strata/strata')),
    },
    PLUGINS: {
        ...PATHS.PLUGINS,
        id: 'PLUGINS',
        name: 'Plugins',
        i18nBase: 'products:module.plugins',
        i18nId: 'products:module.plugins.menu-label',
    },
    WWISE_INTEGRATION: {
        ...PATHS.WWISE_INTEGRATION,
        id: 'WWISE_INTEGRATION',
        name: 'Wwise Integration',
        i18nBase: 'products:module.wwise-integration',
        i18nId: 'products:module.wwise-integration.menu-label',
        component: lazy(() => import('../components/modules/products/integrations/integrations')),
    },
    ADD_ONS: {
        ...PATHS.ADD_ONS,
        id: 'ADD_ONS',
        name: 'Add-Ons',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    INTEGRATION: {
        ...PATHS.INTEGRATION,
        id: 'INTEGRATION',
        name: 'Integration',
        i18nBase: 'products:module.integrations',
        i18nId: 'products:module.integrations.menu-label',
        displayInMenu: false,
    },
    STRATA_UPCOMING_COLLECTIONS: {
        ...PATHS.STRATA_UPCOMING_COLLECTIONS,
        id: 'STRATA',
        name: 'Strata',
        displayInMenu: false,
        i18nBase: 'products:strata',
        i18nId: 'products:strata.menu-label',
        component: lazy(() => import('../components/modules/products/strata/strata')),
    },
    SUPPORTED_PLATFORMS: {
        ...PATHS.SUPPORTED_PLATFORMS,
        id: 'SUPPORTED_PLATFORMS',
        name: 'Supported Platforms',
        i18nBase: 'products:module.supported-platforms',
        i18nId: 'products:module.supported-platforms.menu-label',
        displayInMenu: false,
    },
    CREATE_ACCOUNT: {
        ...PATHS.CREATE_ACCOUNT,
        id: 'CREATE_ACCOUNT',
        name: 'CreateAccount',
        i18nBase: 'products:module.creative-services',
        i18nId: 'products:module.creative-services.menu-label',
        displayInMenu: false,
    },
    REGISTER_COURSE: {
        ...PATHS.REGISTER_COURSE,
        id: 'REGISTER_COURSE',
        name: 'RegisterCourse',
        i18nBase: 'Not Necessary',
        i18nId: 'Not Necessary',
        displayInMenu: false,
    },
    REGISTER_PROJECT: {
        ...PATHS.REGISTER_PROJECT,
        id: 'REGISTER_PROJECT',
        name: 'RegisterProject',
        i18nBase: 'products:module.creative-services',
        i18nId: 'products:module.creative-services.menu-label',
        displayInMenu: false,
    },
    REAPER: {
        ...PATHS.REAPER,
        id: 'REAPER',
        name: 'REAPER',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    VST_PLUGIN: {
        ...PATHS.VST_PLUGIN,
        id: 'VST_PLUGIN',
        name: 'VST Plugin',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    IEM_PLUGIN: {
        ...PATHS.IEM_PLUGIN,
        id: 'IEM_PLUGIN',
        name: 'IEM Plugin',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
});

export const DEFAULT_ROUTE = ROUTES.WWISE;
