/* eslint-disable no-console */
import Cookies from 'js-cookie';
import axios, { AxiosRequestConfig, AxiosStatic } from 'axios';

import { isUndefined, omitBy } from 'lodash';

import { FetchOptions } from './api-interfaces';

import { APPLICATION_JSON, COOKIE_USER_LOCALE } from '../constants';
import { backendHostNameForBlog, isDev, isJakhub } from '../utils/utils';

const controller: Record<string, AbortController | null> = {};

export const fetchData = async (
    fetchOptions: FetchOptions
): Promise<AxiosStatic | Error | void> => {
    const { endpoint, formParams, withCredentials = true } = fetchOptions;

    if (isUndefined(endpoint)) {
        return new Error('NO ENDPOINT CONFIGURED');
    }

    const {
        api,
        url,
        method = 'GET',
        formData,
        callCancellable = true,
        requiredForMenus = false,
    } = endpoint;

    const explicitUrl = `${api}${url(formParams)}`;
    const userLocale = Cookies.get(COOKIE_USER_LOCALE);

    const headers = {
        Accept: APPLICATION_JSON,
        'Accept-Language': userLocale,
        'Content-Type': 'application/json',
    } as Partial<AxiosRequestConfig>;

    const hostNameOverride = backendHostNameForBlog(window.location);

    if (controller[explicitUrl] && callCancellable) {
        controller[explicitUrl]?.abort();
        console.warn(`Previous call to ${hostNameOverride}${explicitUrl} was aborted`);
    }

    const URL_suffix =
        isDev() && explicitUrl.indexOf('/info/') !== -1
            ? `?${explicitUrl.split('/').slice(1, -1).join('-')}`
            : '';

    controller[explicitUrl] = new AbortController();
    const config = {
        method,
        url: `${hostNameOverride}${explicitUrl}${URL_suffix}`,
        headers: omitBy(headers, (header) => isUndefined(header)),
        withCredentials,
        data: formData?.(formParams),
        signal: controller[explicitUrl]?.signal,
    };

    if (!requiredForMenus && !isJakhub()) {
        return Promise.resolve();
    }

    return axios(config)
        .then((results) => {
            const { data: resultData } = results;
            controller[explicitUrl] = null;
            if (!isUndefined(resultData.payload)) {
                return JSON.parse(atob(resultData.payload));
            }
            return {
                ...resultData,
                resultHeaders: results.headers,
            };
        })
        .catch((error) => {
            if (error?.response?.data?.err) {
                throw error?.response?.data;
            } else if (error?.response?.data) {
                try {
                    throw JSON.parse(atob(error?.response?.data)).errCode;
                } catch (e) {
                    throw error?.response?.data?.code || 500;
                }
            } else if (error) {
                throw error;
            }

            return new Error(`${explicitUrl} not reachable`);
        });
};

export default fetchData;
