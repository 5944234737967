/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';
import { mapKeys } from 'lodash';

import { createApiAction } from '../../api/api-action-factory';
import { TypedStoreConfig } from '../factories/store';

import { PLATFORMS_ENDPOINT } from '../../api/endpoints';

import PlatformModel, { TypePlatformModel } from './models/platform-model';

import { isDev } from '../../utils/build-utils';
import { lowerSnakeCase, stripNonAlphaNumeric } from '../../utils/utils';

const PlatformStore = TypedStoreConfig({
    modelName: 'PlatformStore',
    properties: {
        isLoading: false,
        platforms: types.array(PlatformModel),
    },
})
    .actions((self) => ({
        getPlatforms: createApiAction(self, {
            endpoint: PLATFORMS_ENDPOINT,
            target: 'platforms',
        }),
    }))
    .actions((self) => ({
        retrievePlatforms() {
            if (!self.platforms.length) {
                self.getPlatforms();
            }
        },
    }))

    .views((self) => ({
        localizedPlatformsTags(platformGroupName = '') {
            const localizedPlatformsTags: Record<string, string> = {};

            const filterPredicate = platformGroupName
                ? (platform: TypePlatformModel) =>
                      platform.platformGroups?.includes(platformGroupName)
                : () => true;
            self.platforms
                ?.filter((platform) => platform.icon !== null)
                .filter(filterPredicate)
                .forEach((itm) => {
                    localizedPlatformsTags[itm.platformId] = itm.platformName;
                });

            return localizedPlatformsTags;
        },
        get platformsTags() {
            return mapKeys(
                self.platforms
                    ?.filter((platforms) => platforms.icon?.smallGrayScale !== null)
                    .map((itm) => ({
                        ...itm,
                        label: itm.platformName,
                        fe_id: lowerSnakeCase(stripNonAlphaNumeric(itm.platformName)),
                        be_id: itm.platformId,
                    })),
                (itm) => itm.fe_id
            );
        },
    }))
    .views((self) => ({
        supportedPlatformsAsGeneric(platformGroup: string) {
            self.retrievePlatforms();
            return self.platforms
                .filter((p) => {
                    return p.platformGroups?.includes(platformGroup);
                })
                .map((pg) => {
                    return {
                        iconUrl: pg.icon.sprite,
                        platformName: pg.platformName,
                        name: pg.platformName,
                    };
                });
        },
    }));

export type TypePlatformStore = Instance<typeof PlatformStore>;

const PlatformStoreInstance = PlatformStore.create({});
declare const window: Window &
    typeof globalThis & {
        PlatformStore: typeof PlatformStoreInstance;
    };
if (window && isDev()) {
    window.PlatformStore = PlatformStoreInstance;
}

export default PlatformStore;
