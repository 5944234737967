import React, { ComponentProps } from 'react';
import { v4 as uuid } from 'uuid';

import styles from './banner-filler.module.scss';

const REQUIRED_CARD_NUMBER = 136;

export type Card = { id: string; imageUrl: string };
type BannerFillerProps = ComponentProps<'div'> & {
    cards: Card[];
};
export default function BannerFiller(props: BannerFillerProps) {
    const { children, cards } = props;
    const cardArray = cards?.length < REQUIRED_CARD_NUMBER ? [...cards, ...cards] : cards;

    const fixUrl = (url: string) =>
        url.includes('/images/') ? `https://www.audiokinetic.com${url}` : url;

    return (
        <>
            <div className={styles.cardsBackgroundContainer}>
                <div className={styles.cardsBackground}>
                    {cardArray.map((card) => (
                        <img
                            key={`${card.id}|${uuid()}`}
                            className={styles.card}
                            src={fixUrl(card.imageUrl)}
                            alt=''
                        />
                    ))}
                </div>
                <div className={styles.cardsForeground}>{children}</div>
            </div>
            <div className={styles.bannerBottomBand} />
        </>
    );
}
