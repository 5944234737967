import { typedRoutes } from './utils/module-interfaces';

import { lazy } from 'react';
import { PATHS } from './soft-paths/legal';

export const ROUTES = typedRoutes({
    TERMS_OF_USE: {
        ...PATHS.TERMS_OF_USE,
        id: 'TERMS_OF_USE',
        name: 'Terms of Use',
        i18nBase: 'footer:module.terms-of-use',
        i18nId: 'footer:module.terms-of-use.menu-label',
    },
    PRIVACY_POLICY: {
        ...PATHS.PRIVACY_POLICY,
        id: 'PRIVACY_POLICY',
        name: 'Privacy Policy',
        i18nBase: 'footer:module.privacy-policy',
        i18nId: 'footer:module.privacy-policy.menu-label',
    },
    USER_ACCOUNT_POLICY: {
        ...PATHS.USER_ACCOUNT_POLICY,
        id: 'USER_ACCOUNT_POLICY',
        name: 'User Account Policy',
        i18nBase: 'footer:module.user-account-policy',
        i18nId: 'footer:module.user-account-policy.menu-label',
    },
    COOKIE_POLICY: {
        ...PATHS.COOKIE_POLICY,
        id: 'COOKIE_POLICY',
        name: 'Cookie Policy',
        i18nBase: 'footer:module.cookie-policy',
        i18nId: 'footer:module.cookie-policy.menu-label',
    },
    TRADEMARKS: {
        ...PATHS.TRADEMARKS,
        id: 'TRADEMARKS',
        name: 'Trademarks',
        i18nBase: 'footer:module.trademarks',
        i18nId: 'footer:module.trademarks.menu-label',
        component: lazy(() => import('../components/modules/legal/trademarks/trademarks')),
    },
});

export const DEFAULT_ROUTE = ROUTES.TERMS_OF_USE;
