const moduleRoot = `/customers`;

export const PATHS = {
    CUSTOMERS: {
        path: `${moduleRoot}`,
    },
    SUPPORT_TICKETS: {
        path: `${moduleRoot}/tickets`,
    },
};
