import { useCallback, useEffect, useRef } from 'react';

export default function useTimeout(callback: (params?: any) => any, delay: number) {
    const callbackRef = useRef(callback);
    const timeoutRef = useRef<NodeJS.Timeout>();
    const waitingRef = useRef<boolean>(false);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const set = useCallback(() => {
        waitingRef.current = true;
        timeoutRef.current = setTimeout(() => {
            waitingRef.current = false;
            callbackRef.current();
        }, delay);
    }, [delay]);

    const clear = useCallback(() => {
        waitingRef.current = false;
        timeoutRef.current && clearTimeout(timeoutRef.current);
    }, []);

    useEffect(() => {
        set();
        return clear;
    }, [delay, set, clear]);

    const reset = useCallback(() => {
        clear();
        set();
    }, [clear, set]);

    return { reset, clear, done: !waitingRef.current };
}
