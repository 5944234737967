import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = `/strata`;

export const PATHS = {
    STRATA: {
        path: `${moduleRoot}`,
    },
    OVERVIEW: {
        path: `${moduleRoot}/overview`,
    },
    DOCUMENTATION: {
        path: `${moduleRoot}/pricing`,
        hardPath: HARD_PATHS.HELP.STRATA_DOCUMENTATION,
    },

    //NEW valid routes
    SOUND_EFFECT_COLLECTIONS_ID: {
        path: `${moduleRoot}/sound-effect-collections/:libraryId`,
    },
    SOUND_EFFECT_COLLECTIONS: {
        path: `${moduleRoot}/sound-effect-collections/`,
    },
    SOUND_EFFECT_COLLECTIONS_COLLECTION: {
        path: `${moduleRoot}/sound-effect-collections/collection/:collectionId`,
    },
    SOUND_EFFECT_COLLECTION_BAD: {
        path: `${moduleRoot}/sound-effect-collections/collection`,
    },

    //OLD collections routes still needed for backward compatibility
    COLLECTIONS_ID: {
        path: `${moduleRoot}/collections/:libraryId`,
    },
    COLLECTIONS: {
        path: `${moduleRoot}/collections/`,
    },
    COLLECTION: {
        path: `${moduleRoot}/collection/:collectionId`,
    },
    COLLECTION_BAD: {
        path: `${moduleRoot}/collection`,
    },

    PRICING: {
        path: `${moduleRoot}/pricing`,
    },
    LICENSING: {
        path: `${moduleRoot}/licensing/faq/strataLicenses`,
    },
    REGISTER_PROJECT: {
        path: `${moduleRoot}/projectRegistration`,
        hardPath: HARD_PATHS.GENERAL.FCRM_REGISTERED_PROJECTS,
    },
};
