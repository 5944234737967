import { Instance, types } from 'mobx-state-tree';

const ExperienceModel = types.model('ExperienceModel', {
    organizationId: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    organizationName: types.maybeNull(types.string),
    website: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
});

export type TypeExperienceModel = Instance<typeof ExperienceModel>;
export default ExperienceModel;
