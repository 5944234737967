import React, { ComponentProps } from 'react';

import { injectStores } from '../../../stores/factories/store-utils';
import { TypeApplicationStore } from '../../../stores/application-store';

import { HEADER_TOOLS } from '../../../constants';

import ScrollLocker from '../legos/scroll-locker/scroll-locker';

import styles from './switch-link-popup.module.scss';

type Props = {
    ApplicationStore: TypeApplicationStore;
    id: string;
} & ComponentProps<'div'>;

function PopupLinkContainer(props: Props) {
    const {
        ApplicationStore: {
            headerTools: { openedHeaderTool, openedHeaderToolId },
        },
        children,
        id,
    } = props;

    if (openedHeaderTool !== HEADER_TOOLS.SWITCH_LINK_POPUP || openedHeaderToolId !== id) {
        return null;
    }

    return (
        <div className={styles.popup}>
            <div className={styles.popupContent}>
                {children}
                <ScrollLocker lockScroller />
            </div>
        </div>
    );
}

export default injectStores(['ApplicationStore'])(PopupLinkContainer);
