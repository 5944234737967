import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = `/documentation`;

export const PATHS = {
    DOCUMENTATION: {
        path: `${moduleRoot}`,
        hardPath: HARD_PATHS.HELP.WWISE,
    },
    WWISE: {
        path: `${moduleRoot}/wwise`,
        hardPath: HARD_PATHS.HELP.WWISE,
    },
    STRATA: {
        path: `${moduleRoot}/strata`,
        hardPath: HARD_PATHS.HELP.STRATA,
    },
    AK_LAUNCHER: {
        path: `${moduleRoot}/audiokinetic_launcher`,
        hardPath: HARD_PATHS.HELP.AK_LAUNCHER,
    },
};
