import { Instance, types } from 'mobx-state-tree';

export const SchoolType = types.model('SchoolType', {
    en_US: types.optional(types.string, '', [undefined, null]),
    fr_CA: types.optional(types.string, '', [undefined, null]),
    ja_JP: types.optional(types.string, '', [undefined, null]),
    ko_KR: types.optional(types.string, '', [undefined, null]),
    zh_CN: types.optional(types.string, '', [undefined, null]),
});
export type TypeSchoolTypeModel = Instance<typeof SchoolType>;
export type TypeSchoolTypeProperties = Instance<typeof SchoolType.properties>;

const SchoolLinks = types.model('SchoolLinks', {
    licenseAgreement: types.optional(types.string, '', [undefined, null]),
});
export type TypeSchoolLinksModel = Instance<typeof SchoolLinks>;

const SchoolModel = types.model('SchoolTypesModel', {
    types: types.map(SchoolType),
    links: SchoolLinks,
});
export type TypeSchoolModel = Instance<typeof SchoolModel>;

export default SchoolModel;
