import { lazy } from 'react';
import { HEADER_TOOLS } from '../constants';
import { featureFlags } from '../utils/feature-flags';
import { typedRoutes } from './utils/module-interfaces';

import ApplicationStoreInstance from '../stores/application-store';
import { PATHS } from './soft-paths/general';

const { SIGN_UP, CONTACT_FORM } = HEADER_TOOLS;

export const ROUTES = typedRoutes({
    GENERAL: {
        ...PATHS.GENERAL,
        id: 'GENERAL',
        name: 'GENERAL',
        i18nBase: 'general:module',
        i18nId: 'general:module.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/general/general')),
    },
    FEATURE_FLAGS: {
        ...PATHS.FEATURE_FLAGS,
        protectedFeature: 'FEATURE_FLAGS',
        id: 'FEATURE_FLAGS',
        name: 'FEATURE_FLAGS',
        i18nBase: 'featureFlags:feature-flags',
        i18nId: 'featureFlags:feature-flags.menu-label',
        displayInMenu: featureFlags.FEATURE_FLAGS_ADMIN,
        component: lazy(() => import('../utils/feature-flags-toggle')),
    },
    PROJECT_INVITATION: {
        ...PATHS.PROJECT_INVITATION,
        id: 'PROJECT_INVITATION',
        name: 'ProjectInvitation',
        i18nBase: 'general:module.register-project',
        i18nId: 'general:module.register-project.menu-label',
        component: lazy(
            () => import('../components/modules/general/project-invitation/project-invitation')
        ),
    },
    REGISTER_PROJECT: {
        ...PATHS.REGISTER_PROJECT,
        id: 'REGISTER_PROJECT',
        name: 'RegisterProject',
        i18nBase: 'general:module.register-project',
        i18nId: 'general:module.register-project.menu-label',
        displayInMenu: false,
    },
    CART: {
        ...PATHS.CART,
        id: 'CART',
        name: 'Cart',
        i18nBase: 'general:module.cart',
        i18nId: 'general:module.cart.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/general/cart/cart')),
    },
    FCRM_SEARCH: {
        ...PATHS.FCRM_SEARCH,
        id: 'FCRM_SEARCH',
        name: 'fCRM Search',
        i18nBase: 'general:fcrm-search',
        i18nId: 'general:fcrm-search',
        displayInMenu: false,
    },
    CREATE_ACCOUNT: {
        id: 'CREATE_ACCOUNT',
        name: 'CreateAccount',
        i18nBase: 'general:module.create-account',
        i18nId: 'general:module.create-account.menu-label',
        displayInMenu: false,
        action: () => {
            ApplicationStoreInstance.showToolInHeader(SIGN_UP);
        },
    },
    CONTACT_AK: {
        id: 'CONTACT_AK',
        name: 'Contact Audiokinetic',
        i18nBase: 'general:module.create-account',
        i18nId: 'general:module.create-account.menu-label',
        displayInMenu: false,
        action: (params) => {
            const { formContext = 'default' } = params;
            console.log('CONTACT_AK | action');

            ApplicationStoreInstance.clearDump();
            ApplicationStoreInstance.showToolInHeader(CONTACT_FORM, formContext);
        },
    },
    // Keep this for now in case something goes wrong with new strategy
    CREATE_ACCOUNT_OLD: {
        ...PATHS.CREATE_ACCOUNT,
        id: 'CREATE_ACCOUNT_OLD',
        name: 'CreateAccount',
        i18nBase: 'general:module.create-account',
        i18nId: 'general:module.create-account.menu-label',
        displayInMenu: false,
        onLoadAction: () => {
            ApplicationStoreInstance.showToolInHeader(SIGN_UP);
        },
        component: lazy(() => import('../components/modules/home/home')),
    },
    CREATE_BLOG_ACCOUNT: {
        ...PATHS.CREATE_BLOG_ACCOUNT,
        id: 'CREATE_BLOG_ACCOUNT',
        name: 'CreateBlogAccount',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    FCRM_MY_PROFILE: {
        ...PATHS.FCRM_MY_PROFILE,
        id: 'FCRM_MY_PROFILE',
        name: 'MY PROFILE',
        i18nBase: 'menu-tools.signed-in-options.my-profile',
        i18nId: 'menu-tools.signed-in-options.my-profile',
        displayInMenu: false,
    },
    FCRM_MY_MESSAGES: {
        ...PATHS.FCRM_MY_MESSAGES,
        id: 'FCRM_MY_MESSAGES',
        name: 'My Messages',
        i18nBase: 'menu-tools.signed-in-options.my-messages',
        i18nId: 'menu-tools.signed-in-options.my-messages',
        displayInMenu: false,
    },
    FCRM_MY_DETAILS: {
        ...PATHS.FCRM_MY_DETAILS,
        id: 'FCRM_MY_DETAILS',
        name: 'My Details',
        i18nBase: 'menu-tools.signed-in-options.my-details',
        i18nId: 'menu-tools.signed-in-options.my-details',
        displayInMenu: false,
    },
    FCRM_MY_PROJECTS: {
        ...PATHS.FCRM_MY_PROJECTS,
        id: 'FCRM_MY_PROJECTS',
        name: 'My Projects',
        i18nBase: 'menu-tools.signed-in-options.my-projects',
        i18nId: 'menu-tools.signed-in-options.my-projects',
        displayInMenu: false,
    },
    FCRM_MY_COURSES: {
        ...PATHS.FCRM_MY_COURSES,
        id: 'FCRM_MY_COURSES',
        name: 'My Courses',
        i18nBase: 'menu-tools.signed-in-options.my-courses',
        i18nId: 'menu-tools.signed-in-options.my-courses',
        displayInMenu: false,
    },
    FCRM_MY_ORGANIZATIONS: {
        ...PATHS.FCRM_MY_ORGANIZATIONS,
        id: 'FCRM_MY_ORGANIZATIONS',
        name: 'My Organizations',
        i18nBase: 'menu-tools.signed-in-options.my-organizations',
        i18nId: 'menu-tools.signed-in-options.my-organizations',
        displayInMenu: false,
    },
    FCRM_MY_COMPANY: {
        ...PATHS.FCRM_MY_COMPANY,
        id: 'FCRM_MY_COMPANY',
        name: 'My Company',
        i18nBase: 'menu-tools.signed-in-options.my-company',
        i18nId: 'menu-tools.signed-in-options.my-company',
        displayInMenu: false,
    },
    FCRM_MY_SCHOOL: {
        ...PATHS.FCRM_MY_SCHOOL,
        id: 'FCRM_MY_SCHOOL',
        name: 'My School',
        i18nBase: 'menu-tools.signed-in-options.my-school',
        i18nId: 'menu-tools.signed-in-options.my-school',
        displayInMenu: false,
    },
    FCRM_MY_NOTIFICATIONS: {
        ...PATHS.FCRM_MY_NOTIFICATIONS,
        id: 'FCRM_MY_NOTIFICATIONS',
        name: 'My Notifications',
        i18nBase: 'menu-tools.signed-in-options.my-notifications',
        i18nId: 'menu-tools.signed-in-options.my-notifications',
        displayInMenu: false,
    },
    IN_GAME_CREDITS: {
        ...PATHS.IN_GAME_CREDITS,
        id: 'IN_GAME_CREDITS',
        name: 'In-game credits',
        i18nBase: 'menu-tools.signed-in-options.in-game-credits',
        i18nId: 'menu-tools.signed-in-options.in-game-credits',
        displayInMenu: false,
        component: lazy(
            () => import('../components/modules/general/in-game-credits/in-game-credits')
        ),
    },
    FCRM_IN_GAME_CREDITS: {
        ...PATHS.FCRM_IN_GAME_CREDITS,
        id: 'FCRM_IN_GAME_CREDITS',
        name: 'In-game Credits',
        i18nBase: 'menu-tools.signed-in-options.in-game-credits',
        i18nId: 'menu-tools.signed-in-options.in-game-credits',
        displayInMenu: false,
    },
    DOWNLOAD_LAUNCHER: {
        ...PATHS.DOWNLOAD_LAUNCHER,
        id: 'DOWNLOAD_LAUNCHER',
        name: 'Download $t(doNotTranslate:audiokinetic-launcher)',
        i18nBase: 'general:module.download-wwise',
        i18nId: 'general:module.download-wwise',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/general/download/download')),
    },
    FCRM_REGISTERED_PROJECTS: {
        ...PATHS.FCRM_REGISTERED_PROJECTS,
        id: 'FCRM_REGISTERED_PROJECTS',
        name: 'Registered Projects',
        i18nBase: 'menu-tools.signed-in-options.register-project',
        i18nId: 'menu-tools.signed-in-options.register-project',
        displayInMenu: false,
    },
    FCRM_PRICING: {
        ...PATHS.FCRM_PRICING,
        id: 'FCRM_PRICING',
        name: 'Pricing',
        i18nBase: 'menu-tools.get-started-dropdown.pricing',
        i18nId: 'menu-tools.get-started-dropdown.pricing',
        displayInMenu: false,
    },
    ROYALTY_LICENSING: {
        ...PATHS.ROYALTY_LICENSING,
        id: 'ROYALTY_LICENSING',
        name: 'Licensing FAQ',
        i18nBase: 'menu-tools.get-started-dropdown.licensing-faq',
        i18nId: 'menu-tools.get-started-dropdown.licensing-faq',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/general/licensing-faq/licensing-faq')),
    },
    GAAS_LICENSING: {
        ...PATHS.GAAS_LICENSING,
        id: 'GAAS_LICENSING',
        name: 'Licensing FAQ',
        i18nBase: 'menu-tools.get-started-dropdown.licensing-faq',
        i18nId: 'menu-tools.get-started-dropdown.licensing-faq',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/general/licensing-faq/licensing-faq')),
    },
    TRIAL_LICENSING: {
        ...PATHS.TRIAL_LICENSING,
        id: 'TRIAL_LICENSING',
        name: 'Licensing FAQ',
        i18nBase: 'menu-tools.get-started-dropdown.licensing-faq',
        i18nId: 'menu-tools.get-started-dropdown.licensing-faq',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/general/licensing-faq/licensing-faq')),
    },
    COMMERCIAL_LICENSING: {
        ...PATHS.COMMERCIAL_LICENSING,
        id: 'COMMERCIAL_LICENSING',
        name: 'Licensing FAQ',
        i18nBase: 'menu-tools.get-started-dropdown.licensing-faq',
        i18nId: 'menu-tools.get-started-dropdown.licensing-faq',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/general/licensing-faq/licensing-faq')),
    },
    WWISE_COMMERCIAL_LICENSING: {
        ...PATHS.WWISE_COMMERCIAL_LICENSING,
        id: 'WWISE_COMMERCIAL_LICENSING',
        name: 'Licensing FAQ',
        i18nBase: 'menu-tools.get-started-dropdown.licensing-faq',
        i18nId: 'menu-tools.get-started-dropdown.licensing-faq',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/general/licensing-faq/licensing-faq')),
    },
    ACADEMIC_LICENSING: {
        ...PATHS.ACADEMIC_LICENSING,
        id: 'ACADEMIC_LICENSING',
        name: 'Licensing FAQ',
        i18nBase: 'menu-tools.get-started-dropdown.licensing-faq',
        i18nId: 'menu-tools.get-started-dropdown.licensing-faq',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/general/licensing-faq/licensing-faq')),
    },
    STRATA_LICENSING: {
        ...PATHS.STRATA_LICENSING,
        id: 'STRATA_LICENSING',
        name: 'Licensing FAQ',
        i18nBase: 'menu-tools.get-started-dropdown.licensing-faq',
        i18nId: 'menu-tools.get-started-dropdown.licensing-faq',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/general/licensing-faq/licensing-faq')),
    },
    FCRM_MY_SUPPORT_TICKETS: {
        ...PATHS.FCRM_MY_SUPPORT_TICKETS,
        id: 'FCRM_MY_SUPPORT_TICKETS',
        name: 'My Support Tickets',
        i18nBase: 'menu-tools.signed-in-options.my-support-tickets',
        i18nId: 'menu-tools.signed-in-options.my-support-tickets',
        displayInMenu: false,
    },
    FCRM_MY_LICENSES: {
        ...PATHS.FCRM_MY_LICENSES,
        id: 'FCRM_MY_LICENSES',
        name: 'My Licenses',
        i18nBase: 'menu-tools.signed-in-options.my-licences',
        i18nId: 'menu-tools.signed-in-options.my-licences',
        displayInMenu: false,
    },
    FCRM_MY_STORE: {
        ...PATHS.FCRM_MY_STORE,
        id: 'FCRM_MY_STORE',
        name: 'My Strata Library',
        i18nBase: 'menu-tools.signed-in-options.my-strata-library',
        i18nId: 'menu-tools.signed-in-options.my-strata-library',
        displayInMenu: false,
    },
    WWISE_TOUR_REGISTER_TO_ATTEND: {
        ...PATHS.WWISE_TOUR_REGISTER_TO_ATTEND,
        id: 'WWISE_TOUR_REGISTER_TO_ATTEND',
        name: 'WwiseTour register to attend',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    SUBMIT_YOUR_TALK_WWISE_TOUR: {
        ...PATHS.SUBMIT_YOUR_TALK_WWISE_TOUR,
        id: 'SUBMIT_YOUR_TALK_WWISE_TOUR',
        name: 'Submit your talk wwise tour',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    INTERACTIVE_MUSIC_SYMPOSIUM_REGISTER_TO_ATTEND: {
        ...PATHS.INTERACTIVE_MUSIC_SYMPOSIUM_REGISTER_TO_ATTEND,
        id: 'INTERACTIVE_MUSIC_SYMPOSIUM_REGISTER_TO_ATTEND',
        name: 'Interactive music symposium register to attend',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    SUBMIT_YOUR_TALK_INTERACTIVE_MUSIC_SYMPOSIUM: {
        ...PATHS.SUBMIT_YOUR_TALK_INTERACTIVE_MUSIC_SYMPOSIUM,
        id: 'SUBMIT_YOUR_TALK_INTERACTIVE_MUSIC_SYMPOSIUM',
        name: 'Submit your talk interactive music symposium',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    WWWOE_REGISTER_TO_ATTEND: {
        ...PATHS.WWWOE_REGISTER_TO_ATTEND,
        id: 'WWWOE_REGISTER_TO_ATTEND',
        name: 'WWWOE Register to Attend',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    RESOURCES_VIDEOS: {
        ...PATHS.RESOURCES_VIDEOS,
        id: 'RESOURCES_VIDEOS',
        name: 'Resources Videos',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    RESOURCES_SAMPLES: {
        ...PATHS.RESOURCES_SAMPLES,
        id: 'RESOURCES_SAMPLES',
        name: 'Resources Samples',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    LINUX_AUTOMOTIVE: {
        ...PATHS.LINUX_AUTOMOTIVE,
        id: 'LINUX_AUTOMOTIVE',
        name: 'Linux Automotive',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    STRATA_LICENSE_AGREEMENT: {
        ...PATHS.STRATA_LICENSE_AGREEMENT,
        id: 'STRATA_LICENSE_AGREEMENT',
        name: 'Strata License Agreement',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    STRATA_SUBSCRIPTION_AGREEMENT: {
        ...PATHS.STRATA_SUBSCRIPTION_AGREEMENT,
        id: 'STRATA_SUBSCRIPTION_AGREEMENT',
        name: 'Strata Subscription Agreement',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    COMMUNITY_PARTNER_MARKETING_GUIDELINE: {
        ...PATHS.COMMUNITY_PARTNER_MARKETING_GUIDELINE,
        id: 'COMMUNITY_PARTNER_MARKETING_GUIDELINE',
        name: 'Community partner Marketing Guideline',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    SUBSCRIBE_GENERAL_YOUTUBE: {
        ...PATHS.SUBSCRIBE_GENERAL_YOUTUBE,
        id: 'SUBSCRIBE_GENERAL_YOUTUBE',
        name: 'YouTube channel',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    DESCRIPTION_TESTER: {
        ...PATHS.DESCRIPTION_TESTER,
        protectedFeature: 'DESCRIPTION_TESTER',
        id: 'DESCRIPTION_TESTER',
        name: 'Description Tester',
        i18nBase: 'menu-tools.general.description-tester',
        i18nId: 'menu-tools.general.description-tester',
        displayInMenu: featureFlags.DESCRIPTION_TESTER,
        component: lazy(
            () => import('../components/modules/general/description-tester/description-tester')
        ),
    },
    FOUR_OH_FOUR: {
        ...PATHS.FOUR_OH_FOUR,
        id: 'FOUR_OH_FOUR',
        name: '404',
        i18nBase: 'menu-tools.general',
        i18nId: 'menu-tools.general',
        component: lazy(() => import('../components/common/four-oh-four/four-oh-four')),
    },
});

export const DEFAULT_ROUTE = ROUTES.FEATURE_FLAGS;
