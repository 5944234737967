import { typedRoutes } from './utils/module-interfaces';

import { lazy } from 'react';
import { PATHS } from './soft-paths/store';

export const ROUTES = typedRoutes({
    STORE: {
        ...PATHS.STORE,
        id: 'STORE',
        name: 'Store',
        i18nBase: 'store:module',
        i18nId: 'store:module.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/store')),
    },
    PAY2GO: {
        ...PATHS.PAY2GO,
        id: 'PAY2GO',
        name: 'PAY2GO Support',
        i18nBase: 'store:module.pay2go',
        i18nId: 'store:module.pay2go.menu-label',
    },
    PLUGINS: {
        ...PATHS.PLUGINS,
        id: 'PLUGINS',
        name: 'Plugins',
        i18nBase: 'store:module.plugins',
        i18nId: 'store:module.plugins.menu-label',
    },
    CERTIFICATIONS: {
        ...PATHS.CERTIFICATIONS,
        id: 'CERTIFICATIONS',
        name: 'Certifications (new)',
        i18nBase: 'store:module.certifications',
        i18nId: 'store:module.certifications.menu-label',
        component: lazy(() => import('../components/modules/learning/store-certifications')),
    },
    PURCHASE_CERTIFICATION: {
        ...PATHS.PURCHASE_CERTIFICATION,
        id: 'PURCHASE_CERTIFICATION',
        name: 'Purchase Certification',
        i18nBase: 'store:module.certifications',
        i18nId: 'store:module.certifications.menu-label',
        displayInMenu: false,
    },
    STRATA: {
        ...PATHS.STRATA,
        id: 'STRATA',
        name: 'Strata Library Store',
        i18nBase: 'store:module.strata',
        i18nId: 'store:module.strata.menu-label',
        component: lazy(() => import('../components/modules/store/strata/strata')),
    },
    STRATA_PRICING: {
        ...PATHS.STRATA_PRICING,
        id: 'STRATA_PRICING',
        name: 'Strata Library Store',
        i18nBase: 'store:module.strata',
        i18nId: 'store:module.strata.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/store/strata-pricing/strata-pricing')),
    },
    STRATA_PRICING_TAB: {
        ...PATHS.STRATA_PRICING_TAB,
        id: 'STRATA_PRICING_TAB',
        name: 'Strata Library Store',
        i18nBase: 'store:module.strata',
        i18nId: 'store:module.strata.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/store/strata-pricing/strata-pricing')),
    },
    STRATA_LIBRARY: {
        ...PATHS.STRATA_LIBRARY,
        id: 'STRATA',
        name: 'Strata Library Store',
        i18nBase: 'store:module.strata',
        i18nId: 'store:module.strata.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/store/strata/strata')),
    },
});

export const DEFAULT_ROUTE = ROUTES.STORE;
