import React, { PropsWithChildren } from 'react';
import { injectStores } from '../../../../stores/factories/store-utils';

import { useJakhubTranslation } from '../../../../i18n/jakhub-translation';
import { TypeMenuItemModel } from '../../../../stores/user-profile-store/models/admin-menu-model';
import { TypeApplicationStore } from '../../../../stores/application-store';
import { TypeProfileStore } from '../../../../stores/profile-store';
import { backendHostNameForBlog, isHttpLink } from '../../../../utils/utils';

import { HEADER_TOOLS, LOCAL_SERVER_REPLACE_LOOKUP } from '../../../../constants';

import MenuDropdown from '../../../controls/dropdowns/menu-dropdown';
import SwitchLink from '../switch-link/switch-link';

import styles from './employee-admin-menu.module.scss';

const TOOL_NAME = HEADER_TOOLS.DROP_DOWN;

type AdminMenuLinkProps = {
    item: TypeMenuItemModel;
    className: string;
};
function AdminMenuLink(props: AdminMenuLinkProps) {
    const { item, className } = props;
    const { hostname, host, protocol } = window.location;

    const newHost =
        hostname.indexOf('blog') !== -1
            ? backendHostNameForBlog(window.location)
            : `${protocol}//${host}`;
    const replacementHost = LOCAL_SERVER_REPLACE_LOOKUP[hostname] || newHost;

    const menuUrl = isHttpLink(item.menuUrl) ? item.menuUrl : `${replacementHost}${item.menuUrl}`;
    return (
        <SwitchLink akTestId='|Url|' className={className} to={menuUrl} includeLanguageSlug={false}>
            {item.label}
        </SwitchLink>
    );
}

type MenuDropdownProps = PropsWithChildren<{}> & {
    items: TypeMenuItemModel[];
};

function Items(props: MenuDropdownProps) {
    const { items } = props;
    return (
        <div className={styles.dropDownList}>
            {items.map((item, itemIndex) => {
                return (
                    <div key={`${item.id}|${itemIndex}`} className={styles.dropDownListItem}>
                        {item.menuUrl ? (
                            <AdminMenuLink item={item} className={styles.blackLink} />
                        ) : (
                            <div className={styles.sectionSplitter}>{item.label}</div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

type MenuItemProps = {
    ApplicationStore: TypeApplicationStore;
    item: TypeMenuItemModel;
};
function WrappedMenuItem(props: MenuItemProps) {
    const {
        ApplicationStore: {
            showToolInHeader,
            headerTools: { openedHeaderToolId },
        },
        item,
    } = props;

    if (item?.menu?.length > 0) {
        return (
            <div>
                <MenuDropdown
                    optionsComponents={<Items items={item.menu} />}
                    showOpenList={openedHeaderToolId === item.id}
                    actionOpenList={() => showToolInHeader(TOOL_NAME, item.id)}
                    rightAlign
                >
                    <div className={styles.dropDown}>
                        {item.label}
                        <i className='material-icons material-icons-grey'>
                            {!(openedHeaderToolId === item.id)
                                ? 'arrow_drop_down'
                                : 'arrow_drop_up'}
                        </i>
                    </div>
                </MenuDropdown>
            </div>
        );
    }

    return <AdminMenuLink item={item} className={styles.whiteLink} />;
}

const MenuItem = injectStores(['ApplicationStore'])(WrappedMenuItem);

type Props = {
    ProfileStore: TypeProfileStore;
};
function EmployeeAdminMenu(props: Props) {
    const { t } = useJakhubTranslation();
    const {
        ProfileStore: {
            userAccount: { profile },
            adminMenuStructure,
        },
    } = props;
    const { isAKEmployee = false } = { ...profile };
    const { menu = [] } = { ...adminMenuStructure };

    if (!isAKEmployee) return null;
    if (!menu.length)
        return (
            <div className={styles.employeeTools}>
                <div className={styles.employeeToolsContent}>
                    <span>
                        {t('general:admin-menu.no-menu-loading', {
                            AK_WEBDEV: (
                                <SwitchLink
                                    akTestId='EmployeeAdminMenuLaunchIcon${|Url|https://sie-audiokinetic.slack.com/archives/C03706U2LFJ'
                                    to='https://sie-audiokinetic.slack.com/archives/C03706U2LFJ'
                                />
                            ),
                        })}
                    </span>
                </div>
            </div>
        );

    return (
        <div className={styles.employeeTools}>
            <div className={styles.employeeToolsContent}>
                {menu.map((item: TypeMenuItemModel) => (
                    <MenuItem key={item.id} item={item} />
                ))}
            </div>
        </div>
    );
}

export default injectStores(['ProfileStore'])(EmployeeAdminMenu);
