import { Instance, types } from 'mobx-state-tree';

export const PoweredByWwiseGameModel = types.model('PoweredByWwiseGameModel', {
    title: types.maybeNull(types.string),
    studios: types.maybeNull(types.array(types.string)),
    imageUrl: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    releaseDate: types.maybeNull(types.string),
    gameEngine: types.maybeNull(types.string),
    platformIds: types.maybeNull(types.array(types.string)),
    platformIcons: types.maybeNull(types.array(types.string)),
    gameTags: types.maybeNull(types.array(types.string)),
});

export const PoweredByWwiseModel = types.model('PoweredByWwiseModel', {
    games: types.array(PoweredByWwiseGameModel),
    gamesCount: types.maybeNull(types.number),
});

export type TypePoweredByWwiseModel = Instance<typeof PoweredByWwiseModel>;
export type TypePoweredByWwiseGameModel = Instance<typeof PoweredByWwiseGameModel>;

export default PoweredByWwiseModel;
