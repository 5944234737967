import { Instance, types } from 'mobx-state-tree';

import { CommunityQandAModel } from './community-q-and-a-model';

export const QuestionsAnswersModel = types.model('QuestionsAnswersModel', {
    mostRecent: types.maybeNull(types.array(CommunityQandAModel)),
    mostPopular: types.maybeNull(types.array(CommunityQandAModel)),
});

export type TypeQuestionsAnswersModel = Instance<typeof QuestionsAnswersModel>;

export default QuestionsAnswersModel;
