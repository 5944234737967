import { Instance, types } from 'mobx-state-tree';

const TrainingEventModel = types.model('TrainingEventModel', {
    title: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    datetimeRange: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    tags: types.array(types.string),
    detailsUrl: types.maybeNull(types.string),
});

export type TypeTrainingEventModel = Instance<typeof TrainingEventModel>;

export default TrainingEventModel;
