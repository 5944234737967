/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';
import { mapValues } from 'lodash';

import { TypedStoreConfig } from './factories/store';
import { isDev } from '../utils/build-utils';
import { capitalize, snakeCase } from '../utils/utils';
import { createApiAction } from '../api/api-action-factory';
import {
    USER_PROFILE_LANGUAGES_ENDPOINT,
    SCHOOL_TYPES_INFO_ENDPOINT,
    SPECIAL_BANNER_MESSAGE_INFO_STATIC_ENDPOINT,
    POWERED_BY_WWISE_GAMES_STATIC_ENDPOINT,
    SUPPORTED_PLATFORMS_STATIC_ENDPOINT,
    WWISE_OVERVIEW_HEADER_CTAS,
} from '../api/endpoints';

import AkLibraryStore from './content-stores/ak-library-store';
import ApplicationStoreInstance from './application-store';
import BannerMessageModel from './content-stores/models/banner-message-model';
import BlogStore from './content-stores/blog-store';
import CreatorsDirectoryStore from './content-stores/creators-directory-store';
import DocumentationStore from './content-stores/documentation-store';
import GameEngineStore from './content-stores/game-engine-store';
import HolidayStore from './content-stores/holiday-store';
import IntegrationStore from './content-stores/integration-store';
import JobStore from './content-stores/job-store';
import NewsListStore from './content-stores/news-list-store';
import NewsStore from './content-stores/news-store';
import PlatformStore from './content-stores/platform-store';
import PluginStore from './content-stores/plugin-store';
import PoweredByWwiseStore from './content-stores/powered-by-wwise-store';
import PoweredByWwiseGamesStore from './content-stores/models/powered-by-wwise-games-model';
import QuestionsAnswersStore from './content-stores/questions-answers-store';
import SchoolsInfoModel from './content-stores/models/schools-info-model';
import SupportedPlatformsModel from './content-stores/models/supported-platforms-model';
import UpcomingTrainingEventsStore from './content-stores/upcoming-training-events-store';
import WwiseCertificationStore from './content-stores/wwise-certification-store';
import WwiseOverviewHeaderCtasModel from './content-stores/models/wwise-overview-header-ctas-model';
import WwiseSupportPolicyStore from './content-stores/wwise-support-policy-store';
import YouTubeStore from './content-stores/youtube-store';

import { TypeSchoolTypeProperties } from './application-stores/models/SchoolTypesModel';
import LanguageLocaleModel from './content-stores/models/language-list-model';

const ContentStore = TypedStoreConfig({
    modelName: 'ContentStore',
    properties: {
        isLoading: false,
        AkLibraryStore: AkLibraryStore,
        BlogStore: BlogStore,
        CreatorsDirectoryStore: CreatorsDirectoryStore,
        DocumentationStore: DocumentationStore,
        GameEngineStore: GameEngineStore,
        HolidayStore: HolidayStore,
        IntegrationStore: IntegrationStore,
        JobStore: JobStore,
        LanguageList: types.maybeNull(LanguageLocaleModel),
        NewsStore: NewsStore,
        NewsListStore: NewsListStore,
        PlatformStore: PlatformStore,
        PluginStore: PluginStore,
        PoweredByWwiseStore: PoweredByWwiseStore,
        PoweredByWwiseGames: types.maybeNull(PoweredByWwiseGamesStore),
        QuestionsAnswersStore: QuestionsAnswersStore,
        SchoolsInfo: types.maybeNull(SchoolsInfoModel),
        SpecialBannerMessage: BannerMessageModel,
        SupportedPlatforms: SupportedPlatformsModel,
        UpcomingTrainingEvents: UpcomingTrainingEventsStore,
        WwiseCertificationStore: WwiseCertificationStore,
        WwiseOverviewHeaderCtas: types.maybeNull(WwiseOverviewHeaderCtasModel),
        WwiseSupportPolicyStore: WwiseSupportPolicyStore,
        YouTubeStore: YouTubeStore,
    },
})
    .actions((self) => ({
        retrieveSchoolTypes: createApiAction(self, {
            endpoint: SCHOOL_TYPES_INFO_ENDPOINT,
            target: 'SchoolsInfo',
        }),
        getBackendLanguageList: createApiAction(self, {
            endpoint: USER_PROFILE_LANGUAGES_ENDPOINT,
            target: 'LanguageList',
            onLoad: (self) => {
                self.LanguageList = mapValues(self.LanguageList, (i) =>
                    mapValues(i, (j) => capitalize(j))
                );
            },
        }),
        getSpecialMessageConfig: createApiAction(self, {
            endpoint: SPECIAL_BANNER_MESSAGE_INFO_STATIC_ENDPOINT,
            target: 'SpecialBannerMessage',
        }),
        getPoweredByWwiseGames: createApiAction(self, {
            endpoint: POWERED_BY_WWISE_GAMES_STATIC_ENDPOINT,
            target: 'PoweredByWwiseGames',
        }),
        fetchSupportedPlatformsCompatibilityGrid: createApiAction(self, {
            endpoint: SUPPORTED_PLATFORMS_STATIC_ENDPOINT,
            target: 'SupportedPlatforms',
        }),
        fetchWwiseOverviewHeaderCtas: createApiAction(self, {
            endpoint: WWISE_OVERVIEW_HEADER_CTAS,
            target: 'WwiseOverviewHeaderCtas',
        }),
    }))
    .actions((self) => ({
        retrieveBackendLanguageList() {
            if (!self.LanguageList) {
                self.getBackendLanguageList();
            }
        },
        getSupportedPlatformsCompatibilityGrid() {
            if (!(Object.keys(self.SupportedPlatforms?.plugins || {}).length > 0)) {
                self.fetchSupportedPlatformsCompatibilityGrid();
            }
        },
    }))
    .views((self) => ({
        getLocalizedLanguages() {
            const userLocale = snakeCase(
                ApplicationStoreInstance.userLocale
            ) as keyof TypeSchoolTypeProperties;
            return self.LanguageList?.[userLocale];
        },
    }));

export type TypeContentStore = Instance<typeof ContentStore>;

const ContentStoreInstance = ContentStore.create({
    AkLibraryStore: {},
    BlogStore: {},
    CreatorsDirectoryStore: {},
    DocumentationStore: {},
    GameEngineStore: {},
    HolidayStore: {},
    IntegrationStore: {},
    JobStore: {},
    NewsStore: {},
    NewsListStore: {},
    PlatformStore: {},
    PluginStore: {},
    PoweredByWwiseStore: {},
    QuestionsAnswersStore: {},
    SpecialBannerMessage: {},
    SupportedPlatforms: {},
    UpcomingTrainingEvents: {},
    WwiseCertificationStore: {},
    WwiseSupportPolicyStore: {},
    YouTubeStore: {},
});

declare const window: Window &
    typeof globalThis & {
        ContentStore: typeof ContentStoreInstance;
    };

if (window && isDev()) {
    window.ContentStore = ContentStoreInstance;
}

export default ContentStoreInstance;
