import { cast, Instance, types } from 'mobx-state-tree';

import { filter, map, find } from 'lodash';

import { ThirdPartyServicesTypes, THIRD_PARTY_SERVICES } from '../../constants';

const THIRD_PARTY_SERVICES_CASCADE = {
    VIDEOS: [ThirdPartyServicesTypes.YOUTUBE, ThirdPartyServicesTypes.BILIBILI],
    SOUNDS: [ThirdPartyServicesTypes.SOUNDCLOUD, ThirdPartyServicesTypes.AUDIOKINETIC],
    SOCIAL: [
        ThirdPartyServicesTypes.FACEBOOK,
        ThirdPartyServicesTypes.LINKEDIN,
        ThirdPartyServicesTypes.TWITTER,
        ThirdPartyServicesTypes.TWITCH,
        ThirdPartyServicesTypes.NAVER,
        ThirdPartyServicesTypes.WEIBO,
        ThirdPartyServicesTypes.WECHAT,
    ],
};

async function fetchWithTimeout(resource: any, options: any = {}) {
    const { timeout = 10000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
        ...options,
        signal: controller.signal,
    });
    clearTimeout(id);
    return response;
}

const testService = async (service: any) => {
    try {
        await fetchWithTimeout(service.url, { timeout: 2000, mode: 'no-cors' });
        return { ...service, isAvailable: true };
    } catch {
        return { ...service, isAvailable: false };
    }
};

function testAllServices(services: any) {
    const promises: any = [];
    services.forEach((service: any) => promises.push(testService(service)));
    return Promise.allSettled(promises);
}

const AssetTypeService = types.model('AssetTypeService', {
    VIDEOS: 'NONE',
    SOUNDS: 'NONE',
    SOCIAL: 'NONE',
});

const Service = types.model('Service', {
    id: types.identifier,
    url: types.string,
    isAvailable: types.boolean,
});

const ThirdPartyServicesStore = types
    .model('ThirdPartyServicesStore', {
        services: types.maybeNull(types.array(Service)),
        selectedService: types.maybeNull(types.reference(Service)),
        assetTypeService: types.maybeNull(AssetTypeService),
    })
    .actions((self) => ({
        setServiceAvailability(serviceId: any, availability: any) {
            self.selectedService = serviceId;
            if (self?.selectedService) {
                self.selectedService.isAvailable = availability;
            }
        },
        setAssetTypeService(newServiceSettings: any) {
            if (newServiceSettings) {
                self.assetTypeService = newServiceSettings;
            }
        },
    }))
    .views((self) => ({
        isServiceAvailable(serviceId: any) {
            return (
                find(self?.services, (service) => service.id === serviceId)?.isAvailable || false
            );
        },
        getSoundProvider() {
            return self.assetTypeService?.SOUNDS;
        },
        getVideoProvider() {
            return self.assetTypeService?.VIDEOS;
        },
    }))
    .actions((self) => ({
        afterCreate() {
            self.services = cast(
                map(THIRD_PARTY_SERVICES, ({ url, id }) => ({
                    id,
                    url,
                    isAvailable: false,
                }))
            );

            testAllServices(self.services)
                .then((results) =>
                    map(
                        filter(results, (result) => result.status === 'fulfilled'),
                        (fulfilledResult) => {
                            if (fulfilledResult.status === 'fulfilled') {
                                self.setServiceAvailability(
                                    fulfilledResult.value.id,
                                    fulfilledResult.value.isAvailable
                                );
                            }
                        }
                    )
                )
                .then(() => {
                    self.setAssetTypeService({
                        VIDEOS: find(THIRD_PARTY_SERVICES_CASCADE.VIDEOS, (service) =>
                            self.isServiceAvailable(service)
                        ),
                        SOUNDS: find(THIRD_PARTY_SERVICES_CASCADE.SOUNDS, (service) =>
                            self.isServiceAvailable(service)
                        ),
                        SOCIAL: find(THIRD_PARTY_SERVICES_CASCADE.SOCIAL, (service) =>
                            self.isServiceAvailable(service)
                        ),
                    });
                });
        },
    }));

export type TypeThirdPartyServicesModel = Instance<typeof ThirdPartyServicesStore>;

export default ThirdPartyServicesStore;
