import React from 'react';

import { Provider } from 'mobx-react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import Jakhub from './app/jakhub';
import AppTheme from './app/app-theme';

import ApplicationStore from './stores/application-store';
import ContentStore from './stores/content-store';
import ProductStore from './stores/product-store';
import ProfileStore from './stores/profile-store';
import ProfileEntityStore from './stores/profile-entity-store';

import { preLoadAppResources } from './app/app_initiatisation';
import reportWebVitals from './reportWebVitals';

import './jakhub.scss';

preLoadAppResources();

ReactDOM.render(
    <Provider
        ApplicationStore={ApplicationStore}
        ContentStore={ContentStore}
        ProfileStore={ProfileStore}
        ProductStore={ProductStore}
        ProfileEntityStore={ProfileEntityStore}
    >
        <ThemeProvider theme={AppTheme}>
            <Jakhub />
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
