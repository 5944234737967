import React from 'react';
import classNames from 'classnames';

import { ModuleId, TargetType } from '../../../../modules/utils/module-interfaces';

import SwitchLink from '../../../menus/menu/switch-link/switch-link';

import './menu-icon-link.scss';

export interface MenuIconLinkProps {
    badgeCount: number;
    className?: string;
    isMobileMenu?: boolean;
}
interface Props<M extends ModuleId> {
    className?: string;
    isMobileMenu?: boolean;
    icon: string;
    badgeCount: number;
    label: string;
    target: TargetType<M>;
}

function MenuIconLink<M extends ModuleId>(props: Props<M>) {
    const { target, badgeCount, label, icon, className = '', isMobileMenu = false } = props;
    const correctedBadgeCount = badgeCount > 9 ? '9+' : badgeCount;

    const classes = classNames(
        'shopping-cart',
        !isMobileMenu ? 'mobile-hide' : '',
        isMobileMenu ? 'mobile-menu-item' : '',
        className ? className : '',
        badgeCount > 0 ? 'with-badge' : ''
    );
    return (
        <SwitchLink
            akTestId={`ShoppingCart|LinkTo|${target.module}|${target.subModule.toString()}`}
            to={target}
            className={classes}
        >
            <div className='link'>
                {!isMobileMenu && <i className='material-icons-outlined'>{icon}</i>}
                {isMobileMenu ? <div>{label}</div> : null}
                {badgeCount > 0 && <span className='badge'>{correctedBadgeCount}</span>}
            </div>
        </SwitchLink>
    );
}

export default MenuIconLink;
