import React from 'react';

import { useJakhubTranslation } from '../../../i18n/jakhub-translation';

import { injectStores } from '../../../stores/factories/store-utils';
import { TypeApplicationStore } from '../../../stores/application-store';
import { TypeCurrentUserInfoModel } from '../../../stores/models/current-user';
import { TypeProfileStore } from '../../../stores/profile-store';

import Button from '../../controls/button/button';
import DivButton from '../../common/legos/div-button/div-button';
import TextField from '../../controls/text-field/text-field';

import { EmailOrnament } from '../legos/textfield-ornaments';
import { Controller, useFormContext } from 'react-hook-form';
import { EMAIL_RULES } from '../legos/field-rules';
import ErrorMessages from '../legos/error-messages';
import { HEADER_TOOLS } from '../../../constants';

import styles from '../sign-in-up/sign-in-up.module.scss';

const { SIGN_IN } = HEADER_TOOLS;

interface Props {
    ApplicationStore: TypeApplicationStore;
    ProfileStore: TypeProfileStore;
    handleChange(id: string, event: React.ChangeEvent<HTMLInputElement>): void;
    setFormMode(newMode: string): void;
    pwResetResponse?: TypeCurrentUserInfoModel;
    submitAttempted: boolean;
    setSubmitAttempted: (newState: boolean) => void;
}

interface CheckMailProps {
    email: string;
    onClose: () => void;
}

function CheckYourEmail(props: CheckMailProps) {
    const { t } = useJakhubTranslation();
    const { email, onClose } = props;
    return (
        <div className={styles.actionSuccess}>
            <div className={styles.actionSuccess__title}>
                {t('menu-tools.signup-login.reset-password-confirmation.title')}
            </div>
            <div className={styles.actionSuccess__description}>
                {t('menu-tools.signup-login.reset-password-confirmation.description', { email })}
            </div>
            <Button
                id='check-your-email|ok'
                label={t('buttons.ok')}
                onClick={onClose}
                key='image-link-primary'
                autoFocus
            />
        </div>
    );
}

function ResetPassword(props: Props) {
    const { t, tt } = useJakhubTranslation();
    const {
        ApplicationStore: { hideAllHeaderTools },
        ProfileStore: { triggerForgottenUserPasswordReset, pwResetResponse, userLoginFormData },
        handleChange,
        setFormMode,
        submitAttempted,
        setSubmitAttempted,
    } = props;
    const { email } = userLoginFormData;

    const handleSubmit = () => {
        setSubmitAttempted(true);
        triggerForgottenUserPasswordReset(userLoginFormData);
    };

    const {
        handleSubmit: RHF_handleSubmit,
        control,
        formState: { errors },
    } = useFormContext();

    if (pwResetResponse?.email === email && email !== '') {
        return <CheckYourEmail email={pwResetResponse.email} onClose={hideAllHeaderTools} />;
    }

    return (
        <form onSubmit={RHF_handleSubmit(handleSubmit)}>
            <div className={styles.signInUpForm__formItem}>
                <Controller
                    name='email'
                    control={control}
                    defaultValue=''
                    rules={EMAIL_RULES}
                    render={({ field: { onChange, name, ...rest } }) => {
                        return (
                            <TextField
                                label={tt('menu-tools.signup-login.email')}
                                id={name}
                                size='small'
                                error={!!errors[name]}
                                InputProps={EmailOrnament()}
                                onChange={(event) => {
                                    onChange(event.target.value);
                                    handleChange(
                                        name,
                                        event as React.ChangeEvent<HTMLInputElement>
                                    );
                                }}
                                {...rest}
                            />
                        );
                    }}
                />
                <ErrorMessages fieldId='email' errors={errors} submitAttempted={submitAttempted} />
            </div>
            <div className={styles.signInUpForm__formItem}>
                <Button
                    id='reset-password|main-call-to-action'
                    label={t('menu-tools.signup-login.btn-reset-pw')}
                    onClick={() => setSubmitAttempted(true)}
                    type='submit'
                />
            </div>
            <div className={styles.switchContext}>
                <div className={styles.switchContextOption}>
                    <span className={styles.switchContext__text}>
                        {t('menu-tools.signup-login.do-you-have-an-account')}
                    </span>
                    <DivButton
                        className={`${styles.switchContextOption} ${styles.switchContextOption__button}`}
                        onClick={() => setFormMode(SIGN_IN)}
                    >
                        {t('menu-tools.signup-login.btn-login')}
                    </DivButton>
                </div>
            </div>
        </form>
    );
}

export default injectStores(['ApplicationStore', 'ProfileStore'])(ResetPassword);
