import { Instance, types } from 'mobx-state-tree';

export const LocalizedLabelMap = types.model('LocalizedLabelMap', {
    en_US: types.optional(types.string, '', [undefined, null]),
    fr_CA: types.optional(types.string, '', [undefined, null]),
    ja_JP: types.optional(types.string, '', [undefined, null]),
    ko_KR: types.optional(types.string, '', [undefined, null]),
    zh_CN: types.optional(types.string, '', [undefined, null]),
});
export type TypeLocalizedLabelMap = Instance<typeof LocalizedLabelMap>;
