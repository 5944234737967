import React from 'react';

import { useJakhubTranslation } from '../../../i18n/jakhub-translation';

import BannerV2 from '../legos/banner/banner-v2';
import Button from '../../controls/button/button';
import SwitchLink from '../../menus/menu/switch-link/switch-link';

function FourOhFour() {
    const { tt } = useJakhubTranslation();
    const namespace = 'common';
    const moduleName = 'ui-errors.four-oh-four';
    const dictionaryBase = `${namespace}:${moduleName}`;

    const { ctaPrimary } = tt(dictionaryBase);

    return (
        <BannerV2
            id='four-oh-four'
            dictionaryBase={dictionaryBase}
            imageId='mountain'
            ctasComponent={
                <SwitchLink
                    akTestId='FourOhFour|LinTo|HOME|HOME'
                    to={{ module: 'HOME', subModule: 'HOME' }}
                >
                    <Button id='go-home-primary-action' label={ctaPrimary} size='large' />
                </SwitchLink>
            }
            minHeight={600}
        />
    );
}

export default FourOhFour;
