import { LOCAL_SERVER_REPLACE_LOOKUP } from './js-constants.js';
import { Resolution } from './stores/models/model-enums';

export const JAKHUB = 'JAKHUB';

export const I18N_MARKER = 'i18n:';

export const AUDIOKINETIC_DOT_COM = 'https://www.audiokinetic.com';
// export const AUDIOKINETIC_DOT_COM = 'https://staging.gowwise.com';
// export const AUDIOKINETIC_DOT_COM = 'https://paul.gowwise.com';

export { LOCAL_SERVER_REPLACE_LOOKUP };
export const APPLICATION_JSON = 'application_json';

export const COOKIE_USER_LOCALE = 'language3'; // This Cookie is shared with fCRM. 3 is AKCONF_FCRM_COOKIE_VERSION.
export const COOKIE_DURATION_IN_DAYS = 30;

export const SEARCH_DEBOUNCE_DELAY = 777;

export const UPLOAD_FILE_TYPES = ['jpeg', 'jpg', 'png']; // Accepted file types for image-uploader
export const UPLOAD_FILE_SIZE_LIMIT = 8388608; // 8MB === 8 * 1024 * 1024

export const HEADER_TOOLS = {
    //  LOGIN TOOLS
    SIGN_IN: 'SIGN_IN',
    SIGN_UP: 'SIGN_UP',
    RESET_PASSWORD: 'RESET_PASSWORD',

    //  GENERAL TOOLS
    DROP_DOWN: 'dropDown',
    GET_STARTED: 'getStarted',
    LANGUAGE_SWITCHER: 'languageSwitcher',
    SEARCH_BAR: 'searchBar',
    SHARE: 'share',
    SUB_MENU_OPTIONS: 'subMenuOptions',
    USER_OPTIONS: 'userOptions',
    POWERED_BY_ITEMS_PER_PAGE: 'poweredByItemsPerPage',
    DRAWER: 'drawer',

    // Switch Link Popup
    SWITCH_LINK_POPUP: 'switchLinkPopup',

    // Contact Forms
    CONTACT_FORM: 'contactForm',
    CONTACT_PROJECT_FORM: 'contactProjectForm',
} as const;

// HEADER_TOOLS names
export type HeaderTools = typeof HEADER_TOOLS[keyof typeof HEADER_TOOLS];

export const MESSAGE_TYPES = {
    NOTIFICATION: 'NOTIFICATION',
    PRIVATE_MESSAGE: 'PRIVATE_MESSAGE',
    ORGANIZATION_INVITATION: 'ORGANIZATION_INVITATION',
    PROJECT_INVITATION: 'PROJECT_INVITATION',
    TASK_NOTIFICATION: 'TASK_NOTIFICATION',
} as const;
export type MessageTypes = typeof MESSAGE_TYPES[keyof typeof MESSAGE_TYPES];

export const LOGIN_TOOLS = {
    SIGN_IN: HEADER_TOOLS.SIGN_IN,
    SIGN_UP: HEADER_TOOLS.SIGN_UP,
    RESET_PASSWORD: HEADER_TOOLS.RESET_PASSWORD,
} as const;
export type LoginTools = typeof LOGIN_TOOLS[keyof typeof LOGIN_TOOLS];

export const CONTACT_FORM = {
    CONTACT_FORM: HEADER_TOOLS.CONTACT_FORM,
    CONTACT_PROJECT_FORM: HEADER_TOOLS.CONTACT_PROJECT_FORM,
} as const;
export type ContactForms = typeof CONTACT_FORM[keyof typeof CONTACT_FORM];

export const SUPPORT_POLICY_ACTIVE = 'active';
export const SUPPORT_POLICY_PRIVATE = 'private';
export const SUPPORT_POLICY_END_OF_LIFE = 'end-of-life';

// Feature flags
export const LOCAL_STORAGE_KEY = 'jakhub_feature_flags';

// Back button management
export const PATH_MAP_COOKIE_NAME = 'jakhub_path_map';

// LogoGrid maxColumns per resolution
export const MAX_COLUMNS_PER_RESOLUTION: Record<Resolution, number> = {
    [Resolution.XSMALL]: 2,
    [Resolution.SMALL]: 3,
    [Resolution.MEDIUM]: 6,
    [Resolution.LARGE]: 9,
    [Resolution.XLARGE]: 9,
    [Resolution.XXLARGE]: 9,
};

export const maxColPerResolution = (resolution: Resolution) => {
    return MAX_COLUMNS_PER_RESOLUTION[resolution];
};

export const TEST_ENVIRONMENT = 'test-environment';

export const YOUTUBE = 'YOUTUBE';
export enum ThirdPartyServicesTypes {
    YOUTUBE = 'YOUTUBE',
    BILIBILI = 'BILIBILI',
    SOUNDCLOUD = 'SOUNDCLOUD',
    AUDIOKINETIC = 'AUDIOKINETIC',
    FACEBOOK = 'FACEBOOK',
    LINKEDIN = 'LINKEDIN',
    TWITTER = 'TWITTER',
    TWITCH = 'TWITCH',
    NAVER = 'NAVER',
    WEIBO = 'WEIBO',
    WECHAT = 'WECHAT',
}
export const AUDIOKINETIC_AUDIO_BASE_URL = 'media.gowwise.com';

export const LINKEDIN_CONVERSION_TRACKER_ID = {
    free: 11976106, // For strata-trial, we want to track stuff See Patrick Saab for details
    paid: null, // For strata, we DO NOT currently track anything for tha paid stuff
};

export const LIBRARY_TAG_TO_LIBRARY_INFO = {
    strata: 'user-based.0',
    'strata-trial': 'trial.0',
};

export const THIRD_PARTY_SERVICES = {
    [ThirdPartyServicesTypes.YOUTUBE]: {
        id: ThirdPartyServicesTypes.YOUTUBE,
        url: 'https://www.youtube.com/',
    },
    [ThirdPartyServicesTypes.BILIBILI]: {
        id: ThirdPartyServicesTypes.BILIBILI,
        url: 'https://www.bilibili.com/',
    },
    [ThirdPartyServicesTypes.SOUNDCLOUD]: {
        id: ThirdPartyServicesTypes.SOUNDCLOUD,
        url: 'https://soundcloud.com/',
    },
    [ThirdPartyServicesTypes.AUDIOKINETIC]: {
        id: ThirdPartyServicesTypes.AUDIOKINETIC,
        url: 'https://audiokinetic.com/',
    },
    [ThirdPartyServicesTypes.FACEBOOK]: {
        id: ThirdPartyServicesTypes.FACEBOOK,
        url: 'https://facebook.com/',
    },
    [ThirdPartyServicesTypes.LINKEDIN]: {
        id: ThirdPartyServicesTypes.LINKEDIN,
        url: 'https://linkedin.com/',
    },
    [ThirdPartyServicesTypes.TWITTER]: {
        id: ThirdPartyServicesTypes.TWITTER,
        url: 'https://twitter.com/',
    },
    [ThirdPartyServicesTypes.TWITCH]: {
        id: ThirdPartyServicesTypes.TWITCH,
        url: 'https://twitch.com/',
    },
    [ThirdPartyServicesTypes.NAVER]: {
        id: ThirdPartyServicesTypes.NAVER,
        url: 'https://www.naver.com/',
    },
    [ThirdPartyServicesTypes.WEIBO]: {
        id: ThirdPartyServicesTypes.WEIBO,
        url: 'https://weibo.com/us',
    },
    [ThirdPartyServicesTypes.WECHAT]: {
        id: ThirdPartyServicesTypes.WECHAT,
        url: 'https://www.wechat.com/',
    },
};

export const IMAGE_POT_TO_CHOOSE_FROM = [
    'ambassador',
    'astronaut',
    'autobenefits',
    'car',
    'city',
    'dashboard',
    'game',
    'goggles',
    'lab',
    'lbe',
    'road',
    'robot',
    'spatial',
    'ufo',
    'worldcommunity',
    'community',
    'train',
    'spacedocks',
];

export const SUPPORTED_OS = {
    WINDOWS: 'windows',
    MACOS: 'mac',
    LINUX: 'linux',
};

export const FILTER_PREFIXES = {
    CREATORS_DIRECTORY: 'cd',
    POWERED_BY_WWISE: 'pbw',
    SUPPORTED_PLATFORMS: 'sp',
    WWISE_PLUGINS: 'wp',
    SUPPORT_TICKETS: 'st',
};
