import React from 'react';
import { useTranslation } from 'react-i18next';

import MenuIconLink, { MenuIconLinkProps } from '../menu-icon-link/menu-icon-link';

function Messages(props: MenuIconLinkProps) {
    const { t } = useTranslation();
    const { badgeCount, className = '', isMobileMenu = false } = props;

    return (
        <MenuIconLink
            target={{ module: 'MESSAGES', subModule: 'MESSAGES' }}
            badgeCount={badgeCount}
            label={t('general:module.messages.menu-label')}
            icon='mail'
            isMobileMenu={isMobileMenu}
            className={className}
        />
    );
}

export default Messages;
