import { Instance, types } from 'mobx-state-tree';

import { mapValues } from 'lodash';

import { MODULES } from '../../modules/modules';

import { HEADER_TOOLS } from '../../constants';

const ModuleIds = mapValues(MODULES, (MOD) => MOD.id);

const HeaderToolsList = {
    ALL_CLOSED: '',
    ...HEADER_TOOLS,
    ...ModuleIds,
};

const HeaderToolsStore = types.model('HeaderToolsStore', {
    openedHeaderToolId: '',
    mobileMenuOpened: false,
    openedHeaderTool: types.optional(
        types.enumeration<typeof HeaderToolsList[keyof typeof HeaderToolsList]>(
            Object.values(HeaderToolsList)
        ),
        HeaderToolsList.ALL_CLOSED
    ),
});

export type TypeHeaderToolsModel = Instance<typeof HeaderToolsStore>;

export default HeaderToolsStore;
