import React from 'react';

import { ModuleRoute } from '../../../../modules/utils/module-interfaces';
import ModuleNavigation from '../../../modules/module-navigation';

import './menu.scss';

export interface MenuProps {
    menuState: Record<string, string>;
}

function MainMenu(props: MenuProps) {
    const { menuState } = props;

    return (
        <div id='main-menu' className='main-menu'>
            <ModuleNavigation
                className='menu-home'
                selectedModules={['HOME']}
                predicate={(module: ModuleRoute) => module.id === 'HOME'}
                menuState={menuState}
            />
            <ModuleNavigation
                className='header-main-menu'
                predicate={(module: ModuleRoute) => module.displayInMenu !== false}
                menuState={menuState}
                goDeep={false}
            />
        </div>
    );
}

export default MainMenu;
