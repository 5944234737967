import React from 'react';
import { inject, observer } from 'mobx-react';
import ApplicationStore from '../application-store';

export function injectStores<StoreName extends string>(stores: StoreName[]) {
    return <T extends React.ComponentType<React.ComponentProps<T>>>(Component: T) =>
        stores.reduce(
            (wrappedFunc, store) => inject(store)(observer(wrappedFunc)),
            Component as React.ComponentType<Omit<React.ComponentProps<T>, StoreName>>
        );
}

const { getUserLocale } = ApplicationStore;
export function getLocalizedLabels(mstMap: any) {
    if (!mstMap) return {};

    const locale = getUserLocale().replace('-', '_');

    const returnThis: Record<string, string> = {};

    const mapSet = new Set(mstMap.keys());
    const mapArray = Array.from(mapSet);
    mapArray.map((k) => (returnThis[k as string] = mstMap.get(k)[locale].split('- ').pop()));

    return returnThis;
}
