import { Instance, types } from 'mobx-state-tree';

//Todo HUB-1067 Investigate FCRM's form validation

const EmailsModel = types.model('EmailsModel', {
    primary: types.maybeNull(types.string),
    primaryChangeTo: types.maybeNull(types.string),
    secondary: types.maybeNull(types.string),
    secondaryChangeTo: types.maybeNull(types.string),
});

const AccountModel = types.model('AccountModel', {
    password: '',
    confirmPassword: '',
    primaryEmail: '',
    secondaryEmail: '',
    firstName: '',
    lastName: '',
    qnaDisplayName: '',
    qnaScore: 0,
    emails: types.optional(EmailsModel, { primary: '' }),
    publicQA: types.frozen({
        displayName: '',
        points: 0,
    }),
});

export type TypeAccountModel = Instance<typeof AccountModel>;
export default AccountModel;
