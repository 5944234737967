import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = `/store`;

export const PATHS = {
    STORE: {
        path: `${moduleRoot}`,
    },
    PAY2GO: {
        path: `${moduleRoot}/strata`,
        hardPath: HARD_PATHS.STORE.PAY2GO,
    },
    PLUGINS: {
        path: `${moduleRoot}/plugins`,
        hardPath: HARD_PATHS.STORE.PLUGINS,
    },
    CERTIFICATIONS: {
        path: `${moduleRoot}/certifications`,
    },
    PURCHASE_CERTIFICATION: {
        path: `${moduleRoot}/purchase-certification/:courseId`,
        hardPath: HARD_PATHS.STORE.PURCHASE_CERTIFICATION,
    },
    STRATA: {
        path: `${moduleRoot}/strata`,
    },
    STRATA_PRICING: {
        path: `${moduleRoot}/strata-pricing`,
    },
    STRATA_PRICING_TAB: {
        path: `${moduleRoot}/strata-pricing/:selectedTab`,
    },
    STRATA_LIBRARY: {
        path: `${moduleRoot}/strata/:libraryId`,
    },
};
