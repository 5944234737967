import { Endpoint } from '../api-interfaces';

export const PROFILE_CONTENT_PROVIDER_DETAILS_ENDPOINT: Endpoint = {
    api: '/api',
    url: ({ entityId }) => `/creatorsDirectory/companyDetails/${entityId}/`,
};

export const PROFILE_SCHOOL_DETAILS_ENDPOINT: Endpoint = {
    api: '/api',
    url: ({ entityId }) => `/creatorsDirectory/schoolDetails/${entityId}/`,
};
