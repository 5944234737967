import React, { PropsWithChildren, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import DivButton from '../legos/div-button/div-button';
import { injectStores } from '../../../stores/factories/store-utils';
import { TypeApplicationStore } from '../../../stores/application-store';

import './menu-message.scss';

type MessageConfig = {
    i18nId: string;
    publishDateStart: string | null;
    publishDateEnd: string | null;
    cookieResetDate: string;
    cookieExpiry: number;
    barColor: string;
};
interface Props extends PropsWithChildren<{}> {
    ApplicationStore: TypeApplicationStore;
    messageConfig: MessageConfig;
    isDismissable?: boolean;
}
function MenuMessage(props: Props) {
    const {
        ApplicationStore: { serverDate },
        messageConfig = {
            i18nId: '',
            publishDateStart: '',
            publishDateEnd: '',
            cookieResetDate: '',
            cookieExpiry: 0,
            barColor: '',
        },
        isDismissable = true,
        children,
    } = props;

    const { i18nId, publishDateStart, publishDateEnd, cookieResetDate, cookieExpiry, barColor } =
        messageConfig;

    const serverDateString = new Date(serverDate || 0)?.toISOString()?.split('T')[0];
    const cookieKey = `${i18nId}|Dismissed`;
    useEffect(() => {
        // Reset cookie because this is when our vacation starts and we want
        // to make sure the warning is fresh in user's minds :-)
        if (serverDateString === cookieResetDate) {
            Cookies.set(cookieKey, '');
        }
    });

    const [messageIsDismissed, setMessageIsDismissed] = useState(Cookies.get(cookieKey));

    const dismissThisMessage = () => {
        setMessageIsDismissed('DISMISSED');
        Cookies.set(cookieKey, 'DISMISSED', { expires: cookieExpiry });
    };

    if (
        (publishDateStart && publishDateStart > serverDateString) ||
        (publishDateEnd && publishDateEnd < serverDateString)
    )
        return null;
    if (messageIsDismissed === 'DISMISSED' && isDismissable) return null;

    return (
        <div id='menu-message' className='menu-message' style={{ background: barColor }}>
            <div className='menu-message-content'>
                {children}
                {isDismissable ? (
                    <div>
                        <DivButton className='close-button' onClick={dismissThisMessage}>
                            <i className='material-icons white-icon-style'>close</i>
                        </DivButton>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default injectStores(['ApplicationStore'])(MenuMessage);
