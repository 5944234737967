import { Endpoint } from '../api-interfaces';
import { RESOURCE_LOCATION } from './general';

export const YOUTUBE_CATEGORIES: Endpoint = {
    api: RESOURCE_LOCATION,
    url: () => 'video-categories.json',
};

export const YOUTUBE_VIDEOS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/videos/',
    method: 'POST',
    formData: ({ count = 100 }) => ({
        count,
    }),
};

export const STRATA_HOW_TO_VIDEOS_STATIC_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/strata-how-to-videos/',
};

export const GET_SOUNDCLOUD_TRACK_INFO: Endpoint = {
    api: 'https://soundcloud.com/oembed',
    url: (params: any) => `?format=json&url=${params.url}`,
    method: 'GET',
};
