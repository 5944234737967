/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';
import ApiActionFactory from '../../api/api-action-factory';
import StoreFactory from '../factories/store';

import { HOLIDAYS_ENDPOINT } from '../../api/endpoints';
import HolidayModel from './models/holiday-model';

import { isDev } from '../../utils/build-utils';

const HolidayStore = StoreFactory({
    modelName: 'HolidayStore',
    models: [
        ApiActionFactory({
            getHolidays: {
                endpoint: HOLIDAYS_ENDPOINT,
                target: 'holidays',
                getServerDate: true,
            },
        }),
    ],
    modelStructure: {
        isLoading: false,
        title: 'HolidayStore',
        serverDate: '',
        holidays: types.maybeNull(types.array(HolidayModel)),
    },
}).actions((self) => ({
    retrieveHolidays() {
        if (!self.holidays) {
            self.getHolidays();
        }
    },
}));

export type TypeHolidayStore = Instance<typeof HolidayStore>;

const HolidayStoreInstance = HolidayStore.create();
declare const window: Window &
    typeof globalThis & {
        HolidayStore: typeof HolidayStoreInstance;
    };

if (window && isDev()) {
    window.HolidayStore = HolidayStoreInstance;
}

export default HolidayStore;
