import { HARD_PATHS } from '../utils/hard-paths';
import { featureFlags } from '../../utils/feature-flags';

const moduleRoot = `/community`;

export const PATHS = {
    COMMUNITY: {
        path: `${moduleRoot}`,
    },
    BLOG: {
        path: `${moduleRoot}/blog`,
        hardPath: featureFlags.COMMUNITY_BLOG_PAGE === true ? undefined : HARD_PATHS.COMMUNITY.BLOG,
    },
    SUBMIT_BLOG: {
        path: `${moduleRoot}/blog/submit`,
        hardPath: HARD_PATHS.COMMUNITY.SUBMIT_BLOG,
    },
    SUBMIT_VIDEO: {
        path: `${moduleRoot}/blog/submit`,
        hardPath: HARD_PATHS.COMMUNITY.SUBMIT_VIDEO,
    },
    JOB_SKILLS: {
        path: `${moduleRoot}/blog/game-audio-job-skills`,
        hardPath: HARD_PATHS.COMMUNITY.JOB_SKILLS,
    },
    CONTRIBUTORS: {
        path: `${moduleRoot}/contributors`,
        hardPath: HARD_PATHS.COMMUNITY.CONTRIBUTORS,
    },
    CREATORS_DIRECTORY: {
        path: `${moduleRoot}/creators-directory`,
    },
    QUESTIONS_ANSWERS: {
        path: `${moduleRoot}/questions-answers`,
        hardPath: HARD_PATHS.COMMUNITY.QUESTIONS_ANSWERS,
    },
    QUESTIONS: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.COMMUNITY.QUESTIONS,
    },
    ASK_A_QUESTION: {
        path: `${moduleRoot}/questions-answers/ask-a-question`,
        hardPath: HARD_PATHS.COMMUNITY.ASK_A_QUESTION,
    },
    QUESTIONS_UNANSWERED: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.COMMUNITY.QUESTIONS_UNANSWERED,
    },
    STRATA_QUESTIONS_UNANSWERED: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.COMMUNITY.STRATA_QUESTIONS_UNANSWERED,
    },
    EVENTS: {
        path: `${moduleRoot}/events`,
        hardPath: HARD_PATHS.COMMUNITY.EVENTS,
    },
    WWISETOUR: {
        path: `${moduleRoot}/wwise-tour`,
        hardPath: HARD_PATHS.COMMUNITY.WWISETOUR,
    },
    AMBASSADOR: {
        path: `${moduleRoot}/ambassador`,
    },
    ONE_MINUTE_WWISE: {
        path: `${moduleRoot}/one-minute-wwise`,
        hardPath: HARD_PATHS.COMMUNITY.ONE_MINUTE_WWISE,
    },
    WWISE_UP_ON_AIR: {
        path: `${moduleRoot}/wwise-up-on-air`,
        hardPath: HARD_PATHS.COMMUNITY.WWISE_UP_ON_AIR,
    },
    WWISE_INTERACTIVE_MUSIC_SYMPOSIUM: {
        path: `${moduleRoot}/wwise-interactive-music-symposium`,
        hardPath: HARD_PATHS.COMMUNITY.WWISE_INTERACTIVE_MUSIC_SYMPOSIUM,
    },
    REGISTER_COMPANY: {
        path: `${moduleRoot}/directory/register`,
        hardPath: HARD_PATHS.COMMUNITY.REGISTER_COMPANY,
    },
    CREATORS_DIRECTORY_FAQ: {
        path: `${moduleRoot}/directory/faq`,
        hardPath: HARD_PATHS.COMMUNITY.CREATORS_DIRECTORY_FAQ,
    },
    FIND_COMPANY: {
        path: `${moduleRoot}/creators-directory?curTab=COMPANIES`,
    },
    FIND_SCHOOL: {
        path: `${moduleRoot}/creators-directory?curTab=SCHOOLS`,
    },
    FIND_CERTIFIED_USER: {
        path: `${moduleRoot}/creators-directory?curTab=PEOPLE`,
    },
    GET_CERTIFIED: {
        path: `${moduleRoot}/learn/certifications`,
        hardPath: HARD_PATHS.COMMUNITY.GET_CERTIFIED,
    },
    SUBSCRIBE_YOUTUBE: {
        path: `${moduleRoot}/directory/users`,
        hardPath: HARD_PATHS.COMMUNITY.SUBSCRIBE_YOUTUBE,
    },
    SUBSCRIBE_BILIBILI: {
        path: `${moduleRoot}/directory/users`,
        hardPath: HARD_PATHS.COMMUNITY.SUBSCRIBE_BILIBILI,
    },
    SUBSCRIBE_TWITCH: {
        path: `${moduleRoot}/directory/users`,
        hardPath: HARD_PATHS.COMMUNITY.SUBSCRIBE_TWITCH,
    },
    GAME_JAMS: {
        path: `${moduleRoot}/NEW_COMPONENT_PATH`,
        hardPath: HARD_PATHS.COMMUNITY.GAME_JAMS,
    },
    REGISTER_GAME_JAM: {
        path: `${moduleRoot}/register-game-jam`,
        hardPath: HARD_PATHS.COMMUNITY.REGISTER_GAME_JAM,
    },
    WWIZARDS_WWITCHES: {
        path: 'hard-path to facebook',
        hardPath: HARD_PATHS.COMMUNITY.WWIZARDS_WWITCHES,
    },
    PARTNER_PROGRAM: {
        path: 'hard-path to facebook',
        hardPath: HARD_PATHS.COMMUNITY.PARTNER_PROGRAM,
    },
};
