const moduleRoot = `/settings`;

export const PATHS = {
    ACCOUNT_SETTINGS: {
        path: `${moduleRoot}/account-settings`,
    },
    ACCOUNT_VERIFICATION: {
        path: `${moduleRoot}/account-verification`,
    },
    ACCOUNT_SETTINGS_OPTIONS: {
        path: `${moduleRoot}/account-settings/:optionId`,
    },
};
