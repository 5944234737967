import { Instance, types } from 'mobx-state-tree';
import CardInfoModel from './card-info-model';

const BillingModel = types.model('BillingModel', {
    cardType: types.optional(types.string, '', [null, undefined]),
    cardLogo: types.maybeNull(types.string),
    cardInfo: types.union(CardInfoModel, types.frozen()),
    isDefault: types.boolean,
    reference: types.maybeNull(types.string),
});

export type TypeBillingModel = Instance<typeof BillingModel>;
export default BillingModel;
