import { IAnyType, Instance, types } from 'mobx-state-tree';

export const MenuItemModel = types.snapshotProcessor(
    types.model('MenuItemModel', {
        id: types.optional(types.string, '', [undefined, null]),
        label: types.optional(types.string, '', [undefined, null]),
        type: types.optional(types.string, '', [undefined, null]),
        menu: types.array(types.late((): IAnyType => MenuItemModel)),
        menuUrl: types.optional(types.string, '', [undefined, null]),
    }),
    {
        preProcessor(sn: any) {
            const fix = {
                thisString: '://blog.',
                forThis: '://www.',
            };
            const fixedUrl =
                window.location.origin.indexOf(fix.thisString) !== -1
                    ? window.location.origin.replace(fix.thisString, fix.forThis)
                    : '';
            return {
                ...sn,
                menuUrl: `${fixedUrl}${sn.menuUrl}`,
            };
        },
    }
);

export type TypeMenuItemModel = Instance<typeof MenuItemModel>;
export default MenuItemModel;
