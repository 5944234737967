import { lazy } from 'react';
import { typedRoutes } from './utils/module-interfaces';
import { PATHS } from './soft-paths/customers';

export const ROUTES = typedRoutes({
    CUSTOMERS: {
        ...PATHS.CUSTOMERS,
        id: 'CUSTOMERS',
        name: 'Customers',
        i18nBase: 'customers:module',
        i18nId: 'customers:module.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/customers/customers')),
    },
    SUPPORT_TICKETS: {
        ...PATHS.SUPPORT_TICKETS,
        id: 'SUPPORT_TICKETS',
        name: 'Support Tickets',
        i18nBase: 'customers:module',
        i18nId: 'customers:module.menu-label',
        displayInMenu: false,
        privateMode: true,
        component: lazy(() => import('../components/modules/customers/support-tickets')),
    },
});

export const DEFAULT_ROUTE = ROUTES.SUPPORT_TICKETS;
