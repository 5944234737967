import { useEffect } from 'react';
import useTimeout from './useTimeout';
import useUpdateEffect from './useUpdateEffect';

export default function useDebounce(
    callback: () => any,
    delay: number,
    dependencies: any,
    leadingCall = false
) {
    const { reset, clear, done } = useTimeout(callback, delay);
    useEffect(() => {
        reset();
    }, [...dependencies, reset]);
    useEffect(clear, []);

    useUpdateEffect(() => {
        if (done && leadingCall) {
            callback();
        }
    }, dependencies);
}
