import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { sortBy } from 'lodash';
import { useLocation } from 'react-router-dom';
import FormGroup from '@material-ui/core/FormGroup';

import { useJakhubTranslation } from '../../../i18n/jakhub-translation';
import { injectStores } from '../../../stores/factories/store-utils';
import { TypeApplicationStore } from '../../../stores/application-store';
import { TypeProfileStore } from '../../../stores/profile-store';

import { EMAIL_RULES, PASSWORD_RULES } from '../legos/field-rules';
import ErrorMessages from '../legos/error-messages';
import { SignupData } from '../sign-in-up-interfaces';

import Button from '../../controls/button/button';
import CheckBox from '../../controls/checkbox/checkbox';
import DivButton from '../../common/legos/div-button/div-button';
import { EmailOrnament, PasswordOrnament } from '../legos/textfield-ornaments';
import PasswordStrength from '../legos/password-strength';
import SwitchLink from '../../menus/menu/switch-link/switch-link';
import TextField from '../../controls/text-field/text-field';
import { TypeSubscriptionModel } from '../../../stores/models/subscription-info-model';

import { MODULES } from '../../../modules/modules';

import { LOGIN_TOOLS } from '../../../constants';

import styles from '../sign-in-up/sign-in-up.module.scss';

const { SIGN_IN } = LOGIN_TOOLS;

type SignupOption = {
    id: string;
} & TypeSubscriptionModel;
interface CheckBoxGroupProps {
    signupData: SignupData;
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    signupOptions: SignupOption[];
}

interface Props {
    ApplicationStore: TypeApplicationStore;
    ProfileStore: TypeProfileStore;
    signupData: SignupData;
    handleChange(id: string, event: React.ChangeEvent<HTMLInputElement>): void;
    handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>): void;
    setFormMode(newMode: string): void;
    submitAttempted: boolean;
    setSubmitAttempted: (newState: boolean) => void;
    postSignUpTargetPath?: string;
    noSubscribe?: boolean;
}

function CheckBoxGroup(props: CheckBoxGroupProps) {
    const { signupData, signupOptions, handleCheckboxChange } = props;
    const { Blog, Strata, Wwise } = signupData;

    const checkValues: Record<string, any> = { Blog, Strata, Wwise };

    return (
        <FormGroup className={styles.muiFormGroupRoot}>
            {signupOptions.map(({ description, id }: SignupOption) => (
                <CheckBox
                    size='small'
                    key={`SubscriptionId|${id}`}
                    className='sign-up-options'
                    checked={checkValues[id]}
                    onChange={handleCheckboxChange}
                    name={id}
                    label={description}
                />
            ))}
        </FormGroup>
    );
}

/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
function Signup(props: Props) {
    const { t, tt } = useJakhubTranslation();
    const {
        ApplicationStore: { hideAllHeaderTools },
        ProfileStore: {
            userSignup,
            signupResponse,
            userLoginFormData,
            postSignUpTarget,
            loginInProgress,
            retrieveEmailSubscriptionsInfo,
            getEmailSubscriptionsInfo,
        },
        signupData,
        handleCheckboxChange,
        handleChange,
        setFormMode,
        submitAttempted,
        setSubmitAttempted,
        postSignUpTargetPath = MODULES.PROFILE.routes.PROFILE_CREATE_THANK_YOU.path,
        noSubscribe = false,
    } = props;
    const {
        handleSubmit: RHF_handleSubmit,
        control,
        formState: { errors },
        getValues,
    } = useFormContext();

    useEffect(retrieveEmailSubscriptionsInfo, []);
    const subscriptionInfo = getEmailSubscriptionsInfo();

    const toggles = subscriptionInfo.emailSubscriptions || {};
    const orderedToggles = sortBy(
        Object.keys(toggles).map((t) => ({ ...toggles[t], id: t })),
        ['ordinal']
    ).filter((t) => t.displayOnCreateAccount);

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const invitationToken = encodeURIComponent(queryParameters.get('invitation') || '');

    const [passwordVisible, setPasswordVisible] = useState(false);

    useEffect(() => {
        postSignUpTarget(postSignUpTargetPath);
    }, []);

    const handleSubmit = () => {
        setSubmitAttempted(true);
        const signUpObject = { ...userLoginFormData, ...signupData };
        if (invitationToken) {
            signUpObject['projectInvitationToken'] = invitationToken;
        }

        userSignup(signUpObject);
    };

    if (signupResponse?.code === 200) hideAllHeaderTools();

    return (
        <form onSubmit={RHF_handleSubmit(handleSubmit)}>
            <div className={styles.signInUpForm__formItem}>
                <Controller
                    name='email'
                    control={control}
                    defaultValue=''
                    rules={EMAIL_RULES}
                    render={({
                        formState: { errors },
                        field: { onChange, name, ref, ...rest },
                    }) => {
                        console.log('errors :  ', errors);
                        const showError = submitAttempted && !!errors[name];
                        return (
                            <TextField
                                label={tt('menu-tools.signup-login.email')}
                                id={name}
                                size='small'
                                error={showError}
                                InputProps={EmailOrnament()}
                                disabled={loginInProgress}
                                inputRef={ref}
                                onChange={(event) => {
                                    onChange(event.target.value);
                                    handleChange(
                                        name,
                                        event as React.ChangeEvent<HTMLInputElement>
                                    );
                                }}
                                {...rest}
                            />
                        );
                    }}
                />
                <ErrorMessages fieldId='email' errors={errors} submitAttempted={submitAttempted} />
            </div>
            <div className={styles.signInUpForm__formItem}>
                <Controller
                    name='password'
                    control={control}
                    defaultValue=''
                    rules={PASSWORD_RULES}
                    render={({ field: { onChange, name, ref, ...rest } }) => {
                        const showError = submitAttempted && !!errors[name];
                        return (
                            <>
                                <TextField
                                    label={tt('menu-tools.signup-login.password')}
                                    id={name}
                                    size='small'
                                    error={showError}
                                    type={passwordVisible ? 'text' : 'password'}
                                    InputProps={PasswordOrnament({
                                        passwordVisible,
                                        setPasswordVisible,
                                    })}
                                    disabled={loginInProgress}
                                    inputRef={ref}
                                    onChange={(event) => {
                                        onChange(event.target.value);
                                        handleChange(
                                            name,
                                            event as React.ChangeEvent<HTMLInputElement>
                                        );
                                    }}
                                    {...rest}
                                />
                                <PasswordStrength password={getValues().password} />
                            </>
                        );
                    }}
                />
                <ErrorMessages
                    fieldValue={getValues().password}
                    submitAttempted={submitAttempted}
                    fieldId='password'
                    errors={errors}
                    instructions={['pw_tooShort', 'pw_lowercase', 'pw_uppercase', 'pw_number']}
                />
            </div>
            <div className={styles.switchContext}>
                <span>
                    {t('menu-tools.signup-login.3-block-agreement', {
                        AK_TERMS_LINK: (
                            <SwitchLink
                                akTestId='SignUp|LinkTo|LEGAL|'
                                to={{ module: 'LEGAL', subModule: 'TERMS_OF_USE' }}
                            />
                        ),
                        AK_PRIVACY_POLICY_LINK: (
                            <SwitchLink
                                akTestId='SignUp|LinkTo|LEGAL|PRIVACY_POLICY'
                                to={{ module: 'LEGAL', subModule: 'PRIVACY_POLICY' }}
                            />
                        ),
                        AK_USER_ACCOUNT_POLICY_LINK: (
                            <SwitchLink
                                akTestId='SignUp|LinkTo|LEGAL|USER_ACCOUNT_POLICY'
                                to={{ module: 'LEGAL', subModule: 'USER_ACCOUNT_POLICY' }}
                            />
                        ),
                    })}
                </span>
                {!noSubscribe && (
                    <CheckBoxGroup
                        signupData={signupData}
                        signupOptions={orderedToggles}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                )}
                <div className={styles.signInUpForm__formItem}>
                    <Button
                        id='signup|main-call-to-action'
                        label={t('menu-tools.signup-login.btn-sign-up')}
                        onClick={() => setSubmitAttempted(true)}
                        type='submit'
                        disabled={loginInProgress}
                    />
                </div>
                <div className={styles.switchContextOption}>
                    <span className={styles.switchContext__text}>
                        {t('menu-tools.signup-login.do-you-have-an-account')}
                    </span>
                    <DivButton
                        className={`${styles.switchContextOption} ${styles.switchContextOption__button}`}
                        onClick={() => setFormMode(SIGN_IN)}
                    >
                        <span>{t('menu-tools.signup-login.btn-login')}</span>
                    </DivButton>
                </div>
            </div>
        </form>
    );
}
/* eslint-enable max-lines-per-function */
/* eslint-enable max-statements */

export default injectStores(['ApplicationStore', 'ProfileStore'])(Signup);
