import { Instance, types } from 'mobx-state-tree';

import { LibraryPurchaseType } from '../../models/model-enums';

export const LibraryModel = types.model('LibraryModel', {
    tag: types.optional(types.string, '', [undefined, null]),
    title: types.optional(types.string, '', [undefined, null]),
    purchaseType: types.enumeration<LibraryPurchaseType>(
        'LibraryPurchaseType',
        Object.values(LibraryPurchaseType)
    ),
    purchaseLink: types.string,
});

export type TypeLibraryModel = Instance<typeof LibraryModel>;
export default LibraryModel;
