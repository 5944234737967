import React from 'react';
import { DEFAULT_ROUTE, MODULES } from '../../../modules/modules';
import { useLocation } from 'react-router-dom';
import { ModuleId, RouteId, TargetType } from '../../../modules/utils/module-interfaces';
import { supportedLanguages } from '../../../i18n/supported-locales';
import { map } from 'lodash';
import { isJakhub, isJakhubForChina, upperSnakeCase } from '../../../utils/utils';
import {
    getPredicatedModules,
    getSelectedModules,
    isValidModule,
} from '../../../modules/utils/module-utils';
import { Helmet } from 'react-helmet';
import { useJakhubTranslation } from '../../../i18n/jakhub-translation';

export const locationToModuleId = (
    pathname: string
): { currentModuleId: ModuleId; currentSubModuleId: ModuleId } => {
    const appPathWithoutLang = ((pathString) => {
        // Remove the first '/' then split into an array of segments
        const pathArray = pathString.substring(1).split('/');
        // First segment is a Language slug?
        const segments = supportedLanguages
            .map((lang) => lang.toUpperCase())
            .includes(pathArray[0].toUpperCase())
            ? pathArray.slice(1)
            : pathArray;
        return `/${segments.join('/')}`;
    })(pathname);

    const [currentModuleId, currentSubModuleId] = map(
        appPathWithoutLang.substring(1).split('/'),
        (i) => upperSnakeCase(i) as ModuleId | MagicSlug | 'GAME_JAMS'
    );

    const { moduleIsValid, subModuleIsValid } = isValidModule({
        module: currentModuleId,
        subModule: currentSubModuleId,
    } as TargetType<ModuleId>);

    if (moduleIsValid && subModuleIsValid) {
        return {
            currentModuleId: currentModuleId as ModuleId,
            currentSubModuleId: currentSubModuleId as RouteId,
        };
    }

    // SECOND TRY TO IDENTIFY MODULES FOR FCRM LINKS
    const fcrmMagicList = {
        BLOG: 'COMMUNITY',
        COMMUNITY: 'COMMUNITY',
        DIRECTORY: 'COMMUNITY',
        QA: 'COMMUNITY',
        DISCOVER: 'SOLUTIONS',
        LEARN: 'LEARNING',
        COURSES: 'LEARNING',
        PRODUCTS: 'PRODUCTS',
        LIBRARY: 'DOCUMENTATION',
    } as const;
    type MagicSlug = keyof typeof fcrmMagicList;

    let magicRootSegment = fcrmMagicList[currentModuleId as MagicSlug];
    let magicSecondarySegment = currentModuleId === 'COURSES' ? 'LEARN_WWISE' : currentSubModuleId;
    magicSecondarySegment =
        currentModuleId === 'DIRECTORY' ? 'CREATORS_DIRECTORY' : magicSecondarySegment;
    magicSecondarySegment = currentModuleId === 'QA' ? 'QUESTIONS_ANSWERS' : magicSecondarySegment;
    magicSecondarySegment = currentModuleId === 'LIBRARY' ? 'DOCUMENTATION' : magicSecondarySegment;
    magicSecondarySegment =
        currentModuleId === 'LEARNING' && currentSubModuleId === 'GAME_JAMS'
            ? 'EVENTS'
            : magicSecondarySegment;

    if (window.location.hostname.indexOf('blog') !== -1) {
        magicRootSegment = 'COMMUNITY';
        magicSecondarySegment = 'BLOG';
    }

    const { moduleIsValid: fallBackModuleIsValid, subModuleIsValid: fallBackSubModuleIsValid } =
        isValidModule({
            module: magicRootSegment as ModuleId,
            subModule: magicSecondarySegment,
        } as TargetType<ModuleId>);
    return {
        currentModuleId: fallBackModuleIsValid ? magicRootSegment : DEFAULT_ROUTE.id,
        currentSubModuleId:
            fallBackModuleIsValid && fallBackSubModuleIsValid
                ? (magicSecondarySegment as ModuleId)
                : ('' as ModuleId),
    };
};

const currentModuleInfo = (menuState: Record<string, ModuleId>) => {
    const { currentModuleId, currentSubModuleId } = menuState;
    const moduleId = currentModuleId as ModuleId;
    const subModuleId = (currentSubModuleId ? currentSubModuleId : currentModuleId) as RouteId;

    const { i18nBase, i18nId } = MODULES[moduleId].routes[subModuleId];
    return { i18nBase, i18nId, moduleId: currentModuleId, subModuleId };
};

export const countSubModules = (moduleId: ModuleId) => {
    const currentModuleSubRoutes = getSelectedModules(MODULES, [moduleId])[moduleId]?.routes || {};
    const entriesToDisplay =
        getPredicatedModules(currentModuleSubRoutes, (module) => module.displayInMenu !== false) ||
        {};
    return Object.keys(entriesToDisplay).length;
};

const unchangeableTitles = ['DOCUMENTATION', 'BLOG', 'SEARCH'];

type Props = {
    title?: string;
    description?: string;
};
export default function HeaderHelmet(props: Props) {
    const { tt } = useJakhubTranslation();
    const { title: titleOverride, description: descriptionOverride } = props;
    const { pathname } = useLocation();
    const menuState = locationToModuleId(pathname);

    const { i18nBase, i18nId, moduleId, subModuleId } = currentModuleInfo(menuState);
    const doNotChangeTitle =
        unchangeableTitles.includes(moduleId) || unchangeableTitles.includes(subModuleId);

    const dictHelmetId = `helmet:${moduleId}.${subModuleId}` || '';
    const helmetId = `${i18nBase}.helmet` || '';

    // Priority #1 -> Use override if present (titleOverride, descriptionOverride)

    // Priority #2 -> Use data from i18n/locales/xx-XX/helmet.json
    const { title: titleHelmet, description: descriptionHelmet } = tt(dictHelmetId);

    // Priority #3 -> Use data from i18nBase.helmet in appropriate dictionary file
    const { title: titleDict, description: descriptionDict } = tt(helmetId);

    // Priority #4 - Use menu-label used to get to page
    const menuLinkTitle = tt(i18nId);

    //                   Prio #1       || Prio #2     || Prio #3   || Prio #4
    const title = titleOverride || titleHelmet || titleDict || menuLinkTitle;
    const description = descriptionOverride || descriptionHelmet || descriptionDict || null;

    const titleSuffix = ` | Audiokinetic${isJakhubForChina() ? '.cn' : ''}`;
    const akTitle = `${title}${subModuleId === 'HOME' ? '' : titleSuffix}`;

    if (!isJakhub() || doNotChangeTitle) return null;

    return (
        <Helmet>
            {akTitle ? <title>{`${akTitle}`}</title> : null}
            {title ? <meta name='title' content={title} /> : null}
            {description ? <meta name='description' content={description} /> : null}
        </Helmet>
    );
}
