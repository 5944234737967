import { Instance, types } from 'mobx-state-tree';

export const CategoryData = types.model('CategoryData', {
    en_US: types.optional(types.string, '', [undefined, null]),
    fr_CA: types.optional(types.string, '', [undefined, null]),
    ja_JP: types.optional(types.string, '', [undefined, null]),
    ko_KR: types.optional(types.string, '', [undefined, null]),
    zh_CN: types.optional(types.string, '', [undefined, null]),
});
export type TypeCategoryDataModel = Instance<typeof CategoryData>;
export type TypeCategoryDataPropeties = Instance<typeof CategoryData.properties>;

export const AllCategoryData: TypeCategoryDataModel = {
    en_US: 'All',
    fr_CA: 'Toutes',
    ja_JP: '全て',
    ko_KR: '모두',
    zh_CN: '全部',
};

export const BlogCategories = types.model('BlogCategories', {
    all: types.frozen(AllCategoryData),
    game_audio: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
    tips_tools: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
    spatial_audio: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
    vr_experiences: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
    interactive_audio: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
    interactive_music: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
    audio_programming: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
    automotive: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
    community_events: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
    new_releases: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
    sound_design: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
    lbe: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
    strata_tips_tools: types.optional(CategoryData, {} as TypeCategoryDataModel, [null, undefined]),
});
export type TypeBlogCategoriesModel = Instance<typeof BlogCategories>;
export type TypeBlogCategoriesProperties = Instance<typeof BlogCategories.properties>;

export const BlogPostCount = types.model('BlogPostCount', {
    en_US: types.optional(types.number, 0, [undefined, null]),
    fr_CA: types.optional(types.number, 0, [undefined, null]),
    ja_JP: types.optional(types.number, 0, [undefined, null]),
    ko_KR: types.optional(types.number, 0, [undefined, null]),
    zh_CN: types.optional(types.number, 0, [undefined, null]),
});

const BlogModel = types.model('BlogModel', {
    tags: BlogCategories,
    postCount: BlogPostCount,
});
export type TypeBlogModel = Instance<typeof BlogModel>;

export default BlogModel;
