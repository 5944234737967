/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';
import Cookies from 'js-cookie';
import { includes } from 'lodash';

import { COOKIE_USER_LOCALE, COOKIE_DURATION_IN_DAYS, HEADER_TOOLS } from '../constants';

import { supportedLocales, SupportedLocale, SupportedLocales } from '../i18n/supported-locales';

import { isDev, isJakhubForChina } from '../utils/build-utils';
import { localeToLanguage } from '../utils/utils';

import CountriesRegionsStore from './application-stores/countries-regions-store';
import HeaderToolsStore from './application-stores/header-tools-store';
import SchoolStore from './application-stores/school-store';
import ThirdPartyServicesStore from './application-stores/third-party-services-store';

import { createApiAction } from '../api/api-action-factory';
import { TypedStoreConfig } from './factories/store';

import {
    ALIVE_ENDPOINT,
    ENVIRONMENT_ID_ENDPOINT,
    CREATE_SCHOOL_ENDPOINT,
    CONTACT_AUDIOKINETIC_ENDPOINT,
} from '../api/endpoints';
import { ApplicationLanguage, ApplicationLocale } from '../signals/locale';

const { SEARCH_BAR, SIGN_IN, SIGN_UP, CONTACT_FORM } = HEADER_TOOLS;

const MOBILE_MENU_CLOSERS = [SIGN_IN, SIGN_UP, SEARCH_BAR, CONTACT_FORM];

const BackendIsAliveModel = types.model('BackendIsAliveModel', {
    title: types.maybeNull(types.string),
    author: types.maybeNull(types.string),
    branch: types.maybeNull(types.string),
    date: types.maybeNull(types.string),
    subject: types.maybeNull(types.string),
});

const PersistentObjectModel = types.model('PersistentObjectModel', {
    userIsAuthorized: false,
});
type TypePersistentObjectProperties = Instance<typeof PersistentObjectModel.properties>;

const SchoolCreateModel = types.model('SchoolCreateModel', {
    school: types.model('school', { code: types.number }),
});

const ApplicationStore = TypedStoreConfig({
    modelName: 'ApplicationStore',
    properties: {
        i18nLoaded: false,
        serverDate: '',
        userLocale: types.optional(
            types.enumeration<SupportedLocale>(Object.values(supportedLocales)),
            supportedLocales[1]
        ),
        headerTools: HeaderToolsStore,
        headerToolId: '',
        backendAlive: types.maybeNull(BackendIsAliveModel),
        backendEnvironmentId: types.string,
        thirdPartyServices: ThirdPartyServicesStore,
        countriesRegionsStore: CountriesRegionsStore,
        SchoolStore: SchoolStore,
        schoolCreateResult: types.maybeNull(SchoolCreateModel),
        persistentObject: PersistentObjectModel,
        unwantedResponse: types.frozen({}),
    },
})
    .actions((self) => ({
        isBackendAlive: createApiAction(self, {
            endpoint: ALIVE_ENDPOINT,
            target: 'backendAlive',
            getServerDate: true,
        }),
        getBackendEnvironmentId: createApiAction(self, {
            endpoint: ENVIRONMENT_ID_ENDPOINT,
            target: 'backendEnvironmentId',
        }),
        sendContactForm: createApiAction(self, {
            endpoint: CONTACT_AUDIOKINETIC_ENDPOINT,
            target: 'unwantedResponse',
            onTrigger(self) {
                self.showToolInHeader('', '');
            },
        }),
        submitSchoolCreateForm: createApiAction(self, {
            endpoint: CREATE_SCHOOL_ENDPOINT,
            target: 'schoolCreateResult',
        }),
    }))
    .actions((self) => ({
        clearDump() {
            self.unwantedResponse = {};
        },
        closeMobileMenu() {
            self.headerTools.mobileMenuOpened = false;
        },
    }))
    .actions((self) => ({
        setUserLocale(newLocale: SupportedLocales) {
            self.userLocale = newLocale;
            self.closeMobileMenu();
            const {
                location: { hostname },
            } = window;
            const hostName = hostname.split('.').slice(-2).join('.');

            ApplicationLocale.value = newLocale;
            ApplicationLanguage.value = localeToLanguage(newLocale);

            Cookies.set(COOKIE_USER_LOCALE, localeToLanguage(newLocale), {
                domain: hostName,
                expires: COOKIE_DURATION_IN_DAYS,
            });
        },
        showToolInHeader(toolToShow: string, id = '') {
            const { openedHeaderToolId, openedHeaderTool } = self.headerTools;
            const newToolId = id === openedHeaderToolId ? '' : id;

            self.headerTools = {
                ...self.headerTools,
                openedHeaderToolId: newToolId,
                openedHeaderTool: !(openedHeaderTool === toolToShow && newToolId === '')
                    ? toolToShow
                    : '',
            };

            if (includes([...MOBILE_MENU_CLOSERS, ''], toolToShow)) {
                self.closeMobileMenu();
            }
        },
    }))
    .actions((self) => ({
        toggleMobileMenu() {
            const currentMobileMenuState = self.headerTools.mobileMenuOpened;
            self.showToolInHeader('');
            self.headerTools.mobileMenuOpened = !currentMobileMenuState;
        },
    }))
    .actions((self) => ({
        hideAllHeaderTools() {
            self.showToolInHeader('');
        },
        setPersistentObject(
            persistentObjKey: keyof TypePersistentObjectProperties,
            persistentObjValue: any
        ) {
            //works but we should look into a solution that doesn't use any if we continue to use this
            self.persistentObject[persistentObjKey] = persistentObjValue;
        },
    }))
    .views((self) => ({
        getUserLocale(): string {
            return self.userLocale || '';
        },
        get userLanguage(): string {
            const defaultUserLanguage = isJakhubForChina() ? 'zh' : supportedLocales[0];
            return self.userLocale.split('-')[0] || defaultUserLanguage;
        },
        applicationIsLoaded() {
            return self.i18nLoaded;
        },
    }));

export type TypeApplicationStore = Instance<typeof ApplicationStore>;

const ApplicationStoreInstance = ApplicationStore.create({
    headerTools: {},
    thirdPartyServices: {},
    backendEnvironmentId: '',
    countriesRegionsStore: {},
    SchoolStore: {},
    persistentObject: {},
});

declare const window: Window &
    typeof globalThis & {
        ApplicationStore: typeof ApplicationStoreInstance;
    };
if (window && isDev()) {
    window.ApplicationStore = ApplicationStoreInstance;
}

export default ApplicationStoreInstance;
