/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';

import ApiActionFactory from '../../api/api-action-factory';
import StoreFactory from '../factories/store';

import { WWISE_PLUGINS_ENDPOINT } from '../../api/endpoints';

import CarouselItemsModel from './models/carousel-items-model';

import { isDev } from '../../utils/build-utils';

const PluginStore = StoreFactory({
    modelName: 'PluginStore',
    models: [
        ApiActionFactory({
            getPlugins: {
                endpoint: WWISE_PLUGINS_ENDPOINT,
                target: 'plugins',
            },
        }),
    ],
    modelStructure: {
        isLoading: false,
        plugins: types.maybeNull(types.array(CarouselItemsModel)),
    },
}).actions((self) => ({
    retrievePlugins() {
        if (!self.plugins) {
            self.getPlugins();
        }
    },
}));

export type TypeIntegrationStore = Instance<typeof PluginStore>;

const PluginStoreInstance = PluginStore.create();
declare const window: Window &
    typeof globalThis & {
        PluginStore: typeof PluginStoreInstance;
    };

if (window && isDev()) {
    window.PluginStore = PluginStoreInstance;
}

export default PluginStore;
