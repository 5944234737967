import { Instance, types } from 'mobx-state-tree';
import { ModuleId, TargetType } from '../../../modules/utils/module-interfaces';

export type FrozenButton = Record<
    string,
    {
        labelI18n: string;
        labelI18nSupport: string | undefined;
        target: string | TargetType<ModuleId>;
        buttonType: 'beta' | undefined;
    }
>;

const WwiseOverviewHeaderCtasModel = types.model('WwiseOverviewHeaderCtasModel', {
    primary: types.frozen<FrozenButton>({}),
    secondary: types.frozen<FrozenButton>({}),
    tertiary: types.frozen<FrozenButton>({}),
});
export type TypeWwiseOverviewHeaderCtasModel = Instance<typeof WwiseOverviewHeaderCtasModel>;

export default WwiseOverviewHeaderCtasModel;
