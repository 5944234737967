import React from 'react';

import DivButton from '../common/legos/div-button/div-button';

import '../menus/footer/footer.scss';
import '../menus/menu/main-menu/menu.scss';

interface ModuleExpanderProps {
    onClick: () => void;
    isExpanded: boolean;
}
function ModuleExpander(props: ModuleExpanderProps) {
    const { onClick, isExpanded } = props;
    return (
        <DivButton className='module-expander' onClick={onClick}>
            <i className='material-icons-outlined'>
                {isExpanded ? 'arrow_drop_up' : 'arrow_drop_down'}
            </i>
        </DivButton>
    );
}

export default ModuleExpander;
