import React, { ComponentProps } from 'react';

import styles from './styled-content.module.scss';

type Props = {
    contentStyle: 'HEADER' | 'PAGE' | 'PADDED' | 'MOBILE';
} & ComponentProps<'div'>;
export default function StyledContent(props: Props) {
    const { contentStyle, children, className = '' } = props;
    return <div className={`${styles[contentStyle]} ${className}`}>{children}</div>;
}
