const moduleRoot = `/profile`;

export const PATHS = {
    PROFILE: {
        path: `${moduleRoot}`,
    },
    PROFILE_CREATE_THANK_YOU: {
        path: `${moduleRoot}/create-profile-thank-you`,
    },
    PUBLIC_PROFILE: {
        path: `${moduleRoot}/:userId`,
    },
    CONFIRM_NEW_ACCOUNT: {
        path: `/create-account/`,
    },
    PROFILE_NEW_PASSWORD: {
        path: `/password-reset/`,
    },
    PROFILE_NEW_EMAIL: {
        path: `/email-update/`,
    },
    SIGN_IN: {
        path: `/sign-in/`,
    },
    PROFILE_COMPANY: {
        path: `${moduleRoot}/content-provider/`,
    },
    PROFILE_COMPANY_PUBLIC: {
        path: `${moduleRoot}/content-provider/:entityId`,
    },
    PROFILE_SCHOOL: {
        path: `${moduleRoot}/school/`,
    },
    PROFILE_SCHOOL_PUBLIC: {
        path: `${moduleRoot}/school/:entityId`,
    },
};
