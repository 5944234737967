import { useEffect, useRef } from 'react';

export default function useCallOnce(callback: () => any) {
    const firstRenderRef = useRef(true);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            callback();
        }
    }, []);
}
