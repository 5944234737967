import React, { useState } from 'react';

import Cookies from 'js-cookie';
import { includes } from 'lodash';
import { useLocation } from 'react-router-dom';

import { injectStores } from '../../../stores/factories/store-utils';
import { TypeProfileStore } from '../../../stores/profile-store';
import { useJakhubTranslation } from '../../../i18n/jakhub-translation';

import DivButton from '../legos/div-button/div-button';
import MenuMessage from './menu-message';
import EmailConfirmationMessaging from '../../modules/profile/account-confirmation/email-confirmation-messaging';

import COLORS from '../../../styles/legos/colors.module.scss';
import styles from './unverified-user-menu-message.module.scss';

const { akErrorDark } = COLORS;

type Props = {
    ProfileStore: TypeProfileStore;
};
function UnverifiedUserMenuMessageModule(props: Props) {
    const { t, tt } = useJakhubTranslation();
    const {
        ProfileStore: { userResendValidationEmail, userIsLoggedIn, userAccount },
    } = props;
    const { pathname } = useLocation();

    // ToDo HUB-1346 will take care of fixing this. It's not as trivial as it may seem ;-)
    const { account = {}, profile = {} } = { ...userAccount };
    const { emails = { primary: '' } } = { ...account };
    const { primary: email } = emails;
    const { isVerified = false } = { ...profile };

    const messageId = 'UNVERIFIED_USER';
    const [popupMessageIsDismissed, setPopupMessageIsDismissed] = useState(
        Cookies.get(`${messageId}|PopupDismissed`)
    );

    const suppressRedBanner =
        includes(pathname, '/create-account/') ||
        includes(pathname, 'profile/create-profile-thank-you') ||
        includes(pathname, 'learning/create-school-account') ||
        isVerified ||
        !userIsLoggedIn;

    if (suppressRedBanner) return null;

    const dismissThisMessage = () => {
        setPopupMessageIsDismissed('DISMISSED');
        Cookies.set(`${messageId}|PopupDismissed`, 'DISMISSED', { expires: 30 /* In days */ });
        // Not sure if we want to permit dismissal of red menu-message
        // Cookies.set(`${messageId}|Dismissed`, 'DISMISSED', { expires: 1 /* In days */ });
    };

    const i18nMessage = 'general:menu-message.UNVERIFIED_USER';
    const menuMessage_1 = tt(`${i18nMessage}.part_1`);
    const menuMessage_2 = tt(`${i18nMessage}.part_2`);
    const menuMessage_3 = tt(`${i18nMessage}.part_3`);
    const menuMessage_cta = t(`${i18nMessage}.cta`, {
        AK_RESEND_VERIFY_LINK: <span />,
    });

    if (!popupMessageIsDismissed) {
        return (
            <div className={styles.fixedPosition}>
                <EmailConfirmationMessaging onClick={dismissThisMessage} />
            </div>
        );
    }

    const messageConfig = {
        i18nId: messageId,
        message: 'petitie patate',
        publishDateStart: null,
        publishDateEnd: '2099-12-31',
        cookieResetDate: '',
        cookieExpiry: 1,
        barColor: akErrorDark,
    };

    return (
        // Not sure if we want to permit dismissal of red menu-message
        // <MenuMessage messageConfig={messageConfig} isDismissable={!userIsOnAccountPage}>
        <MenuMessage messageConfig={messageConfig} isDismissable={false}>
            <div className={styles.unverifiedBannerMessage}>
                <div>{menuMessage_1}</div>
                <div>{menuMessage_2}</div>
                <div className={styles.flexRow}>
                    <span>{menuMessage_3}</span>
                    <DivButton
                        className={styles.btnWhiteLink}
                        onClick={() => userResendValidationEmail({ email })}
                    >
                        {menuMessage_cta}
                    </DivButton>
                </div>
            </div>
        </MenuMessage>
    );
}

export default injectStores(['ProfileStore'])(UnverifiedUserMenuMessageModule);
