import { lazy } from 'react';
import { typedRoutes } from './utils/module-interfaces';
import { PATHS } from './soft-paths/help';

export const ROUTES = typedRoutes({
    HELP: {
        ...PATHS.HELP,
        id: 'HELP',
        name: 'Help',
        i18nBase: 'help:module.help',
        i18nId: 'help:module.help.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/help/help')),
    },
    SUPPORT: {
        ...PATHS.SUPPORT,
        id: 'SUPPORT',
        name: 'Support',
        i18nBase: 'help:module.support',
        i18nId: 'help:module.support.menu-label',
        component: lazy(() => import('../components/modules/help/support/support')),
    },
    CREATIVE_SERVICES: {
        ...PATHS.CREATIVE_SERVICES,
        id: 'CREATIVE_SERVICES',
        name: 'Creative Services',
        i18nBase: 'help:module.creative-services',
        i18nId: 'help:module.creative-services.menu-label',
    },
    SUBMIT_SUPPORT_TICKET: {
        ...PATHS.SUBMIT_SUPPORT_TICKET,
        id: 'SUBMIT_SUPPORT_TICKET',
        name: 'SubmitSupportTicket',
        i18nBase: 'help:module.submit-support-ticket',
        i18nId: 'help:module.submit-support-ticket.menu-label',
        displayInMenu: false,
    },
    QUESTIONS_ANSWERS: {
        ...PATHS.QUESTIONS_ANSWERS,
        id: 'QUESTIONS_ANSWERS',
        name: 'QuestionsAnswers',
        i18nBase: 'help:module.questions-answers',
        i18nId: 'help:module.questions-answers.menu-label',
        component: lazy(
            () => import('../components/modules/common/question-answers/questions-answers')
        ),
    },
    SUPPORT_CONTACT_US: {
        ...PATHS.SUPPORT_CONTACT_US,
        id: 'SUPPORT_CONTACT_US',
        name: 'SupportContactUs',
        i18nBase: 'help:module.support',
        i18nId: 'help:module.support.menu-label',
        displayInMenu: false,
    },
    SUPPORT_PAY_2_GO: {
        ...PATHS.SUPPORT_PAY_2_GO,
        id: 'SUPPORT_PAY_2_GO',
        name: 'SupportPay2Go',
        i18nBase: 'help:module.support',
        i18nId: 'help:module.support.menu-label',
        displayInMenu: false,
    },
    REQUEST_FEATURE: {
        ...PATHS.REQUEST_FEATURE,
        id: 'REQUEST_FEATURE',
        name: 'RequestFeature',
        i18nBase: 'help:module.request-feature',
        i18nId: 'help:module.request-feature.menu-label',
        component: lazy(() => import('../components/modules/help/request-feature/request-feature')),
    },
    REPORT_BUG: {
        ...PATHS.REPORT_BUG,
        id: 'REPORT_BUG',
        name: 'ReportBug',
        i18nBase: 'help:module.report-bug',
        i18nId: 'help:module.report-bug.menu-label',
        component: lazy(() => import('../components/modules/help/report-bug/report-bug')),
    },
    UNITY_INTEGRATION: {
        ...PATHS.UNITY_INTEGRATION,
        id: 'UNITY_INTEGRATION',
        name: 'Unity Integration',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    UNREAL_INTEGRATION: {
        ...PATHS.UNREAL_INTEGRATION,
        id: 'UNREAL_INTEGRATION',
        name: 'Unreal Integration',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    WAAPI: {
        ...PATHS.WAAPI,
        id: 'WAAPI',
        name: 'WAAPI',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    WWISE_LAUNCHER: {
        ...PATHS.WWISE_LAUNCHER,
        id: 'WWISE_LAUNCHER',
        name: 'WWISE Launcher',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    WWISE_HELP: {
        ...PATHS.WWISE_HELP,
        id: 'WWISE_HELP',
        name: 'WWISE Help',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    WWISE_WELCOME: {
        ...PATHS.WWISE_WELCOME,
        id: 'WWISE_WELCOME',
        name: 'WWISE Welcome',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    WWISE_SDK: {
        ...PATHS.WWISE_SDK,
        id: 'WWISE_SDK',
        name: 'WWISE SDK',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    WWISE_ADVENTURE_GAME: {
        ...PATHS.WWISE_ADVENTURE_GAME,
        id: 'WWISE_ADVENTURE_GAME',
        name: 'WWISE Adventure Game',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    WWISE_AUDIO_LAB: {
        ...PATHS.WWISE_AUDIO_LAB,
        id: 'WWISE_AUDIO_LAB',
        name: 'WWISE Audio Lab',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    WWISE_AUTHORING_API_PACK: {
        ...PATHS.WWISE_AUTHORING_API_PACK,
        id: 'WWISE_AUTHORING_API_PACK',
        name: 'WWISE Authoring API Pack',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    PLUGIN_PACKAGING: {
        ...PATHS.PLUGIN_PACKAGING,
        id: 'PLUGIN_PACKAGING',
        name: 'Plugin packaging',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    GET_IN_TOUCH: {
        ...PATHS.GET_IN_TOUCH,
        id: 'GET_IN_TOUCH',
        name: 'GetInTouch',
        i18nBase: 'general:module.get-in-touch',
        i18nId: 'general:module.get-in-touch.menu-label',
        displayInMenu: false,
    },
    STRATA_DOCUMENTATION: {
        ...PATHS.STRATA_DOCUMENTATION,
        id: 'STRATA_DOCUMENTATION',
        name: 'Strata Documentation',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    STRATA_COMMUNITY_QNA: {
        ...PATHS.STRATA_COMMUNITY_QNA,
        id: 'STRATA_COMMUNITY_QNA',
        name: 'Strata Community Q&A',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
});

export const DEFAULT_ROUTE = ROUTES.HELP;
export default ROUTES;
