import { Instance, types } from 'mobx-state-tree';

export const LibraryCollectionModel = types.snapshotProcessor(
    types.model('LibraryCollectionModel', {
        tag: types.string,
        title: types.string,
        description: types.string,
        logo: types.string,
        authors: types.array(types.string),
        collectionLink: '',
        metadata: types.maybeNull(types.string),
    }),
    {
        preProcessor(sn: any) {
            return {
                ...sn,
                collectionLink: `/strata/sound-effect-collections/collection/${sn.tag}`,
            };
        },
    }
);

export type TypeLibraryCollectionModel = Instance<typeof LibraryCollectionModel>;

export default LibraryCollectionModel;
