/* eslint-disable-next-line no-shadow */
export enum Resolution {
    XSMALL = 'XSMALL',
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
    XLARGE = 'XLARGE',
    XXLARGE = 'XXLARGE',
}

export const BUTTON_VARIANTS = ['contained', 'outlined', 'text'] as const;
export type Variant = typeof BUTTON_VARIANTS[number];

export enum LibraryPurchaseType {
    FREE = 'free',
    PAID = 'paid',
}
