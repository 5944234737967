import React from 'react';

import { useJakhubTranslation } from '../../../i18n/jakhub-translation';

import styles from './password-strength.module.scss';

import COLORS from '../../../styles/legos/colors.module.scss';
const { akRed, akOrange, akLime, akGreen, akAlertDark } = COLORS;

const strengthArray = [
    [10, 14, 18, 24, 99],
    [7, 10, 12, 15, 99],
    [6, 9, 11, 14, 99],
    [5, 7, 9, 11, 99],
];

const strengths = ['veryWeak', 'weak', 'medium', 'strong', 'veryStrong'];
const strengthColors = [akRed, akOrange, akAlertDark, akLime, akGreen];

type Props = {
    password?: string;
};
export default function PasswordStrength(props: Props) {
    const { t } = useJakhubTranslation();
    const { password = '' } = props;
    const dictionaryBase = 'common:labels.passwordStrength';
    const length = password.length;
    const number = (password.match(/[0-9]/g) || []).length;
    const lowercase = (password.match(/[a-z]/g) || []).length;
    const uppercase = (password.match(/[A-Z]/g) || []).length;
    const symbol = (password.match(/[^0-9a-zA-Z]/g) || []).length;

    const typeCount =
        Number(!!number) + Number(!!lowercase) + Number(!!uppercase) + Number(!!symbol);
    const strengthPerLength = strengthArray[typeCount - 1];

    const strength = strengthPerLength?.findIndex((entry: number) => entry >= length) || 0;
    const strengthName = strengths[strength];

    if (!length) return null;

    document.documentElement.style.setProperty('--passwordStrength', strengthColors[strength]);

    return (
        <div className={styles.strength}>
            <div className={styles.bars}>
                {[...Array(5)].map((v, i) => (
                    <div
                        key={`bar${v}|${i}`}
                        className={`${styles.bar} ${strength >= i ? styles.verdictBar : ''}`}
                    />
                ))}
            </div>
            <div className={styles.verdict}>{t(`${dictionaryBase}.${strengthName}`)}</div>
        </div>
    );
}
