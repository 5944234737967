import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import './circular-loader.scss';

function CircularLoader() {
    return (
        <div className='jakhub-loader-veil'>
            <div className='jakhub-circular-loader'>
                <CircularProgress />
            </div>
        </div>
    );
}

export default CircularLoader;
