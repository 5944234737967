import { Instance, types } from 'mobx-state-tree';

import { CollectionDynamicDetailsModel } from './collection-details-model';
import { LibraryModel } from './library-model';
import { MediaModel } from './media-model';

export const CollectionModel = types.model('CollectionModel', {
    tag: types.optional(types.string, '', [undefined, null]),
    title: types.optional(types.string, '', [undefined, null]),
    description: types.optional(types.string, '', [undefined, null]),
    authors: types.array(types.string),
    samples: types.array(types.string),
    technicalDetails: types.array(CollectionDynamicDetailsModel),
    collectionDetails: types.array(CollectionDynamicDetailsModel),
    logo: types.optional(types.string, '', [undefined, null]),
    media: types.array(MediaModel),
    libraries: types.array(LibraryModel),
});

export type TypeCollectionModel = Instance<typeof CollectionModel>;
export default CollectionModel;
