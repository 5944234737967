/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';

import { createApiAction } from '../api/api-action-factory';
import { isDev } from '../utils/build-utils';

import { TypedStoreConfig } from './factories/store';
import {
    ProfileContentProviderDetailsStore,
    ProfileSchoolDetailsStore,
} from './profile-content-provider-stores/profile-entity-details-store';
import CourseAccessRequestModel from './profile-content-provider-stores/course-access-request-model';

import {
    CREATORS_DIRECTORY_REQUEST_COURSE_ACCESS_ENDPOINT,
    PROFILE_CONTENT_PROVIDER_DETAILS_ENDPOINT,
    PROFILE_SCHOOL_DETAILS_ENDPOINT,
} from '../api/endpoints';

const ProfileEntityStore = TypedStoreConfig({
    modelName: 'ProfileEntityStore',
    properties: {
        profileCompanyDetails: types.maybe(ProfileContentProviderDetailsStore),
        profileSchoolDetails: types.maybe(ProfileSchoolDetailsStore),
        accessRequestResponse: types.maybe(CourseAccessRequestModel),
        courseAccessRequestResponse: types.map(CourseAccessRequestModel),
    },
})
    .actions((self) => ({
        getCompanyProfileDetails: createApiAction(self, {
            endpoint: PROFILE_CONTENT_PROVIDER_DETAILS_ENDPOINT,
            target: 'profileCompanyDetails',
        }),
        getSchoolProfileDetails: createApiAction(self, {
            endpoint: PROFILE_SCHOOL_DETAILS_ENDPOINT,
            target: 'profileSchoolDetails',
        }),
        requestCourseAccess: createApiAction(self, {
            endpoint: CREATORS_DIRECTORY_REQUEST_COURSE_ACCESS_ENDPOINT,
            target: 'accessRequestResponse',
        }),
        courseAccessRequestStatus: createApiAction(self, {
            endpoint: CREATORS_DIRECTORY_REQUEST_COURSE_ACCESS_ENDPOINT,
            target: 'courseAccessRequestResponse',
            targetMapId: 'projectMask',
        }),
    }))
    .actions((self) => ({
        checkAccessRequestStatus(form: any) {
            const { projectMask } = form;
            const courseAccessRequestResponse = self.courseAccessRequestResponse.get(projectMask);

            if (!courseAccessRequestResponse) {
                self.courseAccessRequestStatus(form);
            } else {
                self.courseAccessRequestResponse.set(projectMask, {
                    ...courseAccessRequestResponse,
                    checkCount: courseAccessRequestResponse.checkCount + 1,
                });
            }
        },
    }))
    .views((self) => ({
        retrieveAccessRequestStatus(projectMask: string) {
            return self.courseAccessRequestResponse.get(projectMask);
        },
    }));
export type TypeProfileEntityStore = Instance<typeof ProfileEntityStore>;

const ProfileEntityStoreInstance = ProfileEntityStore.create();

declare const window: Window &
    typeof globalThis & {
        ProfileEntityStore: typeof ProfileEntityStoreInstance;
    };

if (window && isDev()) {
    window.ProfileEntityStore = ProfileEntityStoreInstance;
}

export default ProfileEntityStoreInstance;
