import { Instance, types } from 'mobx-state-tree';

const ExtraDownloads = types.model('ExtraDownloads', {
    downloadName: types.maybeNull(types.string),
    downloadUrl: types.maybeNull(types.string),
});

const WwiseCertificationModel = types.model('WwiseCertificationModel', {
    certUrl: types.maybeNull(types.string),
    certifiedUserLogoUrl: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    logoUrl: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    dateCertified: types.maybeNull(types.string),
    certPDFUrl: types.maybeNull(types.string),
    logoKitUrl: types.maybeNull(types.string),
    extraDownloads: types.array(ExtraDownloads),
});

export type TypeWwiseCertificationModel = Instance<typeof WwiseCertificationModel>;
export default WwiseCertificationModel;
