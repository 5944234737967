import { Instance, types } from 'mobx-state-tree';

import { TypedStoreConfig } from '../factories/store';
import { createApiAction } from '../../api/api-action-factory';
import { COUNTRIES_ENDPOINT, REGION_PER_COUNTRY_ID_ENDPOINT } from '../../api/endpoints';
import { map } from 'lodash';

const CountryModel = types.model('CountryModel', {
    countryCode: '',
    countryName: '',
});

const RegionModel = types.model('RegionModel', {
    regionCode: '',
    regionName: '',
});

const CountriesRegionsStore = TypedStoreConfig({
    modelName: 'CountriesRegionsStore',
    properties: {
        rawCountries: types.array(CountryModel),
        selectedCountryCode: types.optional(types.string, ''),
        rawRegionsPerCountry: types.map(types.array(RegionModel)),
    },
})
    .actions((self) => ({
        getCountries: createApiAction(self, {
            endpoint: COUNTRIES_ENDPOINT,
            target: 'rawCountries',
        }),
        getRegionsForCountry: createApiAction(self, {
            endpoint: REGION_PER_COUNTRY_ID_ENDPOINT,
            target: 'rawRegionsPerCountry',
            targetMapId: 'countryCode',
        }),
    }))
    .actions((self) => ({
        afterCreate() {
            self.getCountries();
        },
        retrieveRegions(countryCode: string) {
            const regions = self.rawRegionsPerCountry.get(countryCode);
            if (!regions) {
                self.getRegionsForCountry({ countryCode });
            }
        },
    }))
    .actions((self) => ({
        setStoreCountryCode(countryCode: string) {
            if (countryCode !== undefined) {
                self.selectedCountryCode = countryCode;

                if (!self.rawRegionsPerCountry.get(self.selectedCountryCode)) {
                    self.retrieveRegions(countryCode);
                }
            }
        },
    }))
    .views((self) => ({
        countries() {
            return map(self.rawCountries, (value) => ({
                key: value.countryCode,
                value: value.countryName,
            }));
        },
        regions() {
            return map(self.rawRegionsPerCountry.get(self.selectedCountryCode), (value) => ({
                key: value.regionCode,
                value: value.regionName,
            }));
        },
    }));

export type TypeCountriesRegionsStore = Instance<typeof CountriesRegionsStore>;

export default CountriesRegionsStore;
