import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = `/wwise`;

export const PATHS = {
    WWISE: {
        path: `${moduleRoot}`,
    },
    OVERVIEW: {
        path: `${moduleRoot}/overview`,
    },
    WWISE_SPATIAL_AUDIO: {
        path: `${moduleRoot}/wwise-spatial-audio/`,
    },
    PLUGIN_DETAILS: {
        path: `${moduleRoot}/plugins/:pluginId`,
    },
    PLUGINS: {
        path: `${moduleRoot}/plugins`,
    },
    SUPPORTED_PLATFORMS: {
        path: `${moduleRoot}/supported-platforms`,
    },
    INTEGRATIONS: {
        path: `${moduleRoot}/integrations`,
    },
    POWERED_BY_WWISE: {
        path: `${moduleRoot}/powered-by-wwise`,
    },
    DOCUMENTATION: {
        path: `${moduleRoot}/documentation`,
        hardPath: HARD_PATHS.HELP.WWISE_WELCOME,
    },
    PRICING: {
        path: `${moduleRoot}/pricing`,
        hardPath: HARD_PATHS.WWISE.PRICING,
    },
    LICENSING: {
        path: `${moduleRoot}/licensing/faq/trialLicensing`,
    },
    REGISTER_PROJECT: {
        path: `${moduleRoot}/projectRegistration`,
        hardPath: HARD_PATHS.GENERAL.FCRM_REGISTERED_PROJECTS,
    },
};
