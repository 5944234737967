/* eslint-disable no-param-reassign */
import { Instance } from 'mobx-state-tree';

import { isDev } from '../../utils/build-utils';

import StoreFactory from '../factories/store';

const DocumentationStore = StoreFactory({
    modelName: 'DocumentationStore',
    models: [],
    modelStructure: {
        isLoading: false,
        title: 'DocumentationStore',
    },
});

export type TypeDocumentationStore = Instance<typeof DocumentationStore>;

const DocumentationStoreInstance = DocumentationStore.create();
declare const window: Window &
    typeof globalThis & {
        DocumentationStore: typeof DocumentationStoreInstance;
    };

if (window && isDev()) {
    window.DocumentationStore = DocumentationStoreInstance;
}

export default DocumentationStore;
