import { mapKeys, mapValues } from 'lodash';
import { isDev } from '../../../../utils/utils';

export function defaultValidation(
    dictContext: any,
    validationId: string,
    validationKeyList: string[] = ['required']
) {
    return mapValues(
        mapKeys(validationKeyList, (key) => key.split('-')[0]),
        (v: string) => {
            const validationKey = v.split('-');
            const value = parseInt(validationKey[1], 10) || true;
            const message = dictContext?.[validationId]?.[validationKey[0]];
            if (!message && isDev()) {
                console.error(`
-- :: [INCOMPLETE CONFIG] :: --
It appears like you want to show a validation message in a form.
The message for the "${validationKey[0]}" constraint
of field ${validationId} is not specified in the dictionary stack.`);
            }
            return { value, message };
        }
    );
}
