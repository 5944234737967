import { Instance, types } from 'mobx-state-tree';
import { LocalizedLabelMap } from './localizes-label-map';

const SubscriptionModel = types.model('SubscriptionModel', {
    checkboxLabel: LocalizedLabelMap,
    description: LocalizedLabelMap,
    displayOnCreateAccount: false,
    ordinal: 0,
});
export type TypeSubscriptionModel = Instance<typeof SubscriptionModel>;

const EmailSubscriptionModel = types.model('EmailSubscriptionModel', {
    Blog: SubscriptionModel,
    Education: SubscriptionModel,
    Releases: SubscriptionModel,
    Strata: SubscriptionModel,
    Wwise: SubscriptionModel,
});
export type TypeEmailSubscriptionModel = Instance<typeof EmailSubscriptionModel>;

const SystemNotificationsModel = types.model('SystemNotificationsModel', {
    System: SubscriptionModel,
});
export type TypeSystemNotificationsModel = Instance<typeof SystemNotificationsModel>;

export const SubscriptionInfoModel = types.model('SubscriptionInfoModel', {
    emailSubscriptions: EmailSubscriptionModel,
    systemNotifications: SystemNotificationsModel,
});
export type TypeSubscriptionInfoModel = Instance<typeof SubscriptionInfoModel>;

export default SubscriptionInfoModel;
