import { Instance, types } from 'mobx-state-tree';

const PlatformModel = types.model('PlatformModel', {
    platform: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
});
export type TypePlatformModel = Instance<typeof PlatformModel>;

const LinksModel = types.model('LinksModel', {
    name: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    version: types.maybeNull(types.string),
    platforms: types.array(PlatformModel),
});
export type TypeLinksModel = Instance<typeof LinksModel>;

const DownloadsModel = types.model('DownloadsModel', {
    title: types.maybeNull(types.string),
    links: types.array(LinksModel),
});
export type TypeDownloadsModel = Instance<typeof DownloadsModel>;

const LicenseAgreementModel = types.model('LicenseAgreementModel', {
    document_id: types.number,
    filename: types.maybeNull(types.string),
    label: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    signed: types.boolean,
});
export type TypeLicenseAgreementModel = Instance<typeof LicenseAgreementModel>;

const PrerequisitesModel = types.model('DownloadsModel', {
    title: types.maybeNull(types.string),
    links: types.array(LicenseAgreementModel),
});
export type TypePrerequisitesModel = Instance<typeof PrerequisitesModel>;

const LicencesModel = types.model('LicencesModel', {
    description: types.maybeNull(types.string),
    licenseKey: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    expiration: types.frozen({}),
    relatesTo: types.model('relatesToModel', {
        id: types.maybeNull(types.number),
        productName: types.maybeNull(types.string),
    }),
});
export type TypeLicencesModel = Instance<typeof LicencesModel>;

const InstallmentsModel = types.model('InstallmentsModel', {
    amount: types.maybeNull(types.string),
    end_date: types.maybeNull(types.string),
    payment_date: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    payment_url: types.maybeNull(types.string),
});
export type TypeInstallmentsModel = Instance<typeof InstallmentsModel>;

const PurchasesModel = types.model('PurchasesModel', {
    id: types.maybeNull(types.union(types.string, types.number)),
    productName: types.maybeNull(types.string),
    expirationDate: types.maybeNull(types.string),
    owner: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    installments: types.maybeNull(types.array(InstallmentsModel)),
});
export type TypePurchasesModel = Instance<typeof PurchasesModel>;

const PreProcessedPurchasesModel = types.snapshotProcessor(PurchasesModel, {
    preProcessor(sn: TypePurchasesModel) {
        return {
            ...sn,
            expirationDate: sn.expirationDate?.split(' ')[0],
        };
    },
});
export type TypePreProcessedPurchasesModel = Instance<typeof PreProcessedPurchasesModel>;

const SubscriptionsModel = types.model('SubscriptionsModel', {
    licenseAgreements: types.maybeNull(PrerequisitesModel),
    downloads: types.array(DownloadsModel),
    licenses: types.array(LicencesModel),
    purchases: types.array(PreProcessedPurchasesModel),
});

export type TypeSubscriptionsModel = Instance<typeof SubscriptionsModel>;
export default SubscriptionsModel;
