import { Instance, types } from 'mobx-state-tree';

import ApiActionFactory from '../../api/api-action-factory';
import StoreFactory from '../factories/store';

import { NEWS_ENDPOINT } from '../../api/endpoints';

import { NewsModel } from './models/news-model';

import { isDev } from '../../utils/build-utils';

const NewsStore = StoreFactory({
    modelName: 'NewsStore',
    models: [
        ApiActionFactory({
            fetchNews: {
                endpoint: NEWS_ENDPOINT,
                target: 'news',
            },
        }),
    ],
    modelStructure: {
        isLoading: false,
        news: types.maybeNull(types.array(NewsModel)),
    },
}).actions((self) => ({
    retrieveNews() {
        if (!self.news) {
            self.fetchNews();
        }
    },
}));

export type TypeNewsStore = Instance<typeof NewsStore>;

const NewsStoreInstance = NewsStore.create();
declare const window: Window &
    typeof globalThis & {
        NewsStore: typeof NewsStoreInstance;
    };
if (window && isDev()) {
    window.NewsStore = NewsStoreInstance;
}

export default NewsStore;
