/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';

import { createApiAction } from '../../api/api-action-factory';
import { TypedStoreConfig } from '../factories/store';

import CreatorsDirectoryGroupCountModel from './models/creators-directory-group-count-model';
import CreatorsDirectoryInfoModel, {
    TypeCreatorsDirectoryInfoProperties,
    TypeFieldsModel,
    TypeServicesProperties,
} from './models/creators-directory-info-model';
import CreatorsDirectoryModel, {
    TypeCreatorsDirectoryProperties,
    TypeValidSearchHashes,
} from './models/creators-directory-model';

import { isDev } from '../../utils/build-utils';

import {
    CREATORS_DIRECTORY_INFO_ENDPOINT,
    CREATORS_DIRECTORY_SEARCH_ENDPOINT,
    CREATORS_DIRECTORY_STATS_ENDPOINT,
} from '../../api/endpoints';
import ApplicationStoreInstance from '../application-store';
import { snakeCase } from '../../utils/utils';

interface SearchResults {
    list: Array<any>;
    count: number;
}

type SearchForm = {
    context: TypeValidSearchHashes;
    pagination: any;
    filter: any;
};

const CreatorsDirectoryStore = TypedStoreConfig({
    modelName: 'CreatorsDirectoryStore',
    properties: {
        isLoading: false,
        infoIsLoading: false,
        creatorsStats: types.maybeNull(CreatorsDirectoryGroupCountModel),
        creatorsInfo: types.maybeNull(CreatorsDirectoryInfoModel),
        creatorsDirectory: types.maybeNull(CreatorsDirectoryModel),
    },
})
    .actions((self) => ({
        getGroupCounts: createApiAction(self, {
            endpoint: CREATORS_DIRECTORY_STATS_ENDPOINT,
            target: 'creatorsStats',
        }),
        getCreatorsInfo: createApiAction(self, {
            endpoint: CREATORS_DIRECTORY_INFO_ENDPOINT,
            target: 'creatorsInfo',
            onTrigger(self: any) {
                self.infoIsLoading = true;
            },
            onLoad(self: any) {
                self.infoIsLoading = false;
            },
            onError(self: any) {
                self.infoIsLoading = false;
            },
        }),
        creatorsDirectorySearch: createApiAction(self, {
            endpoint: CREATORS_DIRECTORY_SEARCH_ENDPOINT,
            target: 'creatorsDirectory',
        }),
    }))
    .actions((self) => ({
        creatorsDirectoryHashSearch(hashObject: { searchHash: string; searchForm: SearchForm }) {
            const { searchHash, searchForm } = hashObject;
            const { context } = searchForm;

            if (self.creatorsDirectory?.searchHash?.[context] !== searchHash) {
                self.creatorsDirectorySearch(searchForm);
            }

            if (self.creatorsDirectory?.searchHash?.[context]) {
                // @ts-ignore
                self.creatorsDirectory.searchHash[context] = {
                    ...self.creatorsDirectory.searchHash,
                    [context]: searchHash,
                };
            }
        },
    }))
    .actions((self) => ({
        retrieveGroupCounts() {
            if (!self.creatorsStats) {
                self.getGroupCounts();
            }
        },
    }))
    .views((self) => ({
        getSearchResults(
            creatorsDirectoryContext: keyof TypeCreatorsDirectoryProperties
        ): SearchResults {
            if (self.creatorsDirectory?.[creatorsDirectoryContext]) {
                return {
                    //need to come back to fix this not sure what's in each array
                    list: self.creatorsDirectory[creatorsDirectoryContext] as Array<any>,
                    count: self.creatorsDirectory[
                        `${creatorsDirectoryContext}Count` as keyof TypeCreatorsDirectoryProperties
                    ] as number,
                };
            }
            return { list: [], count: 0 };
        },
        getSortingFields(
            creatorsInfoContext: keyof TypeCreatorsDirectoryInfoProperties
        ): TypeFieldsModel[] {
            if (self.creatorsInfo?.[creatorsInfoContext]) {
                return self.creatorsInfo[creatorsInfoContext]?.fields as TypeFieldsModel[];
            }
            return [{}] as TypeFieldsModel[];
        },
        getServices() {
            const userLocale = snakeCase(
                ApplicationStoreInstance.userLocale
            ) as keyof TypeServicesProperties;

            const localizedServices: Record<string, string> = {};

            const servicesKeys = Object.keys(self.creatorsInfo?.companies?.services || {});

            if (servicesKeys.length === 0 && !self.infoIsLoading) {
                self.getCreatorsInfo();
            }

            self.creatorsInfo?.companies?.services.forEach((locale, service) => {
                localizedServices[service] = locale[userLocale];
            });

            return localizedServices;
        },
    }));

export type TypeCreatorsDirectoryStore = Instance<typeof CreatorsDirectoryStore>;

const CreatorsDirectoryStoreInstance = CreatorsDirectoryStore.create();

declare const window: Window &
    typeof globalThis & {
        CreatorsDirectoryStore: typeof CreatorsDirectoryStoreInstance;
    };

if (window && isDev()) {
    window.CreatorsDirectoryStore = CreatorsDirectoryStoreInstance;
}

export default CreatorsDirectoryStore;
