import ApplicationStoreInstance from '../../stores/application-store';
import { SupportedLocale } from '../../i18n/supported-locales';

import { MODULES } from '../modules';

import { find, includes, omitBy, pickBy } from 'lodash';

import { Module, ModuleId, ModuleRoute, RouteId, TargetType } from './module-interfaces';

type ModuleDictionary = {
    [Id in ModuleId]?: Module<Id>;
};

type RouteDictionary = {
    [Id in RouteId]?: Module<Id>;
};

export const getPredicatedModules = (
    modules: Partial<Record<ModuleId, ModuleRoute>> | Partial<Record<RouteId, Module>>,
    predicate: (module: ModuleRoute) => boolean
): ModuleDictionary | RouteDictionary => pickBy(modules, (module) => predicate(module));

export const getSelectedModules = (
    modules: ModuleDictionary | RouteDictionary,
    selectedModules?: ModuleId[]
): ModuleDictionary => {
    return selectedModules
        ? (omitBy(modules, (link: Module) => !includes(selectedModules, link.id)) as any)
        : modules;
};

export const isValidModule = (
    target: TargetType<ModuleId>
): { moduleIsValid: boolean; subModuleIsValid: boolean } => {
    const { module, subModule } = target;

    const foundModule = find(MODULES, (MOD, MOD_ID) => (MOD_ID as string) === module);
    let foundSubModule = true;

    if (!!foundModule && !!subModule) {
        foundSubModule = !!find(
            foundModule.routes,
            (SUBMOD, SUBMOD_ID) => (SUBMOD_ID as string) === subModule
        );
    }

    return { moduleIsValid: !!foundModule, subModuleIsValid: foundSubModule };
};

export const pathSelector = (module: ModuleRoute): string => {
    const { userLocale } = ApplicationStoreInstance;

    const { path, hardPath: moduleHardPath } = module;

    const hardPath =
        typeof moduleHardPath === 'string'
            ? moduleHardPath
            : moduleHardPath?.[userLocale as SupportedLocale];

    // Use hardPath before path
    return hardPath || path;
};
