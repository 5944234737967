import { Instance, types } from 'mobx-state-tree';

import { TypedStoreConfig } from '../factories/store';
import { createApiAction } from '../../api/api-action-factory';
import { SCHOOL_TYPE_INFO_ENDPOINT } from '../../api/endpoints';
import SchoolTypesModel, { TypeSchoolTypeProperties } from './models/SchoolTypesModel';
import ApplicationStoreInstance from '../application-store';
import { snakeCase } from '../../utils/utils';
import { map } from 'lodash';

const SchoolStore = TypedStoreConfig({
    modelName: 'SchoolStore',
    properties: {
        schoolsInfo: types.maybeNull(SchoolTypesModel),
    },
})
    .actions((self) => ({
        getSchoolsInfo: createApiAction(self, {
            endpoint: SCHOOL_TYPE_INFO_ENDPOINT,
            target: 'schoolsInfo',
        }),
    }))
    .actions((self) => ({
        afterCreate() {
            self.getSchoolsInfo();
        },
    }))
    .views((self) => ({
        getSchoolTypes() {
            const userLocale = snakeCase(
                ApplicationStoreInstance.userLocale
            ) as keyof TypeSchoolTypeProperties;

            const localizedSchoolsTypes: Record<string, string> = {};

            self.schoolsInfo?.types.forEach((locale, schoolType) => {
                localizedSchoolsTypes[schoolType] = locale[userLocale];
            });
            return map(localizedSchoolsTypes, (value, key) => ({
                key,
                value,
            }));
        },
    }));

export type TypeSchoolsInfoStore = Instance<typeof SchoolStore>;

export default SchoolStore;
