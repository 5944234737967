import React, { forwardRef, useRef } from 'react';
import { DateTime } from 'luxon';

import ReactDatePicker, { registerLocale } from 'react-datepicker';

import { useJakhubTranslation } from '../../../i18n/jakhub-translation';
import { injectStores } from '../../../stores/factories/store-utils';
import { TypeApplicationStore } from '../../../stores/application-store';

import { enUS, frCA, ko, ja, zhCN } from 'date-fns/esm/locale';

import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.scss';

registerLocale('frCA', frCA);
registerLocale('ko', ko);
registerLocale('ja', ja);
registerLocale('zhCN', zhCN);

function toLuxonDate(dateString = '1970-01-01'): DateTime {
    const formattedDateString = `${dateString}T00:00:00`;
    return DateTime.fromJSDate(new Date(formattedDateString)).toUTC();
}

const CustomInput = forwardRef((props: any) => {
    const { T } = useJakhubTranslation();
    const { value, placeholder, onClick, componentLabel } = props;

    return (
        <div className='custom-input'>
            <span className='custom-input-label'>{`${componentLabel} : `}</span>
            <div onClick={onClick} className='custom-input-button'>
                {value ? T(`<AK_BOLD>${value}</AK_BOLD>`) : placeholder}
            </div>
        </div>
    );
});
CustomInput.displayName = 'CustomInput';

interface Props {
    ApplicationStore: TypeApplicationStore;
    label: string;
    placeHolder?: string;
    initDate?: string;
    minDate?: string;
    onChange: any;
    format?: string;
    clearable?: boolean;
}

function DatePicker(props: Props) {
    const {
        ApplicationStore: { userLocale },
        label,
        placeHolder,
        initDate,
        onChange,
        format = 'MMM yyyy',
        clearable = false,
        minDate,
    } = props;
    const inputRef = useRef(null);

    // :-/ All locales we support are NOT fully supported
    //     in the sense that jaJP and koKR don't exist
    const localMap: Record<string, Locale> = {
        'en-US': enUS,
        'fr-CA': frCA,
        'ja-JP': ja,
        'ko-KR': ko,
        'zh-CN': zhCN,
    };

    const luxonMinDate = minDate ? toLuxonDate(minDate).toJSDate() : null;
    const luxonDate = initDate ? toLuxonDate(initDate.toString()).toJSDate() : null;

    const handleDateChange = (date: any) => {
        onChange(date === null ? null : DateTime.fromJSDate(new Date(date)).toUTC());
    };

    return (
        <ReactDatePicker
            popperClassName='custom-popper-class'
            locale={localMap[userLocale]}
            selected={luxonDate}
            minDate={luxonMinDate}
            dateFormat={format}
            onChange={handleDateChange}
            isClearable={clearable}
            placeholderText={placeHolder || label}
            showMonthYearPicker
            customInput={<CustomInput inputRef={inputRef} componentLabel={label} />}
        />
    );
}

export default injectStores(['ApplicationStore'])(DatePicker);
