import React, { useEffect, useState } from 'react';
import { forEach, includes, map, reduce } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { Markup } from 'interweave';

import { defaultValidation } from '../../modules/profile/validation-utils/validation-utils';
import { useJakhubTranslation } from '../../../i18n/jakhub-translation';
import { injectStores } from '../../../stores/factories/store-utils';
import { extractUiData } from '../editable-user-data/editable-user-data-utils';

import { EditableFieldGenerator, EditableFieldProps } from '../editable-user-data/field-factory';

import Button from '../../controls/button/button';
import { ContentRendererProps } from '../editable-user-data/editable-user-data';
import { TypeApplicationStore } from '../../../stores/application-store';
import { TypeProfileStore } from '../../../stores/profile-store';

import { getValueWithPath, setValueWithPath } from '../../../utils/utils';

import FormStructures, { FormSubmitDataStructure } from './contact-form-structure';

import { ContactForms } from '../../../constants';

import DivButton from '../legos/div-button/div-button';
import SignInSignUp from '../../authentication/sign-in-up/sign-in-up';
import SwitchLink from '../../menus/menu/switch-link/switch-link';

import styles from './contact-form.module.scss';

const contextSubject: Record<string, string> = {
    contactAmbassadorForm: 'ambassador',
    contactEducationForm: 'education',
    contactGeneralForm: 'generalInquiry',
    contactLicensingForm: 'licensing',
    contactProjectForm: 'sales',
    contactSalesForm: 'sales',
    contactSalesLicensingForm: 'licensing',
    submitSupportTicket: 'generalInquiry',
};

const passwordProtected = (context: string) => ['contactProjectForm'].includes(context);

type Props = ContentRendererProps & {
    ApplicationStore: TypeApplicationStore;
    ProfileStore: TypeProfileStore;
    formId: ContactForms;
};
function ContactForm(props: Props) {
    const { t, tt } = useJakhubTranslation();
    const {
        ApplicationStore: {
            headerTools: { openedHeaderToolId },
            showToolInHeader,
            sendContactForm,
        },
        ProfileStore: { userIsLoggedIn, userAccount, errors, getUserProjects, userProjects },
    } = props;

    const formIsProtected = passwordProtected(openedHeaderToolId);

    const namespace = 'general';
    const moduleName = 'contact-form';
    const dictionaryBase = `${namespace}:${moduleName}`;

    const inquirySubjectsFromDict = tt('general:inquiry') as Record<string, string>;
    const inquirySubjects = map(inquirySubjectsFromDict, (s, sId) => ({ key: sId, value: s }));
    const inquiryProjects = map(userProjects, (p) => ({
        key: `${p.id}`,
        value: p.projectName,
    }));

    const {
        unknownUserInfo,
        loggedInUserInfo,
        projectInfo,
        inquiryInfo,
        termsAndConditionsInfo,
        unknownUserInfoInitData,
        loggedInUserInfoInitData,
        projectInfoInitData,
        inquiryInfoInitData,
        termsAndConditionsData,
    } = FormStructures;

    const newUserData = [...unknownUserInfo, ...loggedInUserInfo, ...projectInfo, ...inquiryInfo];

    const contactFormData = {
        userAccount,
        ...unknownUserInfoInitData,
        ...loggedInUserInfoInitData,
        ...projectInfoInitData,
        ...inquiryInfoInitData,
        ...termsAndConditionsData,
    };

    const description = tt(`${dictionaryBase}.form-context.${openedHeaderToolId}`) as Record<
        string,
        string
    >;

    const defaultValueById = (id: string) => {
        if (id === 'inquiry_description') return description;
        if (id === 'inquiry_subject') return contextSubject[openedHeaderToolId];
        return null;
    };
    const sectionData = reduce(
        newUserData,
        (cumul: any, data: any) => {
            const { id, data_path } = data;

            return !contactFormData
                ? cumul
                : {
                      ...cumul,
                      [id]: defaultValueById(id) || getValueWithPath(contactFormData, data_path),
                  };
        },
        {}
    );

    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [editingData, setEditingData] = useState(sectionData);

    const formValidator = useForm({
        mode: submitAttempted ? 'onChange' : 'onSubmit',
        criteriaMode: 'all',
    });

    const validationMessages = tt(`general:form-validation`);

    // Fields need to be augmented with dynamic data not readily available in contact-form-structure.ts
    const augmentFields = (structureFields: FormSubmitDataStructure[], extras?: any) =>
        structureFields
            .filter((f) => !f.hidden)
            .map((f) => {
                const extrasById = extras && extras?.[f.id] ? extras?.[f.id] : {};

                const augmented = {
                    id: f.id,
                    fieldType: f.fieldType,
                    validation: f.validationId
                        ? defaultValidation(validationMessages, f.validationId)
                        : null,
                    label: f?.labelId
                        ? t(f.labelId, { AK_VAR_INTERPOLATION: sectionData[f.id] })
                        : null,
                    cossin: extras && extras?.[f.id],
                };

                return { ...augmented, ...extrasById };
            }) as EditableFieldProps[];

    // Form augmented structure for new users
    const fieldsForUnknownUser = augmentFields(unknownUserInfo);

    // Form augmented structure for logged-in users
    const fieldsForLoggedInUser = augmentFields(loggedInUserInfo);
    if (inquiryProjects.length > 0 && openedHeaderToolId === 'contactProjectForm') {
        const augmentedProjectInfo = augmentFields(projectInfo, {
            selected_project: { optionList: inquiryProjects },
        });
        fieldsForLoggedInUser.splice(
            Infinity,
            0,
            {
                id: 'splitter_1',
                fieldType: 'SimpleSpacer',
            },
            ...augmentedProjectInfo
        );
    }

    // Form augmented structure for inquiry
    const fieldsForInquiry = augmentFields(inquiryInfo, {
        inquiry_subject: {
            optionList: inquirySubjects,
        },
        inquiry_subject_other: {
            partnerField: 'inquiry_subject',
            conditionalVisibility: (partnerValue: string) => {
                return partnerValue !== 'other';
            },
        },
    });

    const generatorFields = [
        ...(userIsLoggedIn ? fieldsForLoggedInUser : fieldsForUnknownUser),
        {
            id: 'splitter_2',
            fieldType: 'SimpleSpacer',
        },
        ...fieldsForInquiry,
    ] as EditableFieldProps[];

    if (!userIsLoggedIn) {
        const augmentedTermsConditions = augmentFields(termsAndConditionsInfo, {
            accept_terms_and_conditions: {
                label: t('general:contact-form.formLabels.accept_terms_and_conditions.label', {
                    AK_TERMS_LINK: (
                        <SwitchLink
                            akTestId='SignUp|LinkTo|LEGAL|'
                            to={{ module: 'LEGAL', subModule: 'TERMS_OF_USE' }}
                        />
                    ),
                    AK_PRIVACY_POLICY_LINK: (
                        <SwitchLink
                            akTestId='SignUp|LinkTo|LEGAL|PRIVACY_POLICY'
                            to={{ module: 'LEGAL', subModule: 'PRIVACY_POLICY' }}
                        />
                    ),
                }),
            },
        });
        generatorFields.splice(Infinity, 0, ...augmentedTermsConditions);
    }

    const onChange = (id: string, value: string | boolean) => {
        setEditingData({ ...editingData, [id]: value });
    };

    const saveToServer = () => {
        const omitList = userIsLoggedIn
            ? ['unknown_user_firstname', 'unknown_user_lastname', 'unknown_user_email']
            : ['user_firstname', 'user_lastname', 'user_email'];

        setSubmitAttempted(true);
        const dataToSave = {};
        forEach(
            newUserData.filter((d) => !includes(omitList, d.id)),
            (data: FormSubmitDataStructure) => {
                const goodData =
                    data.id === 'inquiry_subject'
                        ? inquirySubjectsFromDict[editingData[data.id]]
                        : editingData[data.id];
                setValueWithPath(dataToSave, data?.form_path, goodData);
            }
        );

        sendContactForm({ contactForm: dataToSave });
    };

    const uiReadyData = extractUiData(generatorFields, editingData);

    useEffect(() => {
        if (userIsLoggedIn && openedHeaderToolId === 'contactProjectForm') {
            getUserProjects();
        }
    }, [userIsLoggedIn]);

    const disableSubmitButton =
        (!userIsLoggedIn && !editingData.accept_terms_and_conditions) ||
        editingData.inquiry_description === '';

    return (
        <div className={styles.centerContent} onClick={(e) => e.stopPropagation()}>
            <div className={styles.content}>
                <div className={styles.contentTitle}>
                    <div>{tt(`general:contact-form.title`)}</div>
                    <DivButton onClick={() => showToolInHeader('')}>
                        <i className='material-icons-outlined'>close</i>
                    </DivButton>
                </div>
                {formIsProtected && !userIsLoggedIn ? (
                    <SignInSignUp inLine noSignUpOption />
                ) : (
                    <>
                        <FormProvider {...formValidator}>
                            {errors && errors.length > 0 ? (
                                <div className={styles.basicInfoError}>
                                    {map(errors, (err: string) => (
                                        <div className={styles.errors} key={uuid()}>
                                            <Markup content={err} />
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                            <EditableFieldGenerator
                                onChange={onChange}
                                generatorFields={map(generatorFields, (field) => ({
                                    ...field,
                                    value: uiReadyData[field.id],
                                    values: field.ids?.map((id) => ({
                                        id,
                                        value: userAccount[id],
                                    })),
                                }))}
                                dictionaryBase={dictionaryBase}
                            />
                        </FormProvider>
                        <div className={styles.contentCtas}>
                            <Button
                                onClick={formValidator.handleSubmit(saveToServer)}
                                disabled={disableSubmitButton}
                            >
                                {t('common:buttons.submit')}
                            </Button>
                            <Button variant='text' onClick={() => showToolInHeader('')}>
                                {t('common:buttons.cancel')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default injectStores(['ApplicationStore', 'ProfileStore'])(ContactForm);
