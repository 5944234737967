import { Instance, types } from 'mobx-state-tree';

export const AddressModel = types.model('AddressModel', {
    // BE will return this soon
    'address-line1': '',
    'address-line2': '',
    // Keeping this in the meantime so it continues to work
    address_1: '',
    address_2: '',
    city: '',
    region: '',
    country: '',
    postalCode: '',
});

export type TypeAddressModel = Instance<typeof AddressModel>;
export default AddressModel;
