import React from 'react';

import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { TypeApplicationStore } from '../../../../stores/application-store';
import { TypeProfileStore } from '../../../../stores/profile-store';
import { injectStores } from '../../../../stores/factories/store-utils';
import { HEADER_TOOLS } from '../../../../constants';

import CurrentUser from '../current-user/current-user';
import DivButton from '../../../common/legos/div-button/div-button';
import GetStartedDropdown from '../../../controls/dropdowns/get-started-dropdown';
import LanguageSwitcher from '../language-switcher/language-switcher';
import Messages from '../messages/messages';
import ModuleNavigation from '../../../modules/module-navigation';
import ShoppingCart from '../shopping-cart/shopping-cart';

import { ModuleRoute } from '../../../../modules/utils/module-interfaces';

import './mobile-menu.scss';

const { SIGN_IN } = HEADER_TOOLS;
export interface Props {
    ApplicationStore: TypeApplicationStore;
    ProfileStore: TypeProfileStore;
    menuState: Record<string, string>;
}

function MobileMenu(props: Props) {
    const { t } = useTranslation();
    const {
        ApplicationStore: {
            headerTools: { mobileMenuOpened },
            showToolInHeader,
        },
        ProfileStore: {
            userIsLoggedIn,
            userAccount: {
                profile: { itemsInCart },
            },
            userAccountUnreadMessages,
        },
        menuState,
    } = props;

    const mobileMenuClass = classnames('mobile-menu', { 'mobile-menu__visible': mobileMenuOpened });

    const jakhubMenuHeaderBox = document
        .getElementById('jakhub-menu-header')
        ?.getBoundingClientRect();
    document.documentElement.style.setProperty(
        '--jakhubMenuHeaderHeight',
        (jakhubMenuHeaderBox?.height || 0) + 'px'
    );

    return (
        <div className={mobileMenuClass}>
            <div className='mobile-main-menu'>
                <ModuleNavigation
                    isMobileMenu
                    className='menu-home'
                    predicate={(module: ModuleRoute) => module.displayInMenu !== false}
                    menuState={menuState}
                />
                {!userIsLoggedIn && (
                    <div className='modnav-main'>
                        <DivButton
                            className='mobile-menu-item link link_variant'
                            onClick={() => {
                                showToolInHeader(SIGN_IN);
                            }}
                        >
                            {t('menu-tools.signup-login.btn-login')}
                        </DivButton>
                    </div>
                )}
                {userIsLoggedIn ? (
                    <>
                        <div className='modnav-main'>
                            <ShoppingCart badgeCount={itemsInCart} isMobileMenu />
                            <Messages badgeCount={userAccountUnreadMessages} isMobileMenu />
                        </div>
                        <div className='modnav-main'>
                            <CurrentUser isMobileMenu />
                        </div>
                    </>
                ) : null}
                <div className='modnav-main'>
                    <GetStartedDropdown isMobileMenu />
                </div>
                <div className='modnav-main'>
                    <LanguageSwitcher isMobileMenu />
                </div>
            </div>
        </div>
    );
}

export default injectStores(['ApplicationStore', 'ProfileStore'])(MobileMenu);
