/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';

import ApiActionFactory from '../../api/api-action-factory';
import StoreFactory from '../factories/store';

import { isDev } from '../../utils/build-utils';

import { WWISE_SUPPORT_POLICY_STATIC_ENDPOINT } from '../../api/endpoints';

const VersionModel = types.model('WwiseVersionModel', {
    name: '',
    version: '',
    state: '',
    starting_at: '',
    end_of_life_at: '',
});

const WwiseVersionModel = types.snapshotProcessor(VersionModel, {
    preProcessor(sn: TypeVersionModel) {
        return {
            ...sn,
            state: sn.state.replace(/_/g, '-'),
        };
    },
});

const WwiseSupportPolicyStore = StoreFactory({
    modelName: 'WwiseSupportPolicyStore',
    models: [
        ApiActionFactory({
            getSupportedWwiseVersions: {
                endpoint: WWISE_SUPPORT_POLICY_STATIC_ENDPOINT,
                target: 'supportedWwiseVersions',
            },
        }),
    ],
    modelStructure: {
        isLoading: false,
        supportedWwiseVersions: types.maybeNull(types.array(WwiseVersionModel)),
    },
});

export type TypeVersionModel = Instance<typeof VersionModel>;
export type TypeWwiseVersionModel = Instance<typeof WwiseVersionModel>;

const WwiseSupportPolicyStoreInstance = WwiseSupportPolicyStore.create();

declare const window: Window &
    typeof globalThis & {
        WwiseSupportPolicyStore: typeof WwiseSupportPolicyStoreInstance;
    };

if (window && isDev()) {
    window.WwiseSupportPolicyStore = WwiseSupportPolicyStoreInstance;
}

export default WwiseSupportPolicyStore;
