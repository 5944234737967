import React from 'react';

import ModuleNavigation from '../../../modules/module-navigation';

import { ModuleId, ModuleRoute } from '../../../../modules/utils/module-interfaces';

import './menu.scss';

interface Props {
    menuState: Record<string, ModuleId>;
}

function SubMenu(props: Props) {
    const { menuState } = props;

    return (
        <div className='sub-header'>
            <ModuleNavigation
                className='sub-menu'
                selectedModules={[menuState.currentModuleId]}
                predicate={(module: ModuleRoute) => module.displayInMenu !== false}
                menuState={menuState}
                showParentModuleName
            />
        </div>
    );
}

export default SubMenu;
