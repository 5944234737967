import { Instance, types } from 'mobx-state-tree';

const PoweredByWwiseGameModel = types.model('CarouselItemsModel', {
    id: types.string,
    imageUrl: types.string,
});
export type TypePoweredByWwiseGameModel = Instance<typeof PoweredByWwiseGameModel>;

const PoweredByWwiseGamesModel = types.array(PoweredByWwiseGameModel);
export type TypePoweredByWwiseGamesModel = Instance<typeof PoweredByWwiseGamesModel>;

export default PoweredByWwiseGamesModel;
