import React, { PropsWithChildren } from 'react';

import styles from './dictionary-components.module.scss';

export default function Bullet({ children }: PropsWithChildren<{}>) {
    return (
        <div className={styles.bullet}>
            <div>{children}</div>
        </div>
    );
}
