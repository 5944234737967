import { Instance, types } from 'mobx-state-tree';
import ApiActionFactory from '../../api/api-action-factory';
import { NEWS_LIST_ENDPOINT, NEWS_PAGE_ENDPOINT } from '../../api/endpoints';
import { isDev } from '../../utils/build-utils';
import StoreFactory from '../factories/store';
import NewsListModel, { NewsPagesModel, TypeNewsListPageModel } from './models/news-list-model';

export interface NewsListPayload {
    count?: number;
    pagination?: {
        pageNumber: number;
        itemsPerPage: number;
    };
}

const NewsListStore = StoreFactory({
    modelName: 'NewsListStore',
    models: [
        ApiActionFactory({
            retrieveNewsList: {
                endpoint: NEWS_LIST_ENDPOINT,
                target: 'newsList',
                onTrigger(self: any) {
                    self.newsList = { news: [], newsCount: null };
                },
            },
            retrieveNewsPage: {
                endpoint: NEWS_PAGE_ENDPOINT,
                target: 'newsPage',
            },
        }),
    ],
    modelStructure: {
        isLoading: false,
        newsList: types.maybeNull(NewsListModel),
        newsPage: types.maybeNull(NewsPagesModel),
    },
})
    .actions((self) => ({
        getAllNews() {
            if (!self?.newsList?.news.length) {
                self.retrieveNewsList({ pagination: { itemsPerPage: 9999, pageNumber: 1 } });
            }
        },
        fetchNewsList(itemsPerPage?: number, pageNumber?: number, year?: number) {
            self.retrieveNewsList({ pagination: { itemsPerPage, pageNumber }, year });
        },
        fetchNewsPage(newsURL: string) {
            self.retrieveNewsPage({ urltag: newsURL });
        },
    }))
    .views((self) => ({
        getNewsList(): TypeNewsListPageModel[] {
            return self?.newsList?.news;
        },
        getNewsCount(): number {
            return self?.newsList?.newsCount;
        },
        getNewsPageContent(newsURL: string): string {
            const pageFromList = self?.newsList?.news.find((obj: TypeNewsListPageModel) => {
                return obj.url.includes(newsURL);
            });
            if (pageFromList) {
                return pageFromList.body;
            } else if (!self.newsPage) {
                self.fetchNewsPage(newsURL);
            }

            return self.newsPage?.content;
        },
    }));

export type TypeNewsListStore = Instance<typeof NewsListStore>;

const NewsListStoreInstance = NewsListStore.create();

declare const window: Window &
    typeof globalThis & {
        NewsListStore: typeof NewsListStoreInstance;
    };

if (window && isDev()) {
    window.NewsListStore = NewsListStoreInstance;
}

export default NewsListStore;
