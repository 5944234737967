import React from 'react';
import { useTranslation } from 'react-i18next';

import MenuIconLink, { MenuIconLinkProps } from '../menu-icon-link/menu-icon-link';

function ShoppingCart(props: MenuIconLinkProps) {
    const { t } = useTranslation();
    const { badgeCount, className = '', isMobileMenu = false } = props;

    return (
        <MenuIconLink
            target={{ module: 'GENERAL', subModule: 'CART' }}
            badgeCount={badgeCount}
            label={t('general:module.cart.menu-label')}
            icon='shopping_cart'
            isMobileMenu={isMobileMenu}
            className={className}
        />
    );
}

export default ShoppingCart;
