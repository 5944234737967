import React, { PropsWithChildren } from 'react';
import { useJakhubTranslation } from '../../../../i18n/jakhub-translation';
import { injectStores } from '../../../../stores/factories/store-utils';
import { TypeProfileStore } from '../../../../stores/profile-store';

import Button from '../../../controls/button/button';
import DivButton from '../../../common/legos/div-button/div-button';
import SwitchLink from '../../../menus/menu/switch-link/switch-link';

import { ModuleId, TargetType } from '../../../../modules/utils/module-interfaces';
import { TypeApplicationStore } from '../../../../stores/application-store';
import { HEADER_TOOLS } from '../../../../constants';

import styles from './email-confirmation-messaging.module.scss';

const { RESET_PASSWORD } = HEADER_TOOLS;

type InLineButtonProps<M extends ModuleId> = {
    id: string;
    onClick?: () => void;
    to?: TargetType<M>;
} & PropsWithChildren<{}>;
export function InlineButton<M extends ModuleId>({
    id,
    children,
    onClick,
    to,
}: InLineButtonProps<M>) {
    if (to) {
        return (
            <SwitchLink
                akTestId={`InlineButton|LinkTo|${to.module}|${to.subModule.toString()}`}
                to={to}
            >
                <Button id={`email-confirmation|with-route|${id}`} variant='contained'>
                    {children}
                </Button>
            </SwitchLink>
        );
    }

    if (onClick) {
        return (
            <Button
                id={`email-confirmation|with-action|${id}`}
                onClick={onClick}
                variant='contained'
            >
                {children}
            </Button>
        );
    }
    return <>{children}</>;
}
type Props = {
    ApplicationStore: TypeApplicationStore;
    ProfileStore: TypeProfileStore;
    onClick?: () => void;
    messageContext?: string;
    canDismiss?: boolean;
    noAction?: boolean;
};
function EmailConfirmationMessaging(props: Props) {
    const { t, tt } = useJakhubTranslation();
    const {
        ApplicationStore: { showToolInHeader },
        ProfileStore: { userResendValidationEmail, userAccount },
        onClick,
        messageContext = 'UNVERIFIED_USER',
        canDismiss = true,
        noAction = false,
    } = props;

    // ToDo HUB-1346 will take care of fixing this. It's not as trivial as it may seem ;-)
    const { account = {} } = { ...userAccount };
    const { emails = { primary: '' } } = { ...account };
    const { primary: email } = emails;

    const namespace = 'general';
    const moduleName = `menu-message.${messageContext}`;
    const dictionaryBase = `${namespace}:${moduleName}`;

    const dismissMessage = () => {
        onClick && onClick();
    };

    const resendTriggerResetPassword = () => {
        showToolInHeader(RESET_PASSWORD);
        onClick && onClick();
    };
    const resendConfirmationClicked = () => {
        userResendValidationEmail({ email });
        onClick && onClick();
    };
    const menuMessage_1 = tt(`${dictionaryBase}.part_1`);
    const menuMessage_2 = tt(`${dictionaryBase}.part_2`);
    const menuMessage_3 = tt(`${dictionaryBase}.part_3`);
    const menuMessage_cta = t(`${dictionaryBase}.cta`, {
        AK_RESEND_VERIFY_LINK: (
            <InlineButton id='AK_RESEND_VERIFY_LINK' onClick={resendConfirmationClicked} />
        ),
        AK_RESEND_PASSWORD_RESET_LINK: (
            <InlineButton id='AK_RESEND_PASSWORD_RESET_LINK' onClick={resendTriggerResetPassword} />
        ),
        AK_GOTO_SETTINGS_LINK: (
            <InlineButton
                id='AK_GOTO_SETTINGS_LINK'
                to={{ module: 'SETTINGS', subModule: 'ACCOUNT_SETTINGS' }}
            />
        ),
    });

    return (
        <div className={styles.centerContent}>
            <div className={styles.content}>
                <div className={styles.contentTitle}>
                    <div>{t(`${dictionaryBase}.title`)}</div>
                    {canDismiss ? (
                        <DivButton onClick={dismissMessage}>
                            <i className='material-icons'>close</i>
                        </DivButton>
                    ) : null}
                </div>
                <div className={styles.contentDescription}>
                    <span>{menuMessage_1}</span>
                    <span>{menuMessage_2}</span>
                    <span>{menuMessage_3}</span>
                </div>
                {!noAction ? <div className={styles.contentCtas}>{menuMessage_cta}</div> : null}
            </div>
        </div>
    );
}

export default injectStores(['ApplicationStore', 'ProfileStore'])(EmailConfirmationMessaging);
