import { Instance, types } from 'mobx-state-tree';

export const AuthorModel = types.model('AuthorModel', {
    authorName: '',
    authorUrl: '',
    points: 0,
});

export type TypeAuthorModel = Instance<typeof AuthorModel>;

export default AuthorModel;
