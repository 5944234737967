import { Instance, types } from 'mobx-state-tree';

// Proper model coming soon
const CompaniesModel = types.frozen({});

// Proper model coming soon
const SchoolsModel = types.frozen({});

// Proper model coming soon
const UsersModel = types.frozen({});

const SearchHashModel = types.model('SearchHashModel', {
    companies: types.string,
    schools: types.string,
    users: types.string,
});

const CreatorsDirectoryModel = types.model('CreatorsDirectoryModel', {
    companies: types.maybeNull(CompaniesModel),
    companiesCount: types.maybeNull(types.number),
    schools: types.maybeNull(SchoolsModel),
    schoolsCount: types.maybeNull(types.number),
    users: types.maybeNull(UsersModel),
    usersCount: types.maybeNull(types.number),
    searchHash: types.maybeNull(SearchHashModel),
});

export type TypeValidSearchHashes = keyof typeof SearchHashModel.properties;
export type TypeCreatorsDirectoryModel = Instance<typeof CreatorsDirectoryModel>;
export type TypeCreatorsDirectoryProperties = Instance<typeof CreatorsDirectoryModel.properties>;

export default CreatorsDirectoryModel;
