import React, { useEffect } from 'react';
import MenuMessage from './menu-message';

import { useJakhubTranslation } from '../../../i18n/jakhub-translation';

import SwitchLink from '../../menus/menu/switch-link/switch-link';

import './menu-message.scss';

import COLORS from '../../../styles/legos/colors.module.scss';
import { injectStores } from '../../../stores/factories/store-utils';
import { TypeContentStore } from '../../../stores/content-store';
import { TypeApplicationStore } from '../../../stores/application-store';
import { HARD_PATHS } from '../../../modules/utils/hard-paths';

const { akCyan } = COLORS;

type Props = {
    ApplicationStore: TypeApplicationStore;
    ContentStore: TypeContentStore;
};
function SpecialMenuMessage(props: Props) {
    const { T } = useJakhubTranslation();
    const {
        ApplicationStore: { getUserLocale },
        ContentStore: {
            getSpecialMessageConfig,
            SpecialBannerMessage: {
                publishDateStart,
                publishDateEnd,
                cookieResetDate,
                cookieExpiry,
                message_en_US,
                message_fr_CA,
                message_ja_JP,
                message_ko_KR,
                message_zh_CN,
            },
        },
    } = props;
    const messages = {
        'en-US': message_en_US,
        'fr-CA': message_fr_CA,
        'ja-JP': message_ja_JP,
        'ko-KR': message_ko_KR,
        'zh-CN': message_zh_CN,
    } as Record<string, string>;

    const messageConfig = {
        i18nId: 'AK_HOLIDAYS',
        publishDateStart,
        publishDateEnd,
        cookieResetDate,
        cookieExpiry,
        barColor: akCyan,
    };

    useEffect(() => {
        getSpecialMessageConfig();
    }, []);

    const menuMessage = T(messages[getUserLocale()], {
        AK_LEARN_MORE_LINK: (
            <SwitchLink
                className='white-link'
                akTestId='HolidayMenuMessage|HOLIDAY_SCHEDULE_MORE_INFO'
                to={HARD_PATHS.HELP.HOLIDAY_SCHEDULE}
            />
        ),
    });

    if (messages[getUserLocale()] === '') return null;

    return (
        <MenuMessage messageConfig={messageConfig}>
            <div>{menuMessage}</div>
        </MenuMessage>
    );
}

export default injectStores(['ApplicationStore', 'ContentStore'])(SpecialMenuMessage);
