import { Instance, types } from 'mobx-state-tree';
import { LocalizedLabelMap } from './localizes-label-map';

const TicketModel = types.model('TicketModel', {
    id: types.number,
    subject: types.string,
    createdDate: types.string,
    latestMessageDate: types.string,
    latestPostAuthor: types.string,
    projectMask: types.string,
    projectName: types.string,
    ticketUrl: types.string,
    ticketStatus: types.string,
    ticketSeverity: types.string,
    bugFeatureNumbers: types.array(types.string),
});
export type TypeTicketModel = Instance<typeof TicketModel>;

const TicketListModel = types.model('TicketListModel', {
    currentPageNumber: types.number,
    ticketsCount: types.number,
    tickets: types.array(TicketModel),
});
export type TypeTicketListModel = Instance<typeof TicketListModel>;

const SupportTicketsFieldsModel = types.model('SupportTicketsFieldsModel', {
    type: types.string,
    fieldName: types.string,
    isSortable: types.boolean,
    isLink: types.boolean,
    isImage: types.boolean,
});
export type TypeSupportTicketsFieldsModel = Instance<typeof SupportTicketsFieldsModel>;

const SupportTicketsInfoModel = types.model('SupportTicketsInfoModel', {
    fields: types.array(SupportTicketsFieldsModel),
    severityLabels: types.map(LocalizedLabelMap),
    statusLabels: types.map(LocalizedLabelMap),
});
export type TypeSupportTicketsInfoModel = Instance<typeof SupportTicketsModel>;

export const SupportTicketsModel = types.model('SchoolCreationModel', {
    info: types.maybeNull(SupportTicketsInfoModel),
    ticketList: types.maybeNull(TicketListModel),
});

export type TypeSupportTicketsModel = Instance<typeof SupportTicketsModel>;
export default SupportTicketsModel;
