import { Instance, types } from 'mobx-state-tree';

import { PublicProfileModel as ProfileModel } from './profile-model';

const PublicProfileModel = types.model('PublicProfileModel', {
    profile: types.maybeNull(ProfileModel),
    profileInError: types.maybeNull(types.string),
});

export type TypePublicProfileModel = Instance<typeof PublicProfileModel>;
export default PublicProfileModel;
