import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = `/`;

export const PATHS = {
    HOME: {
        path: `${moduleRoot}`,
    },
    HOME_LOGIN: {
        path: `${moduleRoot}login`,
        hardPath: HARD_PATHS.HOME.HOME_LOGIN,
    },
    FOOTER_HOME: {
        path: `${moduleRoot}`,
    },
};
