import React, { useRef } from 'react';

import { TypeApplicationStore } from '../../../../stores/application-store';
import { injectStores } from '../../../../stores/factories/store-utils';

import MenuDropdown from '../../../controls/dropdowns/menu-dropdown';
import SearchBar from '../../../common/search-bar/search-bar';

import { HEADER_TOOLS } from '../../../../constants';

import './search.scss';

const TOOL_NAME = HEADER_TOOLS.SEARCH_BAR;

interface Props {
    ApplicationStore: TypeApplicationStore;
}

function Search(props: Props) {
    const {
        ApplicationStore: {
            headerTools: { openedHeaderTool },
            showToolInHeader,
        },
    } = props;

    const searchButtonRef = useRef(null);
    return (
        <div ref={searchButtonRef} className='search'>
            <MenuDropdown
                showOpenList={openedHeaderTool === TOOL_NAME}
                actionOpenList={() => showToolInHeader(TOOL_NAME)}
                optionsComponents={<SearchBar />}
                optionsClassName='search-dropdown'
                fadeInDelay={500}
            >
                <i className='material-icons'>
                    {openedHeaderTool === TOOL_NAME ? 'close' : 'search'}
                </i>
            </MenuDropdown>
        </div>
    );
}

export default injectStores(['ApplicationStore'])(Search);
