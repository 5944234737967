import { Instance, types } from 'mobx-state-tree';

const LanguageLocaleModel = types.model('LanguageLocaleModel', {
    en_US: types.optional(types.frozen<Record<string, string>>(), {}, [null, undefined]),
    fr_CA: types.optional(types.frozen<Record<string, string>>(), {}, [null, undefined]),
    ja_JP: types.optional(types.frozen<Record<string, string>>(), {}, [null, undefined]),
    ko_KR: types.optional(types.frozen<Record<string, string>>(), {}, [null, undefined]),
    zh_CN: types.optional(types.frozen<Record<string, string>>(), {}, [null, undefined]),
});
export type TypeLanguageLocaleModel = Instance<typeof LanguageLocaleModel>;
export default LanguageLocaleModel;
