import { createTheme } from '@material-ui/core';
import { PaginationClassKey } from '@material-ui/lab/Pagination';

declare module '@material-ui/core/styles/overrides' {
    export interface ComponentNameToClassKey {
        MuiPagination: PaginationClassKey;
    }
}

import colors from '../styles/legos/colors.module.scss';

const { akFossil, akIron, akPrimary300, akSecondary, akWhite, akStone, akRed } = colors;

const accordionBorderRadius = '0';
const buttonPaddingSmall = '0.57em 1.15em';
const buttonPaddingMedium = '0.75em 1em';
const buttonPaddingLarge = '1em 1.5em';
const tabThreePointLineHeight = '20px';

const theme = createTheme({
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    overrides: {
        MuiPagination: {
            root: {
                gap: '30px',
                '& .MuiPaginationItem-outlined': {
                    color: akIron,
                    border: `1px solid ${akStone}`,
                },
                '& .Mui-selected': {
                    border: `2px solid ${akPrimary300}`,
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    fontWeight: '600',
                },
            },
            ul: {
                gap: '4px 0',
                // width: 'fit-content',
                // justifyContent: 'space-between',
            },
        },
        MuiTableCell: {
            root: {
                padding: '8px',
                position: 'relative',
            },
        },
        MuiTabs: {
            root: {
                textAlign: 'left',
            },
        },
        MuiTab: {
            root: {
                width: '100%',
                maxWidth: 'unset',
                padding: `${tabThreePointLineHeight} calc(4 * ${tabThreePointLineHeight}) ${tabThreePointLineHeight} ${tabThreePointLineHeight} `,
                fontSize: tabThreePointLineHeight,
            },
            labelIcon: {
                paddingTop: `${tabThreePointLineHeight}`,
            },
            wrapper: {
                color: akIron,
                alignItems: 'flex-start',
                '& :selected': {
                    color: 'red',
                    boxShadow: 'none',
                },
            },
        },
        MuiInputBase: {
            root: {
                padding: '0 5px',
            },
            input: {
                padding: '2px 0 0 5px',
            },
        },
        MuiInputLabel: {
            root: {
                '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                    transform: 'translate(8px, -6px) scale(0.75)',
                },
            },
            outlined: {
                backgroundColor: 'rgba(255, 255, 255, 1)',
                padding: '0 8px',
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
        },
        MuiButton: {
            root: {
                borderRadius: 2,
                fontSize: '1em',
                height: '2.5em',
                padding: buttonPaddingMedium,
            },
            sizeSmall: {
                fontSize: '0.875em',
                height: '2.29em',
                padding: buttonPaddingSmall,
            },
            sizeLarge: {
                fontSize: '1em',
                height: '3.5em',
                padding: buttonPaddingLarge,
                lineHeight: '1.5em',
                fontWeight: 700,
            },
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
            },
            text: {
                padding: buttonPaddingMedium,
            },
            textSizeSmall: {
                padding: buttonPaddingSmall,
            },
            textSizeLarge: {
                padding: buttonPaddingLarge,
            },
            outlined: {
                padding: buttonPaddingMedium,
            },
            outlinedSizeSmall: {
                padding: buttonPaddingSmall,
            },
            outlinedSizeLarge: {
                padding: buttonPaddingLarge,
            },
            endIcon: {
                marginLeft: '0.5em',
            },
        },
        MuiAccordion: {
            root: {
                backgroundColor: akWhite,
                border: 'none',
                margin: '0',
                color: akIron,
                borderRadius: '0',
                fontSize: '1rem',
                lineHeight: '1.5rem',
                '&.Mui-expanded': {
                    margin: '0',
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: '0',
                },
                '& .MuiAccordionSummary-expandIcon': {
                    transform: 'rotate(90deg)',
                },
                '& .MuiAccordionSummary-root.Mui-expanded': {
                    minHeight: '0',
                },
                '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
                    transform: 'rotate(-90deg)',
                },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'rotate(0deg)',
                },

                '&:before': {
                    display: 'none',
                },
            },
            rounded: {
                borderRadius: accordionBorderRadius,
                '&:first-child': {
                    borderTopLeftRadius: accordionBorderRadius,
                    borderTopRightRadius: accordionBorderRadius,
                },
                '&:last-child': {
                    borderBottomLeftRadius: accordionBorderRadius,
                    borderBottomRightRadius: accordionBorderRadius,
                },
            },
        },
        MuiAccordionSummary: {
            root: {
                padding: '0 16px 0 0',
            },
            content: {
                transform: 'none',
                fontWeight: 600,
                margin: 0,
                padding: 0,
            },
        },
        MuiAccordionDetails: {
            root: {
                backgroundColor: akWhite,
                margin: '2px 0',
                color: akFossil,
                fontSize: '0.9rem',
                lineHeight: '1.1rem',
                textAlign: 'left',
                '&.Mui-expanded': {
                    margin: '2px 0',
                },
                '& .MuiAccordionSummary-expandIcon': {
                    transform: 'rotate(90deg)',
                },
                '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
                    transform: 'rotate(-90deg)',
                },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'rotate(0deg)',
                },
            },
        },
        MuiFormControl: {
            root: {
                backgroundColor: akWhite,
            },
        },
        MuiFormLabel: {
            root: {
                fontWeight: 700,
            },
        },
    },
    typography: {
        fontFamily: 'Noto, sans-serif',
        button: {
            fontFamily: 'Noto, sans-serif',
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.25rem',
            textTransform: 'none',
        },
    },
    palette: {
        primary: {
            main: akPrimary300,
            contrastText: akWhite,
        },
        secondary: {
            main: akSecondary,
            contrastText: akPrimary300,
        },
        error: {
            main: akRed,
            contrastText: akPrimary300,
        },
    },
});

export default theme;
