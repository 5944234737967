import React from 'react';

import { injectStores } from '../../../stores/factories/store-utils';
import { TypeApplicationStore } from '../../../stores/application-store';

import { HEADER_TOOLS } from '../../../constants';

import ContactFormComponent from './contact-form';
import ScrollLocker from '../legos/scroll-locker/scroll-locker';

import styles from './contact-forms.module.scss';

type Props = {
    ApplicationStore: TypeApplicationStore;
};

function ContactForm(props: Props) {
    const {
        ApplicationStore: {
            headerTools: { openedHeaderTool },
        },
    } = props;

    if (openedHeaderTool !== HEADER_TOOLS.CONTACT_FORM) return null;

    return (
        <div className={styles.contactForms}>
            <div className={styles.formComponent}>
                <ContactFormComponent formId={openedHeaderTool} />
                <ScrollLocker lockScroller />
            </div>
        </div>
    );
}

export default injectStores(['ApplicationStore'])(ContactForm);
