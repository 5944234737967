import { lazy } from 'react';
import { typedRoutes } from './utils/module-interfaces';
import { PATHS } from './soft-paths/community';

export const ROUTES = typedRoutes({
    COMMUNITY: {
        ...PATHS.COMMUNITY,
        id: 'COMMUNITY',
        name: 'Community',
        i18nBase: 'community:module.community',
        i18nId: 'community:module.community.menu-label',
        displayInMenu: false,
        component: lazy(() => import('../components/modules/community/community')),
    },
    BLOG: {
        ...PATHS.BLOG,
        protectedFeature: 'COMMUNITY_BLOG_PAGE',
        id: 'BLOG',
        name: 'Blog',
        i18nBase: 'community:module.blog',
        i18nId: 'community:module.blog.menu-label',
        component: lazy(() => import('../components/modules/community/blog-page/blog-page')),
    },
    SUBMIT_BLOG: {
        ...PATHS.SUBMIT_BLOG,
        id: 'SUBMIT_BLOG',
        name: 'SUBMIT_BLOG',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    SUBMIT_VIDEO: {
        ...PATHS.SUBMIT_VIDEO,
        id: 'SUBMIT_VIDEO',
        name: 'SUBMIT_VIDEO',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    JOB_SKILLS: {
        ...PATHS.JOB_SKILLS,
        id: 'JOB_SKILLS',
        name: 'game audio job skills',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    CONTRIBUTORS: {
        ...PATHS.CONTRIBUTORS,
        id: 'CONTRIBUTORS',
        name: 'BlogContributors',
        i18nBase: 'community:module.contributors',
        i18nId: 'community:module.contributors.menu-label',
        displayInMenu: false,
    },
    CREATORS_DIRECTORY: {
        ...PATHS.CREATORS_DIRECTORY,
        id: 'CREATORS_DIRECTORY',
        name: 'CreatorsDirectory',
        i18nBase: 'community:module.creators-directory',
        i18nId: 'community:module.creators-directory.menu-label',
        component: lazy(
            () => import('../components/modules/community/creators-directory/creators-directory')
        ),
    },
    QUESTIONS_ANSWERS: {
        ...PATHS.QUESTIONS_ANSWERS,
        id: 'QUESTIONS_ANSWERS',
        name: 'QuestionAnswers',
        i18nBase: 'community:module.questions-answers',
        i18nId: 'community:module.questions-answers.menu-label',
        displayInFooter: false,
        component: lazy(
            () => import('../components/modules/common/question-answers/questions-answers')
        ),
    },
    QUESTIONS: {
        ...PATHS.QUESTIONS,
        id: 'QUESTIONS',
        name: 'Questions',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    ASK_A_QUESTION: {
        ...PATHS.ASK_A_QUESTION,
        id: 'ASK_A_QUESTION',
        name: 'AskAQuestion',
        i18nBase: 'community:module.ask-a-question',
        i18nId: 'community:module.ask-a-question.menu-label',
        displayInMenu: false,
    },
    QUESTIONS_UNANSWERED: {
        ...PATHS.QUESTIONS_UNANSWERED,
        id: 'QUESTIONS_UNANSWERED',
        name: 'Questions Unanswered',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    STRATA_QUESTIONS_UNANSWERED: {
        ...PATHS.STRATA_QUESTIONS_UNANSWERED,
        id: 'STRATA_QUESTIONS_UNANSWERED',
        name: 'Strata Questions Unanswered',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    EVENTS: {
        ...PATHS.EVENTS,
        id: 'EVENTS',
        name: 'Events',
        i18nBase: 'community:module.events',
        i18nId: 'community:module.events.menu-label',
        component: lazy(() => import('../components/modules/community/events/events')),
    },
    WWISETOUR: {
        ...PATHS.WWISETOUR,
        id: 'WWISETOUR',
        name: 'WwiseTour',
        i18nBase: 'community:module.wwise-tour',
        i18nId: 'community:module.wwise-tour.menu-label',
        component: lazy(() => import('../components/modules/community/wwise-tour/wwise-tour')),
    },
    AMBASSADOR: {
        ...PATHS.AMBASSADOR,
        id: 'AMBASSADOR',
        name: 'Wwise Ambassador',
        i18nBase: 'community:wwise-ambassador',
        i18nId: 'community:wwise-ambassador.menu-label',
        component: lazy(
            () => import('../components/modules/community/wwise-ambassador/wwise-ambassador')
        ),
    },
    ONE_MINUTE_WWISE: {
        ...PATHS.ONE_MINUTE_WWISE,
        id: 'ONE_MINUTE_WWISE',
        name: 'OneMinuteWwise',
        i18nBase: 'community:module.one-minute-wwise',
        i18nId: 'community:module.one-minute-wwise.menu-label',
        displayInMenu: false,
    },
    WWISE_UP_ON_AIR: {
        ...PATHS.WWISE_UP_ON_AIR,
        id: 'WWISE_UP_ON_AIR',
        name: 'WwiseUpOnAir',
        i18nBase: 'community:module.wwise-up-on-air',
        i18nId: 'community:module.wwise-up-on-air.menu-label',
        displayInMenu: false,
    },
    WWISE_INTERACTIVE_MUSIC_SYMPOSIUM: {
        ...PATHS.WWISE_INTERACTIVE_MUSIC_SYMPOSIUM,
        id: 'WWISE_INTERACTIVE_MUSIC_SYMPOSIUM',
        name: 'Wwise Interactive Music Symposium',
        i18nBase: 'community:module.wwise-interactive-music-symposium',
        i18nId: 'community:module.wwise-interactive-music-symposium.menu-label',
        displayInMenu: false,
    },
    REGISTER_COMPANY: {
        ...PATHS.REGISTER_COMPANY,
        id: 'REGISTER_COMPANY',
        name: 'Register Company',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    CREATORS_DIRECTORY_FAQ: {
        ...PATHS.CREATORS_DIRECTORY_FAQ,
        id: 'CREATORS_DIRECTORY_FAQ',
        name: 'Creators Directory FAQ',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    FIND_COMPANY: {
        ...PATHS.FIND_COMPANY,
        id: 'FIND_COMPANY',
        name: 'Find Company',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    FIND_SCHOOL: {
        ...PATHS.FIND_SCHOOL,
        id: 'FIND_SCHOOL',
        name: 'Find School',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    GET_CERTIFIED: {
        ...PATHS.GET_CERTIFIED,
        id: 'GET_CERTIFIED',
        name: 'Get Certified',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    FIND_CERTIFIED_USER: {
        ...PATHS.FIND_CERTIFIED_USER,
        id: 'FIND_CERTIFIED_USER',
        name: 'Find Certified User',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    SUBSCRIBE_YOUTUBE: {
        ...PATHS.SUBSCRIBE_YOUTUBE,
        id: 'SUBSCRIBE_YOUTUBE',
        name: 'YouTube channel',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    SUBSCRIBE_BILIBILI: {
        ...PATHS.SUBSCRIBE_BILIBILI,
        id: 'SUBSCRIBE_BILIBILI',
        name: 'BiliBili channel',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    SUBSCRIBE_TWITCH: {
        ...PATHS.SUBSCRIBE_TWITCH,
        id: 'SUBSCRIBE_TWITCH',
        name: 'Find Certified User',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    GAME_JAMS: {
        ...PATHS.GAME_JAMS,
        id: 'GAME_JAMS',
        name: 'Game Jams',
        i18nBase: 'community:module.game-jams',
        i18nId: 'community:module.game-jams.menu-label',
        displayInMenu: false,
    },
    REGISTER_GAME_JAM: {
        ...PATHS.REGISTER_GAME_JAM,
        id: 'REGISTER_GAME_JAM',
        name: 'Register Game Jam',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    WWIZARDS_WWITCHES: {
        ...PATHS.WWIZARDS_WWITCHES,
        id: 'WWIZARDS_WWITCHES',
        name: 'Wwise Wwizards Wwitches',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
    PARTNER_PROGRAM: {
        ...PATHS.PARTNER_PROGRAM,
        id: 'PARTNER_PROGRAM',
        name: 'Community Partner Program',
        i18nBase: 'NOT_NECESSARY',
        i18nId: 'NOT_NECESSARY',
        displayInMenu: false,
    },
});

export const DEFAULT_ROUTE = ROUTES.COMMUNITY;
