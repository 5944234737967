/* eslint-disable react/jsx-props-no-spreading */
import React, { MouseEvent } from 'react';

import MuiTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

import COLORS from '../../../styles/legos/colors.module.scss';
const { akInk, akFrost } = COLORS;

// Todo HUB-1062 Fix Styling (remove makeStyles, fix hovering labels)
const infoStyles = makeStyles({
    root: {
        borderRadius: '0',
        color: akInk,
        backgroundColor: akFrost,
        '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '0',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            color: akInk,
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: akInk,
        },
    },
});

const editStyles = makeStyles({
    root: {
        borderRadius: '15px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
        },
    },
});

export type Props = {
    editMode?: boolean;
} & TextFieldProps;

function TextField(props: Props) {
    const {
        label = '',
        placeholder = '',
        type = 'text',
        variant = 'outlined',
        size = 'medium',
        autoComplete = 'on',
        InputProps,
        editMode = true,
        disabled,
        ...other
    } = props;

    const classes = editMode ? editStyles() : infoStyles();
    return (
        <MuiTextField
            onClick={(event: MouseEvent<HTMLDivElement>) => event.stopPropagation()}
            {...other}
            label={label}
            placeholder={placeholder}
            type={type}
            disabled={!editMode || disabled}
            size={size}
            variant={variant}
            autoComplete={autoComplete}
            InputProps={InputProps}
            className={classes.root}
        />
    );
}

export default TextField;
