import { Instance, types } from 'mobx-state-tree';

const CardInfoModel = types.model('CardInfoModel', {
    cardNumber: types.maybeNull(types.string),
    expiration: types.maybeNull(types.string),
    cardholderName: types.maybeNull(types.string),
    addressNumber: types.maybeNull(types.string),
    addressLine1: types.maybeNull(types.string),
    addressLine2: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    region: types.maybeNull(types.string),
    postalCode: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
});

export type TypeCardInfoModel = Instance<typeof CardInfoModel>;
export default CardInfoModel;
