import { useState } from 'react';
import useEventListener from './useEventListener';
import Variables from '../styles/legos/variables.module.scss';

import { Resolution } from '../stores/models/model-enums';

const { BreakpointXSmall, BreakpointSmall, BreakpointMedium, BreakpointLarge, BreakpointXLarge } =
    Variables;

const stripPx = (size: string) => parseInt(size, 10);
function namedResolution(width: number) {
    const x_small = stripPx(BreakpointXSmall);
    const small = stripPx(BreakpointSmall);
    const medium = stripPx(BreakpointMedium);
    const large = stripPx(BreakpointLarge);
    const x_large = stripPx(BreakpointXLarge);

    if (width > x_large) return Resolution.XXLARGE;
    if (width > large) return Resolution.XLARGE;
    if (width > medium) return Resolution.LARGE;
    if (width > small) return Resolution.MEDIUM;
    if (width > x_small) return Resolution.SMALL;

    return Resolution.XSMALL;
}

function isSmall(width: number) {
    const small = stripPx(BreakpointSmall);
    return width <= small;
}

function isMobile(width: number) {
    const medium = stripPx(BreakpointMedium);
    return width <= medium;
}

export default function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEventListener('resize', () => {
        setWindowSize({ ...windowSize, width: window.innerWidth, height: window.innerHeight });
    });
    const w = windowSize.width;

    return {
        ...windowSize,
        isMobile: isMobile(w),
        isSmall: isSmall(w),
        resolution: namedResolution(w),
    };
}

export function useWindowScroll() {
    const [windowScroll, setWindowScroll] = useState({
        scrollY: window.scrollY,
        scrollX: window.scrollX,
    });

    useEventListener('scroll', () => {
        setWindowScroll({ ...windowScroll, scrollY: window.scrollY, scrollX: window.scrollX });
    });

    return windowScroll;
}
