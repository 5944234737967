/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';

import ApiActionFactory from '../../api/api-action-factory';
import StoreFactory from '../factories/store';

import { WWISE_CERTIFICATION_ENDPOINT } from '../../api/endpoints';

import CarouselItemsModel from './models/carousel-items-model';

import { isDev } from '../../utils/build-utils';

const WwiseCertificationStore = StoreFactory({
    modelName: 'WwiseCertificationStore',
    models: [
        ApiActionFactory({
            getWwiseCertification: {
                endpoint: WWISE_CERTIFICATION_ENDPOINT,
                target: 'certification',
            },
        }),
    ],
    modelStructure: {
        isLoading: false,
        certification: types.maybeNull(types.array(CarouselItemsModel)),
    },
}).actions((self) => ({
    retrieveWwiseCertification() {
        if (!self.certification) {
            self.getWwiseCertification();
        }
    },
}));

export type TypeWwiseCertificationStore = Instance<typeof WwiseCertificationStore>;

const WwiseCertificationStoreInstance = WwiseCertificationStore.create();
declare const window: Window &
    typeof globalThis & {
        WwiseCertificationStore: typeof WwiseCertificationStoreInstance;
    };

if (window && isDev()) {
    window.WwiseCertificationStore = WwiseCertificationStoreInstance;
}

export default WwiseCertificationStore;
