/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';

import { COMMUNITY_QA_ENDPOINT } from '../../api/endpoints';

import ApiActionFactory from '../../api/api-action-factory';
import { isDev } from '../../utils/build-utils';

import { QuestionsAnswersModel } from './models/questions-answers-model';

import StoreFactory from '../factories/store';

const QuestionsAnswersStore = StoreFactory({
    modelName: 'QuestionsAnswersStore',
    models: [
        ApiActionFactory({
            getCommunityQandA: {
                endpoint: COMMUNITY_QA_ENDPOINT,
                target: 'communityQandA',
            },
        }),
    ],
    modelStructure: {
        title: 'QuestionsAnswersStore',
        isLoading: false,
        communityQandA: types.maybeNull(QuestionsAnswersModel),
    },
})
    .actions((self) => ({
        retrieveCommunityQandA() {
            if (!self.communityQandA) {
                self.getCommunityQandA();
            }
        },
    }))
    .views((self) => ({
        mostRecent() {
            return self.communityQandA?.mostRecent;
        },
        mostPopular() {
            return self.communityQandA?.mostPopular;
        },
    }));

export type TypeQuestionsAnswersStore = Instance<typeof QuestionsAnswersStore>;

const QuestionsAnswersStoreInstance = QuestionsAnswersStore.create();
declare const window: Window &
    typeof globalThis & {
        QuestionsAnswersStore: typeof QuestionsAnswersStoreInstance;
    };

if (window && isDev()) {
    window.QuestionsAnswersStore = QuestionsAnswersStoreInstance;
}

export default QuestionsAnswersStore;
