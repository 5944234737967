import React from 'react';

import { useTranslation } from 'react-i18next';

import { map } from 'lodash';

import { injectStores } from '../../../../stores/factories/store-utils';
import { TypeApplicationStore } from '../../../../stores/application-store';

import { useWindowSize } from '../../../../custom-hooks';

import DivButton from '../../../common/legos/div-button/div-button';
import MenuDropdown from '../../../controls/dropdowns/menu-dropdown';
import ModuleExpander from '../../../modules/module-expander';

import { HEADER_TOOLS } from '../../../../constants';
import {
    supportedLocales,
    supportedLanguages,
    SupportedLocales,
} from '../../../../i18n/supported-locales';

const TOOL_NAME = HEADER_TOOLS.LANGUAGE_SWITCHER;

interface LocaleOptionsProps {
    setUserLocale: (newLocal: SupportedLocales) => void;
    hideAllHeaderTools: () => void;
}

function LocaleOptions(props: LocaleOptionsProps) {
    const { t } = useTranslation();
    const { hideAllHeaderTools, setUserLocale } = props;

    const setAndCloseLocaleOptions = (newLocale: SupportedLocales) => {
        hideAllHeaderTools();
        // Redirect to the homologous URL for the newLocale:
        setUserLocale(newLocale);
        const pathSegments = window.location.pathname.substring(1).split('/');
        const redirect = supportedLanguages.includes(pathSegments[0])
            ? `/${newLocale.split('-')[0]}/${pathSegments.slice(1).join('/')}`
            : `/${newLocale.split('-')[0]}${window.location.pathname}`;
        console.log('redirecting to...', redirect);
        window.location.href = `${redirect}${window.location.search}`;
    };

    return (
        <>
            {map(supportedLocales, (locale) => (
                <DivButton
                    key={`locale-btn-${locale}`}
                    className='link__dropdown'
                    onClick={() => setAndCloseLocaleOptions(locale)}
                >
                    <div>{t(`menu-tools.language-switcher.${locale}.selector-label`)}</div>
                </DivButton>
            ))}
        </>
    );
}

interface Props {
    ApplicationStore: TypeApplicationStore;
    isMobileMenu?: boolean;
    className?: string;
}

function LanguageSwitcher(props: Props) {
    const { t } = useTranslation();
    const {
        ApplicationStore: {
            getUserLocale,
            setUserLocale,
            headerTools: { openedHeaderTool },
            showToolInHeader,
            hideAllHeaderTools,
        },
        isMobileMenu = false,
        className = '',
    } = props;
    const { isMobile } = useWindowSize();

    const isActiveTool = openedHeaderTool === TOOL_NAME;

    if (isMobileMenu && !isMobile) return null;

    return (
        <div className={'language-switcher ' + className}>
            <MenuDropdown
                optionsComponents={
                    <LocaleOptions
                        setUserLocale={setUserLocale}
                        hideAllHeaderTools={hideAllHeaderTools}
                    />
                }
                showOpenList={isActiveTool}
                actionOpenList={() => showToolInHeader(TOOL_NAME)}
            >
                <>
                    {!isMobileMenu && <i className='icon-locale material-icons'>language</i>}
                    {isMobileMenu ? (
                        <div
                            className={
                                'language-switcher-toggler' +
                                (isMobileMenu ? ' mobile-menu-item' : '')
                            }
                        >
                            <div className='link link_variant'>
                                {t(
                                    `menu-tools.language-switcher.${getUserLocale()}.selector-label`
                                )}
                            </div>
                            <ModuleExpander
                                onClick={() => {
                                    showToolInHeader(TOOL_NAME);
                                }}
                                isExpanded={isActiveTool}
                            />
                        </div>
                    ) : null}
                </>
            </MenuDropdown>
        </div>
    );
}

export default injectStores(['ApplicationStore'])(LanguageSwitcher);
