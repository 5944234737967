import React, {
    Component,
    ComponentProps,
    MouseEvent,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { generatePath, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { injectStores } from '../../../../stores/factories/store-utils';
import { TypeApplicationStore } from '../../../../stores/application-store';

import {
    getHardPathTarget,
    injectLanguageSlug,
    isExternalLink,
    isHttpLink,
    isJakhub,
    isNonHttpProtocol,
} from '../../../../utils/utils';
import classNames from 'classnames';
import { ModuleId, ModuleRoute, TargetType } from '../../../../modules/utils/module-interfaces';
import { MODULES } from '../../../../modules/modules';
import { pathSelector } from '../../../../modules/utils/module-utils';
import { JakhubTranslation, useJakhubTranslation } from '../../../../i18n/jakhub-translation';
import { HEADER_TOOLS, I18N_MARKER, TEST_ENVIRONMENT } from '../../../../constants';
import { featureFlags } from '../../../../utils/feature-flags';
import { scrollToAnchor } from '../../../../modules/utils/anchor-utils';
import PopupLinkContainer from '../../../common/switch-link-popup/switch-link-popup';

export type SwitchLinkToUrl = string;
export type SwitchLinkToTarget<M extends ModuleId> = TargetType<M>;
export type SwitchLinkToType<M extends ModuleId> = SwitchLinkToUrl | SwitchLinkToTarget<M>;

type AltLinkProps = {
    onClick: () => void;
    to: string;
    alsoGoTo: string;
    linkClass: string;
    akTestId: string;
} & PropsWithChildren<{}> &
    ComponentProps<'div'>;
function LinkWithAlternate(props: AltLinkProps) {
    const { onClick, to, alsoGoTo, linkClass, style, akTestId, children } = props;

    const [timeoutId, setTimeoutId] = useState<number | undefined>();

    const specialClick = () => {
        onClick();

        setTimeoutId(
            window.setTimeout(function () {
                document.location = alsoGoTo;
            }, 0)
        );
        document.location = to;
    };

    useEffect(() => {
        return window.clearTimeout(timeoutId);
    }, []);

    return (
        <a onClick={specialClick} className={linkClass} data-ak-test-id={akTestId} style={style}>
            {children}
        </a>
    );
}

type CommonProps = {
    ApplicationStore: TypeApplicationStore;
    akTestId: string;
    forceNewTab?: boolean;
    forceSameTab?: boolean;
    disabled?: boolean;
    className?: string;
} & PropsWithChildren<{}> &
    ComponentProps<'div'>;

type PopupProps = {
    popupId: string;
    component: Component;
} & CommonProps;

type LinkProps<M extends ModuleId> = {
    to: SwitchLinkToType<M>;
    includeLanguageSlug?: boolean;
    alsoGoTo?: SwitchLinkToType<M>;
    // from menulink
    i18nId?: string;
    onClick?: () => void;
} & CommonProps;

export function WrappedPopupLink(props: PopupProps) {
    const {
        ApplicationStore: { showToolInHeader },
        children,
        popupId,
        component,
        className,
        disabled = false,
    } = props;

    const linkClass = className
        ? className
        : classNames('ak-link', { 'ak-link__disabled': disabled });

    if (disabled) {
        return <div className={linkClass}>{children}</div>;
    }

    return (
        <div
            // @ts-ignore
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                showToolInHeader?.(HEADER_TOOLS.SWITCH_LINK_POPUP, popupId);
            }}
        >
            {children}
            <PopupLinkContainer id={popupId}>{component}</PopupLinkContainer>
        </div>
    );
}
export const PopupLink = injectStores(['ApplicationStore'])(WrappedPopupLink);

function SwitchLink<M extends ModuleId>(props: LinkProps<M>) {
    const {
        ApplicationStore: {
            backendEnvironmentId,
            closeMobileMenu,
            hideAllHeaderTools,
            userLanguage,
        },
        akTestId,
        children: explicitChildren,
        className,
        to,
        alsoGoTo,

        i18nId,
        includeLanguageSlug = true,
        forceNewTab = false,
        forceSameTab = false,
        disabled = false,
        onClick,
        style,
    } = props;

    const closeOpenDropdown = () => {
        hideAllHeaderTools?.();
        closeMobileMenu?.();
    };

    // --------------------- MENULINK START -------------
    const { tt } = useJakhubTranslation();
    const linkClass = className
        ? className
        : classNames('ak-link', { 'ak-link__disabled': disabled });

    let children = explicitChildren;

    let path;

    if (disabled) {
        return <div className={linkClass}>{children}</div>;
    }

    if (typeof to === 'object') {
        const { params = {}, locationSearch = '' } = { ...to };
        const route = getRoute(to);

        const { i18nId: routeI18nId, action } = route;

        // Use action before path/hardPath
        if (action) {
            return (
                <a
                    onClick={(e) => {
                        e.stopPropagation();
                        action(params);
                    }}
                    className={linkClass}
                    target='_blank'
                    rel='noreferrer'
                    data-ak-test-id={akTestId}
                    style={style}
                >
                    {children}
                </a>
            );
        }

        path = getUrlString(route, tt);
        if (locationSearch) {
            path = `${path}?${locationSearch}`.replace('??', '?');
        }

        if (!children) {
            children = (
                <RouteLabel
                    specialClass={
                        backendEnvironmentId === TEST_ENVIRONMENT
                            ? 'red-logo-for-test-environment'
                            : ''
                    }
                    useThisUrlString={path}
                    route={{ ...route, i18nId: i18nId || routeI18nId }}
                />
            );
        }
    } else {
        path = to;
    }
    // --------------------- MENULINK END -------------
    if (isNonHttpProtocol(path)) {
        if (alsoGoTo) {
            return (
                <LinkWithAlternate
                    onClick={closeOpenDropdown}
                    to={to as string}
                    alsoGoTo={alsoGoTo as string}
                    linkClass={linkClass}
                    akTestId={akTestId}
                    style={style}
                >
                    {children}
                </LinkWithAlternate>
            );
        }

        return (
            <a
                onClick={closeOpenDropdown}
                className={linkClass}
                href={path}
                target='_blank'
                rel='noreferrer'
                data-ak-test-id={akTestId}
                style={style}
            >
                {children}
            </a>
        );
    }

    if (typeof to !== 'string') {
        const { pathHash = null } = { ...to?.params };
        if (pathHash) {
            path = `${path}#${pathHash}`;
        }
    }

    const pathToUse =
        isJakhub() || isHttpLink(path)
            ? injectLanguageSlug(path, userLanguage, includeLanguageSlug)
            : `${getHardPathTarget()}/${userLanguage}${path}`;
    const pathWithParam =
        typeof to !== 'string' && to?.params ? generatePath(pathToUse, to?.params) : pathToUse;

    if (path.includes('#') && !isHttpLink(path)) {
        return (
            <HashLink
                scroll={(el) => scrollToAnchor(el, -40)}
                onClick={closeOpenDropdown}
                className={linkClass}
                to={path.startsWith('#') ? path : pathWithParam}
            >
                {children}
            </HashLink>
        );
    }

    const aLinkTarget =
        (isExternalLink(pathWithParam) || forceNewTab) && !forceSameTab ? '_blank' : '_self';
    return isHttpLink(pathWithParam) || forceNewTab || forceSameTab ? (
        <a
            onClick={() => {
                closeOpenDropdown();
                onClick && onClick();
            }}
            className={linkClass}
            href={pathWithParam}
            target={aLinkTarget}
            rel='noreferrer'
            data-ak-test-id={akTestId}
            style={style}
        >
            {children}
        </a>
    ) : (
        <Link
            onClick={() => {
                closeOpenDropdown();
                onClick && onClick();
            }}
            className={linkClass}
            to={pathWithParam}
            data-ak-test-id={akTestId}
            style={style}
        >
            {children}
        </Link>
    );
}

function getUrlString(route: ModuleRoute, tt: JakhubTranslation['tt']) {
    // Use hardPath before path
    const useThisUrl = pathSelector(route);

    /* TODO - http://team.audiokinetic.inte:8080/jira/browse/HUB-181
        Make the hardPath suffix environment sensitive and remove it from production
    */
    // Is path a direct string or a localized object
    return useThisUrl.substring(0, I18N_MARKER.length) === I18N_MARKER
        ? tt(useThisUrl.substring(I18N_MARKER.length))
        : useThisUrl;
}

function getRoute<M extends ModuleId>(to: TargetType<M>) {
    const { module, subModule } = to;

    const routes = MODULES[module]?.routes;
    // This is a limitation of Typescript, it can't narrow target.module to just one of ModuleId here
    const route: ModuleRoute = routes?.[subModule as keyof typeof routes];

    if (!route) {
        // Keep this console.log to help debug when app fails on new route additions
        console.error('module:  ', module, '  |  ', subModule);
    }
    return route;
}

type RouteLabelProps = {
    route: ModuleRoute;
    useThisUrlString: string;
    specialClass: string;
};
function RouteLabel({ route, useThisUrlString, specialClass }: RouteLabelProps) {
    const { tt } = useJakhubTranslation();

    const { i18nId, IconComponent, linkClass } = route;

    const hardPathSuffix =
        isHttpLink(useThisUrlString) && featureFlags.FCRM_LINK_SUFFIX ? ' >' : '';

    return (
        (IconComponent && (
            <div className={`${linkClass} ${specialClass}`}>
                <IconComponent />
            </div>
        )) || <div>{`${tt(i18nId)}${hardPathSuffix}`}</div>
    );
}

// export default injectStores(['ApplicationStore'])(SwitchLink);
// export default SwitchLink;
export default injectStores(['ApplicationStore'])(SwitchLink) as <M extends ModuleId>(
    props: Omit<LinkProps<M>, 'ApplicationStore'>
) => ReactElement;
