import { typedRoutes } from './utils/module-interfaces';

import { PATHS } from './soft-paths/documentation';

export const ROUTES = typedRoutes({
    DOCUMENTATION: {
        ...PATHS.DOCUMENTATION,
        id: 'DOCUMENTATION',
        name: 'Documentation',
        i18nBase: 'documentation:module',
        i18nId: 'documentation:module.menu-label',
        displayInMenu: false,
    },
    WWISE: {
        ...PATHS.WWISE,
        id: 'WWISE',
        name: 'WWISE',
        i18nBase: 'documentation:wwise',
        i18nId: 'documentation:wwise.menu-label',
    },
    STRATA: {
        ...PATHS.STRATA,
        id: 'STRATA',
        name: 'STRATA',
        i18nBase: 'documentation:strata',
        i18nId: 'documentation:strata.menu-label',
    },
    AK_LAUNCHER: {
        ...PATHS.AK_LAUNCHER,
        id: 'AK_LAUNCHER',
        name: 'AK_LAUNCHER',
        i18nBase: 'documentation:ak-launcher',
        i18nId: 'documentation:ak-launcher.menu-label',
    },
});

export const DEFAULT_ROUTE = ROUTES.DOCUMENTATION;
