import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = `/thank-you`;

export const PATHS = {
    THANK_YOU: {
        path: `${moduleRoot}/:thanksRef`,
    },
    WWISE_LAUNCHER: {
        path: `${moduleRoot}/launcher/:platform`,
    },
    LAUNCHER_PROJECTS: {
        path: 'NO JAKUB POSSIBLE URL',
        hardPath: HARD_PATHS.GENERAL.PROJECTS_LAUNCHER,
    },
};
