import { Instance, types } from 'mobx-state-tree';

import { TypedStoreConfig } from '../factories/store';
import { MESSAGE_TYPES } from '../../constants';

const UserAccountMessageBaseModel = types.model('UserAccountMessageBaseModel', {
    UUID: types.string,
    subject: types.string,
    sender: types.string,
    senderIcon: types.string,
    receivedDate: types.string,
    additionInformationUrl: types.maybe(types.string),
});
export type TypeUserAccountMessageBaseModel = Instance<typeof UserAccountMessageBaseModel>;

export const UserAccountFullMessageModel = types.compose(
    UserAccountMessageBaseModel,
    types.model('UserAccountFullMessageModel', {
        content: types.string,
        messageType: types.enumeration<typeof MESSAGE_TYPES[keyof typeof MESSAGE_TYPES]>(
            Object.values(MESSAGE_TYPES)
        ),
        token: types.string,
    })
);
export type TypeUserAccountFullMessageModel = Instance<typeof UserAccountFullMessageModel>;

export const UserAccountFullMessageArrayModel = TypedStoreConfig({
    modelName: 'UserAccountFullMessageArrayModel',
    properties: {
        messages: types.array(UserAccountFullMessageModel),
    },
});

export const UserAccountSummaryMessageModel = types.compose(
    UserAccountMessageBaseModel,
    types.model('UserAccountSummaryMessageModel', {
        contentExcerpt: types.string,
        read: types.boolean,
    })
);
export type TypeUserAccountSummaryMessageModel = Instance<typeof UserAccountSummaryMessageModel>;

export const UserAccountMessagesModel = TypedStoreConfig({
    modelName: 'UserAccountMessagesModel',
    properties: {
        totalMessages: types.maybeNull(types.number),
        emails: types.array(UserAccountSummaryMessageModel),
    },
});

export type TypeUserAccountMessagesModel = Instance<typeof UserAccountMessagesModel>;
export default UserAccountMessagesModel;
