/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';

import { filter } from 'lodash';

import { createApiAction } from '../../api/api-action-factory';
import { TypedStoreConfig } from '../factories/store';

import { INTEGRATIONS_ENDPOINT } from '../../api/endpoints';

import IntegrationModel from './models/integration-model';

import { isDev } from '../../utils/build-utils';

const IntegrationStore = TypedStoreConfig({
    modelName: 'IntegrationStore',
    properties: {
        integrations: types.maybeNull(types.array(IntegrationModel)),
    },
})
    .actions((self) => ({
        getIntegrations: createApiAction(self, {
            endpoint: INTEGRATIONS_ENDPOINT,
            target: 'integrations',
        }),
    }))
    .actions((self) => ({
        retrieveIntegrations() {
            if (!self.integrations) {
                self.getIntegrations();
            }
        },
    }))
    .views((self) => ({
        getSupportedIntegrations() {
            self.retrieveIntegrations();
            return filter(self.integrations, (integration) => integration.iconUrl !== null);
        },
    }));

export type TypeIntegrationStore = Instance<typeof IntegrationStore>;

const IntegrationStoreInstance = IntegrationStore.create();
declare const window: Window &
    typeof globalThis & {
        IntegrationStore: typeof IntegrationStoreInstance;
    };

if (window && isDev()) {
    window.IntegrationStore = IntegrationStoreInstance;
}

export default IntegrationStore;
