import React, { lazy, Suspense, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { map } from 'lodash';
import cx from 'classnames';

import { useJakhubTranslation } from '../../../i18n/jakhub-translation';
import { injectStores } from '../../../stores/factories/store-utils';
import { TypeProfileStore } from '../../../stores/profile-store';

import CircularProgress from '@material-ui/core/CircularProgress';
import StyledContent from '../../common/styled-content/styled-content';
import SwitchLink from '../../menus/menu/switch-link/switch-link';

import styles from './settings.module.scss';
import '../../menus/header/header.scss';

export interface OptionParams {
    optionId: string;
}

interface SelectedComponentProps {
    optionId?: string;
    userIsVerified?: boolean;
}

interface LeftMenuOptionsProps extends SelectedComponentProps {
    ProfileStore: TypeProfileStore;
    options: string[];
    isTopMenu?: boolean;
}

function WrappedLeftMenuOptions(props: LeftMenuOptionsProps) {
    const { t } = useJakhubTranslation();
    const {
        ProfileStore: {
            userAccount: {
                profile: { isVerified },
            },
            getUserAccount,
            userIsLoggedIn,
        },
        optionId = '',
        options,
        isTopMenu = false,
    } = props;

    useEffect(() => {
        if (userIsLoggedIn) getUserAccount('FULL');
    }, [userIsLoggedIn]);

    if (optionId === '') return null;

    return (
        <>
            {map(options, (option) => {
                const linkClass = isTopMenu
                    ? cx('link', { link__selected: option === optionId })
                    : cx(
                          styles.leftMenuLink,
                          option === optionId ? styles.leftMenuLinkSelected : ''
                      );
                return !isVerified && option !== 'general' ? (
                    <div key={option} className={styles.lockedOption}>
                        <i className='material-icons-outlined'>lock</i>
                        <div>{t(`settings:${option}.menu-label`)}</div>
                    </div>
                ) : (
                    <SwitchLink
                        key={option}
                        to={`/settings/account-settings/${option}`}
                        akTestId={`settingsOption_${option}`}
                        className={linkClass}
                    >
                        {t(`settings:${option}.menu-label`)}
                    </SwitchLink>
                );
            })}
        </>
    );
}
export const LeftMenuOptions = injectStores(['ProfileStore'])(WrappedLeftMenuOptions);

export function SettingsTopMenu() {
    const { tt } = useJakhubTranslation();
    const { optionId = 'general' } = useParams<OptionParams>();

    // Duplicated sub-menu structure here to render like sub-menu but
    // without adding unnecessary complexity in an already complexe component
    return (
        <div className={`sub-header ${styles.nonMobileHide}`}>
            <div className='sub-menu'>
                <div className='modnav-main'>
                    <div className='modnav-main-sub-link'>
                        <SwitchLink
                            key='MAIN|GENERAL'
                            to='/settings/account-settings/general'
                            akTestId='settingsMAinOption_general'
                            className='modnav-main-sub-link-parent modnav-main-sub-link-parent__active'
                        >
                            {tt('settings:module.title')}
                        </SwitchLink>
                        <LeftMenuOptions
                            optionId={optionId}
                            options={[
                                'general',
                                'communications',
                                'creators-directory',
                                'certifications',
                                'downloads',
                                'documents',
                                'billing',
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function LeftMenu(props: SelectedComponentProps) {
    const { optionId = '' } = props;
    return (
        <div className={styles.leftMenu}>
            <LeftMenuOptions
                optionId={optionId}
                options={['general', 'communications', 'creators-directory']}
            />
            <div className={styles.separator} />
            <LeftMenuOptions
                optionId={optionId}
                options={['certifications', 'downloads', 'documents', 'billing']}
            />
        </div>
    );
}

function SelectedComponent(props: SelectedComponentProps) {
    const { optionId = '', userIsVerified } = props;

    if (optionId === '') return null;

    const Component = lazy(() => import(`./${optionId}/${optionId}`));

    return (
        <Suspense
            fallback={
                <div className={styles.waiting}>
                    <CircularProgress />
                </div>
            }
        >
            <Component userIsVerified={userIsVerified} />
        </Suspense>
    );
}

type Props = {
    ProfileStore: TypeProfileStore;
};
function AccountSettings(props: Props) {
    const { tt } = useJakhubTranslation();
    const {
        ProfileStore: {
            userAccount: {
                profile: { isVerified, userId },
            },
        },
    } = props;

    const { optionId = 'general' } = useParams<OptionParams>();

    if (!userId) return null;

    return (
        <StyledContent contentStyle='PAGE'>
            <div className={styles.settings}>
                <div className={styles.settingsTitle}>{tt('settings:module.title')}</div>
                <div className={styles.content}>
                    <LeftMenu optionId={optionId} />
                    <SelectedComponent optionId={optionId} userIsVerified={isVerified} />
                </div>
            </div>
        </StyledContent>
    );
}

export default injectStores(['ProfileStore'])(AccountSettings);
