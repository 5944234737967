import { Instance, types } from 'mobx-state-tree';

const IntegrationModel = types.model('IntegrationModel', {
    id: types.union(types.number, types.string),
    name: types.string,
    iconUrl: types.string,
    url: types.maybeNull(types.string),
    position: types.maybeNull(types.number),
});

export type TypeIntegrationModel = Instance<typeof IntegrationModel>;

export default IntegrationModel;
