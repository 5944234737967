const moduleRoot = `/purchasing-terms-conditions`;

export const PATHS = {
    PURCHASING_TERMS: {
        path: `${moduleRoot}`,
    },
    PURCHASING_TERMS_REGION: {
        path: `${moduleRoot}/:region`,
    },
};
