/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

import ApiActionFactory from '../../api/api-action-factory';
import StoreFactory from '../factories/store';

import ExternalInformationModel from './models/external-information-model';

import { isDev } from '../../utils/build-utils';

import {
    STRATA_HOW_TO_VIDEOS_STATIC_ENDPOINT,
    YOUTUBE_CATEGORIES,
    YOUTUBE_VIDEOS_ENDPOINT,
} from '../../api/endpoints';

const YoutubeStore = StoreFactory({
    modelName: 'YoutubeStore',
    models: [
        ApiActionFactory({
            getYoutubeVideos: {
                endpoint: YOUTUBE_VIDEOS_ENDPOINT,
                target: 'youtubeVideos',
                payloadDataLocationId: 'videos',
            },
            getStrataHowToVideos: {
                endpoint: STRATA_HOW_TO_VIDEOS_STATIC_ENDPOINT,
                target: 'strataHowToVideos',
                payloadDataLocationId: 'videos',
            },
            getYoutubeCategories: {
                endpoint: YOUTUBE_CATEGORIES,
                target: 'youtubeCategories',
            },
        }),
    ],
    modelStructure: {
        isLoading: false,
        youtubeVideos: types.array(ExternalInformationModel),
        strataHowToVideos: types.array(ExternalInformationModel),
        youtubeCategories: types.frozen({}),
    },
}).actions((self) => ({
    buildVideoCategoryCollection() {
        self.youtubeCategories = {};
        // self.youtubeCategories.put({
        //     id: 'all',
        //     categoryName: 'all',
        //     active: true,
        // });
        // each(self.youtubeVideos, video => {
        //     // TODO: remove after the video URL is updated
        //     video.videoUrl = 'https://www.youtube.com/embed/5qap5aO4i9A';
        //
        //     each(video.tags, tag => {
        //         const cleanId = `video_${ toCamelCase(tag) }`;
        //         // video.tagsId.push(cleanId);
        //         self.youtubeCategories.put({
        //             id: cleanId,
        //             categoryName: tag,
        //             i18nId: `old_${ tag }`,
        //             active: true,
        //         });
        //     });
        // });
    },
}));

const YoutubeStoreInstance = YoutubeStore.create();
declare const window: Window &
    typeof globalThis & {
        YoutubeStore: typeof YoutubeStoreInstance;
    };

if (window && isDev()) {
    window.YoutubeStore = YoutubeStoreInstance;
}

export default YoutubeStore;
