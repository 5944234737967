import React from 'react';

import { useLocation } from 'react-router-dom';
import { injectStores } from '../../../stores/factories/store-utils';
import { HeaderProps } from './utils/header-interfaces';

import { SettingsTopMenu } from '../../modules/account-settings/account-settings';
import ContactForms from '../../common/contact-forms/contact-forms';
import DivButton from '../../common/legos/div-button/div-button';
import EmployeeAdminMenu from '../menu/employee-admin-menu/employee-admin-menu';
import HeaderHelmet, { locationToModuleId, countSubModules } from './header-helmet';
import HeaderTools from './utils/header-tools';
import SpecialMenuMessage from '../../common/menu-message/special-menu-message';
import UnverifiedUserMenuMessage from '../../common/menu-message/unverified-user-menu-message';
import MainMenu from '../menu/main-menu/main-menu';
import MobileMenu from '../menu/mobile-menu/mobile-menu';
import PortalDrawer from '../../common/portal-drawer/portal-drawer';
import SubMenu from '../menu/main-menu/sub-menu';

import './header.scss';

function Header(props: HeaderProps) {
    const {
        ApplicationStore: {
            headerTools: { mobileMenuOpened },
            toggleMobileMenu,
        },
    } = props;
    const { pathname } = useLocation();

    const menuState = locationToModuleId(pathname);
    const showSubMenu = countSubModules(menuState.currentModuleId) > 0;
    return (
        <header id='jakhub-menu-header' className='jakhub-menu-header'>
            <HeaderHelmet />
            <EmployeeAdminMenu />
            <div className='header-main'>
                <div className='header-main-content'>
                    <DivButton className='hamburger' onClick={toggleMobileMenu}>
                        <i className='material-icons'>{!mobileMenuOpened ? 'menu' : 'close'}</i>
                    </DivButton>
                    <MainMenu menuState={menuState} />
                    <HeaderTools />
                </div>
            </div>
            {showSubMenu ? <SubMenu menuState={menuState} /> : null}
            {menuState.currentModuleId === 'SETTINGS' ? <SettingsTopMenu /> : null}
            <SpecialMenuMessage />
            <UnverifiedUserMenuMessage />
            <MobileMenu menuState={menuState} />
            <PortalDrawer />
            <ContactForms />
        </header>
    );
}

export default injectStores(['ApplicationStore'])(Header);
