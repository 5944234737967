import { Instance, types } from 'mobx-state-tree';

const AdministratorModel = types.model('AdministratorModel', {
    email: types.optional(types.string, 'admin email'),
    password: types.optional(types.string, 'admin pw'),
});

const SchoolModel = types.model('SchoolModel', {
    name: types.optional(types.string, ''),
    type: types.optional(types.string, ''),
    logo: types.optional(types.string, ''),
    phone: types.optional(types.string, ''),
    website: types.optional(types.string, ''),
});

const FacultyModel = types.model('FacultyModel', {
    name: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    website: types.optional(types.string, ''),
    phone: types.optional(types.string, ''),
});

const AddressModel = types.model('AddressModel', {
    streetAddress: types.optional(types.string, ''),
    city: types.optional(types.string, ''),
    regionCode: types.optional(types.string, ''),
    countryCode: types.optional(types.string, ''),
    zip: types.optional(types.string, ''),
});

// const LicensingInterestModel = types.model('LicensingInterestModel', {
//     strata: types.optional(types.boolean, false),
//     wwise: types.optional(types.boolean, false),
// });

// const LicenseAgreementModel = types.model('LicenseAgreementModel', {
//     agree: types.optional(types.boolean, false),
// });

export const SchoolCreationModel = types.model('SchoolCreationModel', {
    admin: types.optional(AdministratorModel, {}),
    school: types.optional(SchoolModel, {}),
    address: types.optional(AddressModel, {}),
    faculty: types.optional(FacultyModel, {}),

    // administrator: types.optional(AdministratorModel, {}),
    // school: types.optional(SchoolModel, {}),
    // faculty: types.optional(FacultyModel, {}),
    // interests: types.optional(LicensingInterestModel, {}),
    // licenseAgreement: types.optional(LicenseAgreementModel, {}),
});

export type TypeSchoolCreationModel = Instance<typeof SchoolCreationModel>;
export default SchoolCreationModel;
