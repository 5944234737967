import HttpBackend from 'i18next-http-backend';

import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { supportedLocales } from './supported-locales';
import { locales } from './locales/index';

export const loadI18n = (initialLocale: string, callThisWhenDone: () => void): void => {
    use(HttpBackend)
        .use(initReactI18next)
        .init(
            {
                lng: initialLocale,
                load: 'currentOnly',
                supportedLngs: supportedLocales,
                fallbackLng: [initialLocale],
                debug: true,
                preload: [initialLocale],
                interpolation: {
                    escapeValue: false,
                },
                resources: locales,
                ns: Object.keys(locales['en-US']),
                defaultNS: 'common',
                react: {
                    useSuspense: true,
                },
            },
            () => {
                callThisWhenDone();
            }
        );
};

type Vals = typeof locales[keyof typeof locales];

type ValueAtPath<K, T> = K extends `${infer K1}${'.'}${infer K2}`
    ? K1 extends keyof T
        ? ValueAtPath<K2, T[K1]>
        : never
    : K extends keyof T
    ? T[K]
    : never;

export type TranslationAtKey<K> = K extends keyof Vals
    ? Vals[K]
    : K extends `${infer K1}${':'}${infer K2}`
    ? K1 extends keyof Vals
        ? ValueAtPath<K2, Vals[K1]>
        : never
    : ValueAtPath<K, Vals['common']>;

export type Namespace = keyof Vals;
