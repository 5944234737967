import { Instance, types } from 'mobx-state-tree';
import { TypedStoreConfig } from '../factories/store';
import { createApiAction } from '../../api/api-action-factory';

import { UPCOMING_EVENTS_ENDPOINT, UPCOMING_EVENTS_INFO_ENDPOINT } from '../../api/endpoints';

import TrainingEventModel from './models/training-events-model';

const UpcomingTrainingEventsStore = TypedStoreConfig({
    modelName: 'UpcomingTrainingEventsStore',
    properties: {
        eventInfo: types.frozen({}),
        upcomingEvents: types.array(TrainingEventModel),
    },
})
    .actions((self) => ({
        getUpcomingEvents: createApiAction(self, {
            endpoint: UPCOMING_EVENTS_ENDPOINT,
            target: 'upcomingEvents',
            payloadDataLocationId: 'events',
        }),
        getUpcomingEventInfo: createApiAction(self, {
            endpoint: UPCOMING_EVENTS_INFO_ENDPOINT,
            target: 'eventInfo',
        }),
    }))
    .views((self) => ({
        retrieveUpcomingEventByTag(tag: string) {
            return self.upcomingEvents?.filter((event) => event.tags?.includes(tag.toUpperCase()));
        },
    }));

export type TypeUpcomingTrainingEventsStore = Instance<typeof UpcomingTrainingEventsStore>;

export default UpcomingTrainingEventsStore;
