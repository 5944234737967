import { Instance, types } from 'mobx-state-tree';

export const NewsModel = types.model('NewsModel', {
    title: '',
    description: '',
    cta_link: '',
    cta_label: '',
    image: '',
});

export type TypeNewsModel = Instance<typeof NewsModel>;

export default NewsModel;
