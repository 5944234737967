import React, { MouseEvent, PropsWithChildren } from 'react';

import './div-button.scss';
import cx from 'classnames';

interface Props {
    className?: string;
    onClick?(event: MouseEvent): void;
    akTestId?: string;
    id?: string;
    style?: any;
}
function DivButton(props: PropsWithChildren<Props>) {
    const { children = null, style = {}, className = '', onClick, id } = props;
    const buttonClass = cx('div-button', className);
    return (
        <div
            style={style}
            id={id}
            role='button'
            tabIndex={-1}
            className={buttonClass}
            onClick={(event: MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                onClick?.(event);
            }}
        >
            {children}
        </div>
    );
}

export default DivButton;
