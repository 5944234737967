import { Instance, types } from 'mobx-state-tree';

export const PasswordChangeModel = types.model('PasswordChangeModel', {
    status: types.maybeNull(types.string),
    messages: types.maybeNull(types.array(types.string)),
    random: types.maybeNull(types.string),
});

export type TypePasswordChangeModel = Instance<typeof PasswordChangeModel>;
export default PasswordChangeModel;
