/* eslint-disable no-param-reassign */
/* eslint-disable no-console */

import { ModelActions, flow, types, IModelType } from 'mobx-state-tree';
import { isUndefined, mapValues } from 'lodash';

import { Endpoint } from './api-interfaces';
import { fetchData } from './handle-requests';

interface EndPointConfig {
    endpoint: Endpoint;
    target: string;
    targetMapId?: string;
    payloadDataLocationId?: string;
    getServerDate?: boolean;
    onTrigger?(self: any): void;
    onLoad?(self: any, config: any): void;
    onError?(self: any, err: any): void;
}

type BaseStoreType = {
    isLoading?: boolean;
    loadingCounter?: number;
    serverDate?: string;
};

interface TypedEndpointConfig<T> extends Omit<EndPointConfig, 'target'> {
    target: keyof T | '' | 'target_set_in_calling_function';
    formParams?: any;
    payload?: any;
}

function getFlow<T extends BaseStoreType>(self: T, endpointConfig: TypedEndpointConfig<T>) {
    return flow(function* genericApi(params?: any) {
        try {
            const options = {};

            const {
                endpoint,
                target,
                targetMapId,
                payloadDataLocationId,
                onTrigger,
                onLoad,
                getServerDate = false,
            } = endpointConfig;

            const formParams = params || endpointConfig.formParams;
            const { targetPath = null } = { ...params };
            if (self.isLoading !== undefined) {
                self.isLoading = true;
            }

            if (self.loadingCounter !== undefined) {
                self.loadingCounter = self.loadingCounter + 1;
            }

            if (onTrigger) {
                onTrigger(self);
            }

            const response = yield fetchData({
                endpoint,
                formParams,
                ...options,
            });

            /* eslint-disable require-atomic-updates */
            if (getServerDate) {
                self.serverDate = response.resultHeaders.date;
            }

            const dataForSelf = payloadDataLocationId
                ? response.data?.[payloadDataLocationId] || response[payloadDataLocationId]
                : response.data ?? response;

            // @ts-ignore
            if (targetPath && self.callTracker) {
                // @ts-ignore
                self.callTracker = { ...self.callTracker, [targetPath]: 'success' };
            }

            // Todo HUB-1084 Put this block back to it's original state
            // Starts here
            let storeSelf = self;
            // @ts-ignore
            const targetAddress = targetPath ? targetPath?.split('.') : target.split('.');
            while (targetAddress.length > 1) {
                // @ts-ignore
                storeSelf = storeSelf[targetAddress.shift()];
            }

            if (targetMapId && typeof formParams === 'object') {
                // @ts-ignore
                (storeSelf[targetAddress.shift()] as any)?.set(
                    formParams[targetMapId],
                    dataForSelf
                );
            } else {
                const shiftedId = targetAddress.shift();
                // @ts-ignore
                const currentData = storeSelf[shiftedId] || {};

                // @ts-ignore
                storeSelf[shiftedId] =
                    typeof dataForSelf === 'object' && dataForSelf.length === undefined
                        ? { ...currentData, ...dataForSelf }
                        : dataForSelf;
            }

            /* eslint-enable require-atomic-updates */

            if (onLoad) {
                onLoad(self, formParams);
            }

            if (self.isLoading !== undefined) {
                self.isLoading = false;
            }

            if (self.loadingCounter !== undefined) {
                self.loadingCounter = self.loadingCounter - 1;
            }
        } catch (error: any) {
            const { onError } = endpointConfig;

            const { targetPath = null, dataOnError = null } = { ...params };

            // @ts-ignore
            if (targetPath && self.callTracker) {
                // @ts-ignore
                self.callTracker = { ...self.callTracker, [targetPath]: 'error' };
            }

            if (targetPath && !isUndefined(dataOnError)) {
                let storeSelf = self;
                // @ts-ignore
                const targetAddress = targetPath?.split('.');
                while (targetAddress.length > 1) {
                    // @ts-ignore
                    storeSelf = storeSelf[targetAddress.shift()];
                }

                if (storeSelf) {
                    // @ts-ignore
                    storeSelf[targetAddress.shift()] = dataOnError;
                }
            }

            if (self.isLoading !== undefined) {
                self.isLoading = false;
            }

            if (self.loadingCounter !== undefined) {
                self.loadingCounter = self.loadingCounter - 1;
            }

            if (onError) {
                onError(self, error.err || error);
            }

            // @ts-ignore
            if (error.code === 401 && self?.userIsLoggedIn) {
                // @ts-ignore
                self.userLogout && self.userLogout();
            }

            console.error('Woopelay: ', error.err);
            console.log(' #=================================================#');
            console.log('||                                                 ||');
            console.log('||  Should probably put a popup or a toaster here  ||');
            console.log('||                                                 ||');
            console.log(' #=================================================#');
        }
    });
}

// Usage alongside SotreConfig is deprecated as type's are lost
const ApiActionFactory = (
    storeApi: Record<string, EndPointConfig>
): IModelType<{}, { [x: string]: Function }> =>
    types
        .model({})
        .actions((self: any) =>
            mapValues<Record<string, EndPointConfig>, ModelActions[string]>(
                storeApi,
                (endpointConfig: EndPointConfig) => getFlow(self, endpointConfig)
            )
        );

export default ApiActionFactory;

export function createApiAction<T extends BaseStoreType>(
    self: T,
    endpointConfig: TypedEndpointConfig<T>
) {
    /* eslint-disable-next-line max-statements */
    return getFlow(self, endpointConfig);
}
