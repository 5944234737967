import { Instance, types } from 'mobx-state-tree';

const FieldsModel = types.model('FieldsModel', {
    fieldName: types.string,
    isImage: types.boolean,
    isLink: types.boolean,
    isSortable: types.maybeNull(types.boolean),
    type: types.maybe(types.string),
});
export type TypeFieldsModel = Instance<typeof FieldsModel>;

const ServicesModel = types.model('ServicesModel', {
    en_US: types.optional(types.string, '', [undefined, null]),
    fr_CA: types.optional(types.string, '', [undefined, null]),
    ja_JP: types.optional(types.string, '', [undefined, null]),
    ko_KR: types.optional(types.string, '', [undefined, null]),
    zh_CN: types.optional(types.string, '', [undefined, null]),
});
export type TypeServices = Instance<typeof ServicesModel>;
export type TypeServicesProperties = Instance<typeof ServicesModel.properties>;

const CompaniesModel = types.model('CompaniesModel', {
    fields: types.array(FieldsModel),
    services: types.map(ServicesModel),
});
export type TypeCompaniesModel = Instance<typeof CompaniesModel>;

const SchoolsModel = types.model('SchoolsModel', {
    fields: types.array(FieldsModel),
});
export type TypeSchoolsModel = Instance<typeof SchoolsModel>;

const UsersModel = types.model('UsersModel', {
    fields: types.array(FieldsModel),
});
export type TypeUsersModel = Instance<typeof UsersModel>;

const CreatorsDirectoryInfoModel = types.model('CreatorsDirectoryInfoModel', {
    companies: types.maybeNull(CompaniesModel),
    schools: types.maybeNull(SchoolsModel),
    users: types.maybeNull(UsersModel),
});

export type TypeCreatorsDirectoryInfoModel = Instance<typeof CreatorsDirectoryInfoModel>;
export type TypeCreatorsDirectoryInfoProperties = Instance<
    typeof CreatorsDirectoryInfoModel.properties
>;

export default CreatorsDirectoryInfoModel;
