import { Instance, types } from 'mobx-state-tree';

const PublicationModel = types.model('PublicationModel', {
    title: '',
    description: '',
    publishedDate: types.maybeNull(types.string),
    publicationUrl: '',
});

export type TypePublicationModel = Instance<typeof PublicationModel>;
export default PublicationModel;
