import { lazy } from 'react';

import { typedRoutes } from './utils/module-interfaces';

import {
    BiliBiliLogo,
    FacebookLogo,
    LinkedInLogo,
    NaverLogo,
    TwitchLogo,
    TwitterLogo,
    WeChatLogo,
    WeiboLogo,
    YouTubeLogo,
} from '../assets/icons/svgs';
import { SupportedLocales } from '../i18n/supported-locales';
import { PATHS } from './soft-paths/footer';

const { EN_US, FR_CA, JA_JP, KO_KR, ZH_CN } = SupportedLocales;

export const ROUTES = typedRoutes({
    ABOUT: {
        ...PATHS.ABOUT,
        id: 'ABOUT',
        name: 'About',
        i18nBase: 'about:about',
        i18nId: 'about:about.menu-label',
        component: lazy(() => import('../components/modules/about/about')),
    },
    PRESS_ROOM: {
        ...PATHS.PRESS_ROOM,
        id: 'PRESS_ROOM',
        name: 'Press Room',
        i18nBase: 'about:press-room',
        i18nId: 'about:press-room.menu-label',
        component: lazy(() => import('../components/modules/about/press-room/press-room')),
    },
    NEWS: {
        ...PATHS.NEWS,
        id: 'NEWS',
        name: 'News',
        i18nBase: 'about:news',
        i18nId: 'about:news.menu-label',
        displayInFooter: false,
        component: lazy(() => import('../components/modules/about/news/news')),
    },
    NEWS_PAGE: {
        ...PATHS.NEWS_PAGE,
        id: 'NEWS_PAGE',
        name: 'NewsPage',
        i18nBase: 'about:news',
        i18nId: 'about:news.menu-label',
        displayInFooter: false,
        component: lazy(() => import('../components/modules/about/news/news')),
    },
    CAREERS: {
        ...PATHS.CAREERS,
        id: 'CAREERS',
        name: 'Careers',
        i18nBase: 'footer:module.careers',
        i18nId: 'footer:module.careers.menu-label',
    },
    CONTACTS: {
        ...PATHS.CONTACTS,
        id: 'CONTACTS',
        name: 'Contact',
        i18nBase: 'footer:module.contact',
        i18nId: 'footer:module.contact.menu-label',
        component: lazy(() => import('../components/modules/about/contacts/contacts')),
    },
    PARTNERS: {
        ...PATHS.PARTNERS,
        id: 'PARTNERS',
        name: 'Partners',
        i18nBase: 'footer:module.partners',
        i18nId: 'footer:module.partners.menu-label',
    },
    BILIBILI: {
        ...PATHS.BILIBILI,
        id: 'BILIBILI',
        name: 'bili bili',
        IconComponent: BiliBiliLogo,
        i18nBase: 'footer:module.bilibili',
        i18nId: 'footer:module.bilibili.menu-label',
        supportedLocales: [ZH_CN],
    },
    FACEBOOK: {
        ...PATHS.FACEBOOK,
        id: 'FACEBOOK',
        name: 'Facebook',
        IconComponent: FacebookLogo,
        i18nBase: 'footer:module.facebook',
        i18nId: 'footer:module.facebook.menu-label',
        supportedLocales: [FR_CA, EN_US, JA_JP, KO_KR],
    },
    NAVER: {
        ...PATHS.NAVER,
        id: 'NAVER',
        name: 'Naver',
        IconComponent: NaverLogo,
        i18nBase: 'footer:module.naver',
        i18nId: 'footer:module.naver.menu-label',
        supportedLocales: [KO_KR],
    },
    TWITCH: {
        ...PATHS.TWITCH,
        id: 'TWITCH',
        name: 'Twitch',
        IconComponent: TwitchLogo,
        i18nBase: 'footer:module.twitch',
        i18nId: 'footer:module.twitch.menu-label',
        supportedLocales: [FR_CA, EN_US, JA_JP, KO_KR],
    },
    TWITTER: {
        ...PATHS.TWITTER,
        id: 'TWITTER',
        name: 'Twitter',
        IconComponent: TwitterLogo,
        i18nBase: 'footer:module.twitter',
        i18nId: 'footer:module.twitter.menu-label',
        supportedLocales: [FR_CA, EN_US, JA_JP, KO_KR],
    },
    LINKEDIN: {
        ...PATHS.LINKEDIN,
        id: 'LINKEDIN',
        name: 'LinkedIn',
        IconComponent: LinkedInLogo,
        i18nBase: 'footer:module.linkedin',
        i18nId: 'footer:module.linkedin.menu-label',
        supportedLocales: [FR_CA, EN_US, JA_JP, KO_KR],
    },
    WECHAT: {
        ...PATHS.WECHAT,
        id: 'WECHAT',
        name: 'WeChat',
        IconComponent: WeChatLogo,
        i18nBase: 'footer:module.wechat',
        i18nId: 'footer:module.wechat.menu-label',
        supportedLocales: [ZH_CN],
    },
    WEIBO: {
        ...PATHS.WEIBO,
        id: 'WEIBO',
        name: 'Weibo',
        IconComponent: WeiboLogo,
        i18nBase: 'footer:module.weibo',
        i18nId: 'footer:module.weibo.menu-label',
        supportedLocales: [ZH_CN],
    },
    YOUTUBE: {
        ...PATHS.YOUTUBE,
        id: 'YOUTUBE',
        name: 'YouTube',
        IconComponent: YouTubeLogo,
        i18nBase: 'footer:module.youtube',
        i18nId: 'footer:module.youtube.menu-label',
        supportedLocales: [FR_CA, EN_US, JA_JP, KO_KR],
    },
});

export const DEFAULT_ROUTE = ROUTES.ABOUT;
