import React, { ComponentProps } from 'react';

import styles from './banner-video.module.scss';
import VideoPlayer from '../../video-player-modal/video-player';

interface Props extends ComponentProps<'div'> {
    videoUrl: string;
    controls?: boolean;
}
export default function BannerVideo(props: Props) {
    const { videoUrl, controls = true } = props;

    return (
        <div className={styles.bannerVideo}>
            <div className={styles.videoContainer}>
                <VideoPlayer url={videoUrl} autoPlay loop controls={controls} />
            </div>
        </div>
    );
}
