/* eslint-disable no-param-reassign */

import { each } from 'lodash';
import { ModelPropertiesDeclaration, types } from 'mobx-state-tree';

export default function StoreConfig(storeConfig: any) {
    const { modelName, models, modelStructure } = storeConfig;

    let baseModel = types.model();
    if (models) {
        each(models, (model) => {
            baseModel = types.compose(baseModel, model);
        });
    }

    return types
        .compose(baseModel, types.model({ ...modelStructure }))
        .actions((self) => ({
            setStoreAttribute(key: string, value: string | number | boolean) {
                if (self[key] !== undefined) {
                    self[key] = value;
                } else {
                    // eslint-disable-next-line no-console
                    console.warn('TRYING TO SET ILLEGAL KEY: ', key);
                }
            },
        }))
        .named(modelName);
}

type StoreConfigType<P> = {
    modelName?: string;
    properties: P;
};

// A type-fixed version of the above, but for fear of requiring a huge code change, made seperate.
export function TypedStoreConfig<P extends ModelPropertiesDeclaration = {}>(
    storeConfig: StoreConfigType<P>
) {
    const { modelName, properties } = storeConfig;

    const model = types
        .model({
            isLoading: false,
            serverDate: '',
            ...properties,
        })
        .actions((self) => ({
            setStoreAttribute(key: keyof typeof self, value: any) {
                if (self[key] !== undefined) {
                    self[key] = value;
                } else {
                    // eslint-disable-next-line no-console
                    console.warn('TRYING TO SET ILLEGAL KEY: ', key);
                }
            },
        }));

    return modelName ? model.named(modelName) : model;
}
