import React, { ComponentProps, ReactElement, useEffect } from 'react';

import classNames from 'classnames';

import DivButton from '../../common/legos/div-button/div-button';

import './menu-dropdown.scss';

export interface Props extends ComponentProps<'div'> {
    options?: { label: string; action: () => void }[];
    optionsComponents?: ReactElement;
    children: ReactElement;
    rightAlign?: boolean;
    expandRight?: boolean;
    expandLeft?: boolean;
    expandUp?: boolean;
    showOpenList: boolean;
    actionOpenList(): void;
    className?: string;
    optionsClassName?: string;
    fadeInDelay?: number;
    fadeOutDelay?: number;
}

function MenuDropdown(props: Props) {
    const {
        showOpenList,
        actionOpenList,
        children,
        optionsComponents,
        className = '',
        style,
        optionsClassName = '',
        fadeInDelay = 0,
        fadeOutDelay = 0,
        rightAlign = false,
        expandRight = false,
        expandLeft = false,
        expandUp = false,
    } = props;

    const [animateDropdown, setAnimateDropdown] = React.useState(false);
    useEffect(() => {
        setAnimateDropdown(showOpenList || animateDropdown);
    }, [showOpenList, animateDropdown, setAnimateDropdown]);

    const dropdownOptionsClass = classNames(`options ${optionsClassName}`, {
        'options__right-align': rightAlign,
        'options__expand-right': expandRight,
        'options__expand-left': expandLeft,
        'options__expand-up': expandUp,
        options__animated: animateDropdown,
        options__active: showOpenList && animateDropdown,
        options__expanded: showOpenList && fadeInDelay === 0,
    });
    const newDuration = `${showOpenList ? fadeInDelay : fadeOutDelay || fadeInDelay}ms`;

    return (
        <div className={`menu-dropdown ${className}`}>
            <DivButton
                className='btn'
                onClick={(event) => {
                    event.stopPropagation();
                    actionOpenList();
                }}
            >
                {children}
            </DivButton>
            <div
                className={dropdownOptionsClass}
                style={{
                    ...style,
                    animationDuration: newDuration,
                }}
            >
                {optionsComponents}
            </div>
        </div>
    );
}

export default MenuDropdown;
