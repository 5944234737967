import { Instance, types } from 'mobx-state-tree';

const GameTag = types.model('GameTag', {
    en_US: types.optional(types.string, '', [undefined, null]),
    fr_CA: types.optional(types.string, '', [undefined, null]),
    ja_JP: types.optional(types.string, '', [undefined, null]),
    ko_KR: types.optional(types.string, '', [undefined, null]),
    zh_CN: types.optional(types.string, '', [undefined, null]),
});
const GameEngines = types.model('GameEngines', {
    id: types.integer,
    name: types.string,
});
export const PoweredByWwiseInfoModel = types.model('PoweredByWwiseInfoModel', {
    gameTags: types.map(GameTag),
    gameEngines: types.array(GameEngines),
});

export type TypePoweredByWwiseInfoModel = Instance<typeof PoweredByWwiseInfoModel>;

export default PoweredByWwiseInfoModel;
