/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent, ReactNode } from 'react';

import { ThemeProvider, createTheme } from '@material-ui/core';

import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import colors from '../../../styles/legos/colors.module.scss';

const { akCyan, akIron } = colors;

interface Props extends CheckboxProps {
    label?: string | ReactNode;
}

function CheckBox(props: Props) {
    const {
        label = '',
        indeterminate,
        checked = false,
        onClick = () => null,
        onChange = () => null,
        name,
        size = 'medium',
        disabled,
    } = props;

    const fontSizes = {
        small: 12,
        medium: 16,
        large: 20,
    };
    const theme = createTheme({
        palette: {
            secondary: {
                main: akCyan,
            },
        },
        overrides: {
            MuiCheckbox: {
                root: {
                    padding: '0 4px 0 12px',
                },
            },
            MuiFormControlLabel: {
                root: {
                    alignItems: 'start',
                    gap: '0.5rem',
                    marginLeft: 0,
                },
                label: {
                    paddingTop: '2px',
                    fontSize: fontSizes[size],
                    fontWeight: 400,
                    color: akIron,
                    lineHeight: '18px',
                    textAlign: 'left',
                },
            },
        },
    });

    // eslint-disable-next-line react/no-unstable-nested-components
    function DryCheckBox() {
        return (
            <MuiCheckbox
                id={`${name}|${label}`}
                checked={checked}
                disabled={disabled}
                indeterminate={indeterminate}
                onClick={(event) => {
                    event.stopPropagation();
                    onClick && onClick(event);
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    event.stopPropagation();
                    onChange && onChange(event, !checked);
                }}
                name={name}
                size={size}
            />
        );
    }

    return (
        <ThemeProvider theme={theme}>
            {label ? <FormControlLabel control={<DryCheckBox />} label={label} /> : <DryCheckBox />}
        </ThemeProvider>
    );
}

export default CheckBox;
