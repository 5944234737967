import { Instance, types } from 'mobx-state-tree';

const HolidayModel = types.model('HolidayModel', {
    name: '',
    dateFrom: '',
    dateTo: '',
});

export type TypeHolidayModel = Instance<typeof HolidayModel>;

export default HolidayModel;
