import { Instance, types } from 'mobx-state-tree';

const LinksModel = types.model('LinksModel', {
    licenseAgreement: types.maybeNull(types.string),
});
export type TypeLinksModel = Instance<typeof LinksModel>;

const TypesModel = types.model('TypesModel', {
    en_US: types.optional(types.string, '', [undefined, null]),
    fr_CA: types.optional(types.string, '', [undefined, null]),
    ja_JP: types.optional(types.string, '', [undefined, null]),
    ko_KR: types.optional(types.string, '', [undefined, null]),
    zh_CN: types.optional(types.string, '', [undefined, null]),
});
export type TypeTypesModel = Instance<typeof TypesModel>;

const InfoModel = types.model('SchoolsInfoModel', {
    links: types.maybeNull(LinksModel),
    types: types.map(TypesModel),
});
export type TypeInfoModel = Instance<typeof InfoModel>;
export default InfoModel;
