import { lazy } from 'react';

import { typedRoutes } from './utils/module-interfaces';
import { PATHS } from './soft-paths/messages';

export const ROUTES = typedRoutes({
    MESSAGES: {
        ...PATHS.MESSAGES,
        id: 'MESSAGES',
        name: 'Messages',
        i18nBase: 'user_messages:module',
        i18nId: 'user_messages:module.menu-label',
        displayInMenu: false,
        privateMode: true,
        component: lazy(() => import('../components/modules/messages/messages')),
    },
});

export const DEFAULT_ROUTE = ROUTES.MESSAGES;
