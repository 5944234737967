const moduleRoot = `/account`;

export const PATHS = {
    LICENSES_SUBSCRIPTIONS: {
        path: `${moduleRoot}/subscriptions`,
    },
    LICENSES_SUBSCRIPTIONS_WITH_SECTION: {
        path: `${moduleRoot}/subscriptions/:sectionId`,
    },
};
