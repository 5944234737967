import { signal, Signal } from '@preact/signals-react';

export const StrataPricingSelectedTab = signal('general');

type PriceSignal = {
    price: string | null;
    target: string | null;
};
export const StrataPriceTrial: Signal<PriceSignal> = signal({ price: null, target: null });
export const StrataPrice3Y: Signal<PriceSignal> = signal({ price: null, target: null });
export const StrataPriceAcademic: Signal<PriceSignal> = signal({ price: null, target: null });
