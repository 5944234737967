import React from 'react';

import { useTranslation } from 'react-i18next';

import { HEADER_TOOLS } from '../../../constants';

import { TypeApplicationStore } from '../../../stores/application-store';
import { injectStores } from '../../../stores/factories/store-utils';

import Button from '../button/button';
import DivButton from '../../common/legos/div-button/div-button';

import SwitchLink from '../../menus/menu/switch-link/switch-link';

import './menu-dropdown.scss';

const TOOL_NAME = HEADER_TOOLS.GET_STARTED;

export interface Props {
    ApplicationStore: TypeApplicationStore;
    isMobileMenu?: boolean;
    className?: string;
}

function GetStartedDropdown(props: Props) {
    const { t } = useTranslation();
    const {
        ApplicationStore: { showToolInHeader },
        isMobileMenu = false,
        className,
    } = props;

    return (
        <div className={`mobile-menu-item ${className}`}>
            <SwitchLink
                akTestId='GetStarted|LinkTo|General|DownloadLauncher'
                to={{ module: 'GENERAL', subModule: 'DOWNLOAD_LAUNCHER' }}
            >
                {isMobileMenu ? (
                    <DivButton
                        className='link link_variant'
                        onClick={() => {
                            showToolInHeader(TOOL_NAME);
                        }}
                    >
                        {t('common:audiokineticLauncher.download2')}
                    </DivButton>
                ) : (
                    <Button
                        size='small'
                        id='header|tools|download-launcher'
                        label={t('common:audiokineticLauncher.download2')}
                    />
                )}
            </SwitchLink>
        </div>
    );
}

export default injectStores(['ApplicationStore'])(GetStartedDropdown);
