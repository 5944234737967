import React, { ReactElement, Suspense, useEffect, useState } from 'react';

import { featureFlags } from '../utils/feature-flags';

import { Redirect, Route, Router, Switch } from 'react-router';

import { createBrowserHistory } from 'history';
import { isUndefined, map, pickBy } from 'lodash';
import cx from 'classnames';

import ApplicationStoreInstance, { TypeApplicationStore } from '../stores/application-store';
import { injectStores } from '../stores/factories/store-utils';
import { TypeProfileStore } from '../stores/profile-store';

import { supportedLanguages } from '../i18n/supported-locales';

import { Module, ModuleRoute } from '../modules/utils/module-interfaces';
import { DEFAULT_ROUTE, MODULES } from '../modules/modules';

import CircularLoader from '../components/loader/circular-loader/circular-loader';
import Footer from '../components/menus/footer/footer';
import FourOhFour from '../components/common/four-oh-four/four-oh-four';
import EmailConfirmationMessaging from '../components/modules/profile/account-confirmation/email-confirmation-messaging';
import Header from '../components/menus/header/header';
import ProfileStoreRedirectDetector from '../components/modules/profile/profile-store-redirect-detector';
import SignInSignUp from '../components/authentication/sign-in-up/sign-in-up';
import ScrollToTop from '../utils/scroll-to-top';
{
    /*Commented out because of HUB-1758 */
    // import ScrollManager from '../utils/scroll-manager';
}

import './jakhub.scss';

const history = createBrowserHistory();

const renderRouteRedirect = (module: Module, route: ModuleRoute, userLanguage: string) => {
    return (
        <Route
            exact
            path={route.path}
            key={route.path}
            render={(props) => (
                <Redirect
                    to={
                        props.location.pathname.includes(`/${userLanguage}/`)
                            ? `/${props.location.pathname}${window.location.search}`
                            : `/${userLanguage}${props.location.pathname}${window.location.search}`
                    }
                />
            )}
        />
    );
};

type PrivateComponentProps = {
    ProfileStore: TypeProfileStore;
    route: ModuleRoute;
};

function Component(props: PrivateComponentProps) {
    const {
        ProfileStore: {
            userIsLoggedIn,
            loadingCounter,
            userAccount: {
                profile: { isVerified },
            },
        },
        route,
    } = props;

    const rootElement = document.getElementById('main-content');
    rootElement?.setAttribute('style', 'min-height: 100dvh');

    const { onLoadAction, component, privateMode, postSignInUpTarget, redirectTo } = route;

    if (onLoadAction) {
        onLoadAction();
        return <Redirect to={window.location.href} />;
    }

    const Component = component as React.ElementType;

    if (redirectTo) return <Redirect to={redirectTo} />;

    if (loadingCounter > 0 && !userIsLoggedIn) {
        return <CircularLoader />;
    }

    const pathsToBypass = [
        '/settings/account-settings',
        '/profile/create-profile-thank-you',
        '/project-invitation',
    ];

    if (privateMode && userIsLoggedIn && !isVerified && !pathsToBypass.includes(route?.path)) {
        return <EmailConfirmationMessaging canDismiss={false} />;
    }

    return privateMode && !userIsLoggedIn ? (
        <SignInSignUp inLine postSignUpTargetPath={postSignInUpTarget} />
    ) : (
        <Component onLoadAction={onLoadAction} />
    );
}
const PrivateComponent = injectStores(['ProfileStore'])(Component);

const renderRouteComponent = (module: Module, route: ModuleRoute) => {
    if (!isUndefined(route.protectedFeature) && !featureFlags[route.protectedFeature]) {
        return null;
    }

    if (route.redirectTo || route.component) {
        return (
            <Route
                exact
                path={[...supportedLanguages.map((supLan: string) => `/${supLan}${route.path}`)]}
                key={route.path}
                render={() => (
                    <Suspense fallback={<CircularLoader />}>
                        <PrivateComponent route={route} />
                    </Suspense>
                )}
            />
        );
    }

    return null;
};

const cleanMultiSlashRoutesOrFourOhFour = () => {
    return (
        <Route
            path='*'
            render={(props) => {
                const {
                    match: { url },
                } = props;
                const regex = /\/\/+/g;
                const rootElement = document.getElementById('main-content');
                rootElement?.setAttribute('style', 'min-height: 100dvh');

                if (url.match(regex)) {
                    const cleanUrl = url.replace(regex, '/');
                    return <Redirect from={url} to={cleanUrl} />;
                }
                rootElement?.setAttribute('style', 'min-height: unset');
                return <FourOhFour />;
            }}
        />
    );
};

const getModuleSubRoutes = (userLanguage: string) =>
    map(MODULES, (module: Module) =>
        map(
            pickBy(module.routes, (m: ModuleRoute<any>) => m.path || m.hardPath),
            (route: ModuleRoute) => [
                renderRouteRedirect(module, route, userLanguage),
                renderRouteComponent(module, route),
            ]
        )
    );

const getRoutes = (userLanguage: string) =>
    map(MODULES, (module: Module) => [
        renderRouteRedirect(module, module.defaultRoute, userLanguage),
        renderRouteComponent(module, module.defaultRoute),
    ]);

export interface Props {
    ApplicationStore: TypeApplicationStore;
}

const DebugRouter = ({ children }: { children: ReactElement }) => {
    if (featureFlags.ROUTER_DEBUG_IS_ACTIVE) {
        history.listen((location, action) => {
            console.log(
                `The current URL is ${location.pathname}${location.search}${location.hash}`
            );
            console.log(
                `The last navigation action was ${action}`,
                JSON.stringify(history, null, 2)
            );
        });
    }

    return children;
};

declare const window: Window &
    typeof globalThis & {
        BackendInfo: typeof ApplicationStoreInstance.backendAlive;
        JAKHUB_VERSION: string;
    };
if (window) {
    window.BackendInfo = ApplicationStoreInstance.backendAlive;
}

const testForMinHeight = () =>
    ['four-oh-four'].reduce((acc, cur) => acc || document.getElementById(cur) !== null, false);

function Jakhub(props: Props) {
    const {
        ApplicationStore: { applicationIsLoaded, showToolInHeader, userLanguage, backendAlive },
    } = props;

    if (!applicationIsLoaded()) {
        return <CircularLoader />;
    }

    if (backendAlive) {
        const { author, branch, date, subject } = backendAlive;

        window.BackendInfo = {
            title: 'Backend information',
            branch,
            author,
            subject,
            date,
        };

        if (featureFlags.CONSOLE_LOGS_ARE_ACTIVE) {
            // Display current Backend version... but NOT in PROD
            console.info('Backend information');
            console.info('    Branch  : ', branch);
            console.info('    Author  : ', author);
            console.info('    Subject : ', subject);
            console.info('    Date    : ', date);
        }
    }

    const { JAKHUB_VERSION } = window;
    if (featureFlags.CONSOLE_LOGS_ARE_ACTIVE) {
        // Display current Jakhub version... but NOT in PROD
        console.log('JAKHUB_VERSION:  ', JAKHUB_VERSION);
    }

    const [noMinHeight, setNoMinHeight] = useState(false);
    useEffect(() => setNoMinHeight(testForMinHeight()), []);
    history.listen(() => setNoMinHeight(testForMinHeight()));

    const jakhubParentClass = cx('jakhub-parent', { noMinHeight: noMinHeight });

    return (
        <Router history={history}>
            <ScrollToTop />
            {/*Commented out because of HUB-1758 */}
            {/*<ScrollManager />*/}
            <ProfileStoreRedirectDetector />
            <div onClick={() => showToolInHeader('', 'JAKHUB')}>
                <div className={jakhubParentClass} id='jakhub-parent'>
                    <Header />
                    <div className='content-flex'>
                        <div id='main-content' className='main-content'>
                            <DebugRouter>
                                <Switch>
                                    {getModuleSubRoutes(userLanguage)}
                                    {getRoutes(userLanguage)}
                                    <Route
                                        exact
                                        path={[
                                            ...supportedLanguages.map(
                                                (supLan: string) => `/${supLan}/`
                                            ),
                                            '/',
                                        ]}
                                        render={() => (
                                            <Redirect
                                                to={DEFAULT_ROUTE.routes[DEFAULT_ROUTE.id].path}
                                            />
                                        )}
                                    />
                                    {cleanMultiSlashRoutesOrFourOhFour()}
                                </Switch>
                            </DebugRouter>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default injectStores(['ApplicationStore'])(Jakhub);
