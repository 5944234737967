import React, { PropsWithChildren } from 'react';

import classNames from 'classnames';

import { useJakhubTranslation } from '../../../i18n/jakhub-translation';

import { ModuleRoute } from '../../../modules/utils/module-interfaces';

import { injectStores } from '../../../stores/factories/store-utils';
import { TypeApplicationStore } from '../../../stores/application-store';

import { useWindowSize } from '../../../custom-hooks';

import ModuleNavigation from '../../modules/module-navigation';
import PsbLogo from '../../../assets/images/psb_logo.png';
import SwitchLink from '../menu/switch-link/switch-link';

import './footer.scss';

interface Props {
    ApplicationStore: TypeApplicationStore;
}
function PsbComponent({ children }: PropsWithChildren<{}>) {
    return (
        <SwitchLink
            akTestId='PSB|WWW.BEIAN.GOV.CN'
            to='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009871'
        >
            <img src={PsbLogo} alt='沪公网安备 31010402009871号' />
            {children}
        </SwitchLink>
    );
}

function Footer(props: Props) {
    const { t } = useJakhubTranslation();
    const {
        ApplicationStore: { serverDate },
    } = props;
    const { isSmall } = useWindowSize();

    const currentYear = new Date(serverDate).getFullYear() || new Date().getFullYear();

    const footerHeaderClass = classNames('footer-header', { 'display-block': isSmall });

    return (
        <footer className='footer'>
            <div className='footer-content container'>
                <div className={footerHeaderClass}>
                    <div className='footer-header__link'>
                        <ModuleNavigation
                            className='footer-home'
                            selectedModules={['HOME']}
                            predicate={(module: ModuleRoute) => module.id === 'FOOTER_HOME'}
                            // useIcon
                        />
                    </div>
                    <div className='text-links'>
                        <ModuleNavigation
                            className='footer-text-links'
                            selectedModules={['FOOTER']}
                            predicate={(module: ModuleRoute) =>
                                !module.IconComponent &&
                                module.displayInMenu !== false &&
                                module.displayInFooter !== false
                            }
                        />
                    </div>
                    <div className='icon-links'>
                        <ModuleNavigation
                            className='footer-icon-links'
                            selectedModules={['FOOTER']}
                            predicate={(module: ModuleRoute) =>
                                !!module.IconComponent &&
                                module.displayInMenu !== false &&
                                module.displayInFooter !== false
                            }
                            // useIcon
                        />
                    </div>
                </div>
                <div className='full-menu'>
                    <ModuleNavigation
                        className='footer-full-menu'
                        predicate={(module: ModuleRoute) =>
                            module.displayInMenu !== false && module.displayInFooter !== false
                        }
                    />
                </div>
                <div className='legal-menu'>
                    <div className='full-menu__legal'>
                        <ModuleNavigation className='footer-legal' selectedModules={['LEGAL']} />
                    </div>
                    <div className='copyright'>
                        {t('footer:module.copyright.menu-label', {
                            AK_VAR_CURRENT_YEAR: currentYear,
                            AK_ICP_LINK: (
                                <SwitchLink
                                    akTestId='ICP|WWW.BEIAN.GOV.CN'
                                    to='https://www.beian.gov.cn'
                                />
                            ),
                            AK_PSB_LINK: <PsbComponent />,
                        })}
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default injectStores(['ApplicationStore'])(Footer);
