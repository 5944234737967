import { Instance, types } from 'mobx-state-tree';

const NullIsFalseModel = types.snapshotProcessor(
    types.maybeNull(types.union(types.string, types.boolean)),
    {
        preProcessor(sn: any) {
            if (sn === null) return false;
            return sn;
        },
    }
);

const NotificationsModel = types.model('NotificationsModel', {
    emailSubscriptions: types.model({
        Blog: types.maybeNull(NullIsFalseModel),
        Education: types.maybeNull(NullIsFalseModel),
        Releases: types.maybeNull(NullIsFalseModel),
        Strata: types.maybeNull(NullIsFalseModel),
        Wwise: types.maybeNull(NullIsFalseModel),
    }),
    systemNotifications: types.model({
        System: types.boolean,
    }),
});

export type TypeNotificationsModel = Instance<typeof NotificationsModel>;

export default NotificationsModel;
