import { Instance, types } from 'mobx-state-tree';

const RequestModel = types.model('RequestModel', {
    requestReceived: types.maybeNull(types.boolean),
    pendingRequest: types.maybeNull(types.boolean),
    alreadyMember: types.maybeNull(types.boolean),
});
export type TypeRequestModel = Instance<typeof RequestModel>;

const NotificationModel = types.model('NotificationModel', {
    courseLeadersFound: types.maybeNull(types.boolean),
    courseFound: types.maybeNull(types.boolean),
    leadershipNotificationSent: types.maybeNull(types.boolean),
});
export type TypeNotificationModel = Instance<typeof NotificationModel>;

const CourseAccessRequestModel = types.model('CourseAccessRequestModel', {
    request: RequestModel,
    notification: NotificationModel,
    checkCount: 0,
});
export type TypeCourseAccessRequestModel = Instance<typeof CourseAccessRequestModel>;

export default CourseAccessRequestModel;
