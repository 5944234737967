import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { includes, map } from 'lodash';
import classNames from 'classnames';

import { MODULES } from '../../../../modules/modules';

import { useJakhubTranslation } from '../../../../i18n/jakhub-translation';
import { TypeApplicationStore } from '../../../../stores/application-store';
import { TypeProfileModel } from '../../../../stores/user-profile-store/models/profile-model';
import { TypeProfileStore } from '../../../../stores/profile-store';
import { injectStores } from '../../../../stores/factories/store-utils';

import { useCallOnce, useWindowSize } from '../../../../custom-hooks';

import DivButton from '../../../common/legos/div-button/div-button';
import MenuDropdown from '../../../controls/dropdowns/menu-dropdown';
import SwitchLink from '../switch-link/switch-link';
import Tooltip from '@material-ui/core/Tooltip';
import ModuleExpander from '../../../modules/module-expander';

import { HEADER_TOOLS } from '../../../../constants';

import userOptionList from './current-user-option-list';

import '../../../controls/dropdowns/menu-dropdown.scss';
import VARIABLES from '../../../../styles/legos/variables.module.scss';
import './current-user.scss';

const { MaxApplicationWidth } = VARIABLES;

const TOOL_NAME = HEADER_TOOLS.USER_OPTIONS;

const badgeCount = (unreadMessages: number) => {
    return unreadMessages > 9 ? '9+' : unreadMessages;
};

interface OptionListProps {
    userLogout: () => void;
    userAccount: TypeProfileModel;
}

function ConstructOptionList(props: OptionListProps) {
    const { t } = useTranslation();
    const {
        userLogout,
        userAccount,
        userAccount: { unreadMessages },
    } = props;

    return (
        <>
            {map(userOptionList(userAccount), (option) => {
                const { module, subModule } = option.target;

                // @ts-ignore
                const menuItem = <div>{t(MODULES[module].routes[subModule].i18nId)}</div>;
                return !option.includeInOptionList ? null : (
                    <SwitchLink
                        akTestId={`ConstructOptionList|LinkTo|${option.target.module}|${option.target.subModule}`}
                        className={classNames('link__dropdown', {
                            link__dropdown__disabled: option?.disableOption,
                        })}
                        to={option.target}
                        key={`current-user-drop-down-general-${subModule}`}
                        disabled={option?.disableOption}
                    >
                        {option.disableOption ? (
                            <Tooltip
                                title={<div>{t('common:tool-tips.disabled-menu-item')}</div>}
                                placement='bottom-end'
                                enterTouchDelay={0}
                                leaveTouchDelay={0}
                            >
                                {menuItem}
                            </Tooltip>
                        ) : (
                            <>
                                {menuItem}
                                {unreadMessages > 0 &&
                                includes(['FCRM_MY_MESSAGES', 'MESSAGES'], subModule) ? (
                                    <span className='badge'>{badgeCount(unreadMessages)}</span>
                                ) : null}
                            </>
                        )}
                    </SwitchLink>
                );
            })}
            <DivButton className='link__dropdown' onClick={userLogout}>
                <span>{t('menu-tools.signup-login.btn-logout')}</span>
            </DivButton>
        </>
    );
}

interface Props {
    ApplicationStore: TypeApplicationStore;
    ProfileStore: TypeProfileStore;
    isMobileMenu?: boolean;
    className?: string;
}

function CurrentUser(props: Props) {
    const { tt } = useJakhubTranslation();
    const {
        ApplicationStore: {
            headerTools: { openedHeaderTool },
            showToolInHeader,
        },
        ProfileStore: {
            userAccount,
            userAccount: {
                account: {
                    emails: { primary: primary_email },
                },
                profile: { displayName, isVerified },
            },
            userIsLoggedIn,
            userLogout,
        },
        isMobileMenu = false,
        className = '',
    } = props;
    const isActiveTool = openedHeaderTool === TOOL_NAME;

    // Hide User's name if it doesn't fit
    const { isMobile, width: fullWindowWidth } = useWindowSize();
    const [toolWidthRange, setToolWidthRange] = useState<{ min: number | null; max: number }>({
        min: null,
        max: 0,
    });

    const checkAgain = (reason: string) => {
        // Need to make sure Tools are done rendering after login
        const headerTools = document?.getElementById('header-tools')?.getBoundingClientRect();
        const newToolWidth = headerTools?.width || Infinity;

        // The following weird logic is required to support hiding/showing user's name
        // after widow resize vs name change
        const newRange = { ...toolWidthRange };

        if (newRange.min === null || reason === 'displayName') {
            newRange.min = newToolWidth;
            newRange.max = newToolWidth;
        }

        if (newRange.min > newToolWidth) {
            newRange.min = newToolWidth;
        }

        if (newRange.min !== newToolWidth || reason === 'displayName') {
            newRange.max = Math.max(newRange.max, newToolWidth);
        }

        setToolWidthRange(newRange);
    };

    useEffect(() => checkAgain('windowWidth'), [fullWindowWidth]);
    useEffect(() => checkAgain('displayName'), [displayName]);
    useCallOnce(() => checkAgain('CALL ONCE'));

    if (!userIsLoggedIn) return <div />;
    if (isMobileMenu && !isMobile) return null;

    const maxAppW = parseInt(MaxApplicationWidth, 10);
    const mainMenu = document?.getElementById('main-menu')?.getBoundingClientRect();

    // 150 hardcoded to compensate for padding in menus
    const availableSpaceInMenu = maxAppW - (mainMenu?.width ?? maxAppW) - 50;
    const availableSpaceRightOfMenu = (fullWindowWidth - maxAppW) / 2;
    const availableSpaceForTools = availableSpaceInMenu + availableSpaceRightOfMenu;
    const hideUserName = toolWidthRange.max > availableSpaceForTools;

    return (
        <div className={'current-user ' + className}>
            <MenuDropdown
                optionsComponents={
                    <ConstructOptionList
                        userAccount={userAccount.profile}
                        userLogout={userLogout}
                    />
                }
                showOpenList={isActiveTool}
                actionOpenList={() => showToolInHeader(TOOL_NAME)}
            >
                <div className={'current-user-toggler' + (isMobileMenu ? ' mobile-menu-item' : '')}>
                    {!isMobileMenu && (
                        <>
                            <i className='material-icons-outlined'>account_circle</i>
                        </>
                    )}
                    {!hideUserName || isMobileMenu ? (
                        <div
                            className={
                                isMobileMenu ? 'link link_variant' : 'current-user-display-name'
                            }
                        >
                            {isVerified
                                ? displayName
                                : `${primary_email} (${tt('common:labels.unverified-user')})`}
                        </div>
                    ) : null}
                    {!isMobileMenu && (
                        <i className='material-icons'>
                            {isActiveTool ? 'arrow_drop_up' : 'arrow_drop_down'}
                        </i>
                    )}
                    <ModuleExpander
                        onClick={() => showToolInHeader(TOOL_NAME)}
                        isExpanded={isActiveTool}
                    />
                </div>
            </MenuDropdown>
        </div>
    );
}

export default injectStores(['ApplicationStore', 'ProfileStore'])(CurrentUser);
