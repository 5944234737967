import { Instance, types } from 'mobx-state-tree';

import { AuthorModel } from './author-model';

export const CommunityQandAModel = types.model('CommunityQandAModel', {
    id: 0,
    title: '',
    category: '',
    categoryUrl: '',
    tags: types.array(types.string),
    votes: 0,
    answers: 0,
    views: 0,
    publishedDate: types.maybeNull(types.string),
    url: '',
    author: AuthorModel,
});

export type TypeCommunityQandAModel = Instance<typeof CommunityQandAModel>;

export default CommunityQandAModel;
