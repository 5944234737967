import { Instance, types } from 'mobx-state-tree';

export const CollectionDynamicDetailsModel = types.model('CollectionDynamicDetailsModel', {
    label: types.string,
    value: types.string,
    url: types.maybe(types.string),
});

export type TypeCollectionDynamicDetailsModel = Instance<typeof CollectionDynamicDetailsModel>;
export default CollectionDynamicDetailsModel;
