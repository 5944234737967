import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = `/help`;

export const PATHS = {
    HELP: {
        path: `${moduleRoot}`,
    },
    SUPPORT: {
        path: `${moduleRoot}/support`,
    },
    CREATIVE_SERVICES: {
        path: `${moduleRoot}/documentation`,
        hardPath: HARD_PATHS.SOLUTIONS.CREATIVE_SERVICES,
    },
    DOCUMENTATION: {
        path: `${moduleRoot}/documentation`,
        hardPath: HARD_PATHS.HELP.DOCUMENTATION,
    },
    QUESTIONS_ANSWERS: {
        path: `${moduleRoot}/questions-answers`,
        hardPath: HARD_PATHS.HELP.QUESTIONS_ANSWERS,
    },
    SUBMIT_SUPPORT_TICKET: {
        path: `${moduleRoot}/submit-support-ticket`,
        hardPath: HARD_PATHS.HELP.SUBMIT_SUPPORT_TICKET,
    },
    SUPPORT_CONTACT_US: {
        path: `${moduleRoot}/contact/sales`,
        hardPath: HARD_PATHS.HELP.SUPPORT_CONTACT_US,
    },
    SUPPORT_PAY_2_GO: {
        path: `${moduleRoot}/support/pay2go/`,
        hardPath: HARD_PATHS.HELP.SUPPORT_PAY_2_GO,
    },
    REQUEST_FEATURE: {
        path: `${moduleRoot}/request-feature`,
        hardPath: HARD_PATHS.HELP.REQUEST_FEATURE,
    },
    REPORT_BUG: {
        path: `${moduleRoot}/report-bug`,
        hardPath: HARD_PATHS.HELP.REPORT_BUG,
    },
    UNITY_INTEGRATION: {
        path: `${moduleRoot}/NEW-COMPONENT-PATH`,
        hardPath: HARD_PATHS.HELP.UNITY_INTEGRATION,
    },
    UNREAL_INTEGRATION: {
        path: `${moduleRoot}/NEW-COMPONENT-PATH`,
        hardPath: HARD_PATHS.HELP.UNREAL_INTEGRATION,
    },
    WAAPI: {
        path: `${moduleRoot}/NEW-COMPONENT-PATH`,
        hardPath: HARD_PATHS.HELP.WAAPI,
    },
    WWISE_LAUNCHER: {
        path: `${moduleRoot}/NEW-COMPONENT-PATH`,
        hardPath: HARD_PATHS.HELP.WWISE_LAUNCHER,
    },
    WWISE_HELP: {
        path: `${moduleRoot}/NEW-COMPONENT-PATH`,
        hardPath: HARD_PATHS.HELP.WWISE_HELP,
    },
    WWISE_WELCOME: {
        path: `${moduleRoot}/NEW-COMPONENT-PATH`,
        hardPath: HARD_PATHS.HELP.WWISE_WELCOME,
    },
    WWISE_SDK: {
        path: `${moduleRoot}/NEW-COMPONENT-PATH`,
        hardPath: HARD_PATHS.HELP.WWISE_SDK,
    },
    WWISE_ADVENTURE_GAME: {
        path: `${moduleRoot}/NEW-COMPONENT-PATH`,
        hardPath: HARD_PATHS.HELP.WWISE_ADVENTURE_GAME,
    },
    WWISE_AUDIO_LAB: {
        path: `${moduleRoot}/NEW-COMPONENT-PATH`,
        hardPath: HARD_PATHS.HELP.WWISE_AUDIO_LAB,
    },
    WWISE_AUTHORING_API_PACK: {
        path: `${moduleRoot}/NEW-COMPONENT-PATH`,
        hardPath: HARD_PATHS.HELP.WWISE_AUTHORING_API_PACK,
    },
    PLUGIN_PACKAGING: {
        path: `${moduleRoot}/NEW-COMPONENT-PATH`,
        hardPath: HARD_PATHS.HELP.PLUGIN_PACKAGING,
    },
    GET_IN_TOUCH: {
        path: `${moduleRoot}/get_in_touch`,
        hardPath: HARD_PATHS.HELP.GET_IN_TOUCH,
    },
    STRATA_DOCUMENTATION: {
        path: `${moduleRoot}/strata_documentation`,
        hardPath: HARD_PATHS.HELP.STRATA_DOCUMENTATION,
    },
    STRATA_COMMUNITY_QNA: {
        path: `${moduleRoot}/strata_community_qna`,
        hardPath: HARD_PATHS.HELP.STRATA_COMMUNITY_QNA,
    },
};
