import { Instance } from 'mobx-state-tree';

import { isDev } from '../../utils/build-utils';

import StoreFactory from '../factories/store';

const GameEngineStore = StoreFactory({
    modelName: 'GameEngineStore',
    models: [],
    modelStructure: {
        isLoading: false,
        title: 'GameEngineStore',
    },
});

export type TypeGameEngineStore = Instance<typeof GameEngineStore>;

const GameEngineStoreInstance = GameEngineStore.create();
declare const window: Window &
    typeof globalThis & {
        GameEngineStore: typeof GameEngineStoreInstance;
    };

if (window && isDev()) {
    window.GameEngineStore = GameEngineStoreInstance;
}

export default GameEngineStore;
