import { Instance, types } from 'mobx-state-tree';

const DocumentModel = types.model('DocumentModel', {
    date: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    type: types.optional(types.string, 'NOT YET RETURNED BY BE'),
});

export type TypeDocumentModel = Instance<typeof DocumentModel>;
export default DocumentModel;
