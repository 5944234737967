import { setTimeout } from 'timers';

export function scrollToAnchorFromURL(yOffset: number) {
    const { hash } = window.location;
    if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
            scrollToAnchor(element, yOffset);
        }
    }
}

export function scrollToAnchor(element: HTMLElement, yOffset = 0, retryAttempts = 3) {
    requestAnimationFrame(() => {
        const yCoordinate = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        if (retryAttempts) {
            setTimeout(() => {
                const distanceFromElement = element.getBoundingClientRect().top + yOffset;

                if (distanceFromElement > 100 || distanceFromElement < -100) {
                    scrollToAnchor(element, yOffset, retryAttempts - 1);
                }
            }, 500);
        }
    });
}
