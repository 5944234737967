import { isUndefined, pick } from 'lodash';

import { LOCAL_STORAGE_KEY } from '../constants';
import { voidFunction } from './utils';

type FeatureFlags = {
    FEATURE_FLAGS_ADMIN: boolean;
    FEATURE_FLAGS: boolean;
    DESCRIPTION_TESTER: boolean;
    COMMUNITY_BLOG_PAGE: boolean;
    FCRM_LINK_SUFFIX: boolean;
    CONSOLE_LOGS_ARE_ACTIVE: boolean;
    UNOBFUSCATE_SEARCH_URL: boolean;
    ROUTER_DEBUG_IS_ACTIVE: boolean;
};

const localStorageFlags = getFlagsFromLocalStorage();

function getFlagsFromLocalStorage(): FeatureFlags {
    let lStorage;
    try {
        lStorage = localStorage.getItem(LOCAL_STORAGE_KEY)
            ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}')
            : {};
    } catch (e) {
        lStorage = {};
    }

    return lStorage;
}

const noFlagMessages: string[] = [];
function getFlagState(envVar: keyof FeatureFlags): boolean {
    if (process.env[`REACT_APP_${envVar}`] !== 'true') {
        // Will console.warn these messages IF featureFlag CONSOLE_LOGS_ARE_ACTIVE is true
        noFlagMessages.push(`  -- ::: No Feature Flags loaded for ${envVar} ::: --`);
    }

    if (process.env['REACT_APP_FEATURE_FLAGS'] !== 'true' && !localStorageFlags[envVar]) {
        return false;
    }
    if (!isUndefined(localStorageFlags[envVar])) {
        return localStorageFlags[envVar];
    }
    if (!isUndefined(process.env[`REACT_APP_${envVar}`])) {
        return process.env[`REACT_APP_${envVar}`] === 'true';
    }
    return false;
}

const featureFlags = {
    FEATURE_FLAGS: false,
    FEATURE_FLAGS_ADMIN: false,
    DESCRIPTION_TESTER: false,
    COMMUNITY_BLOG_PAGE: false,
    FCRM_LINK_SUFFIX: false,
    CONSOLE_LOGS_ARE_ACTIVE: false,
    UNOBFUSCATE_SEARCH_URL: false,
    ROUTER_DEBUG_IS_ACTIVE: false,
} as Record<keyof FeatureFlags, boolean>;

type ConsoleFunction = ((param: any[]) => void) | null;
type Console = {
    log: ConsoleFunction;
    info: ConsoleFunction;
    debug: ConsoleFunction;
    warn: ConsoleFunction;
};
export const logger = (function () {
    const oldConsole: Console = {
        log: null,
        info: null,
        debug: null,
        warn: null,
    };
    return {
        enableLogger: () => {
            if (oldConsole.log === null) return;

            // @ts-ignore
            globalThis['console']['log'] = oldConsole.log;
            // @ts-ignore
            globalThis['console']['info'] = oldConsole.info;
            // @ts-ignore
            globalThis['console']['debug'] = oldConsole.debug;
            // @ts-ignore
            globalThis['console']['warn'] = oldConsole.warn;
        },

        disableLogger: () => {
            // @ts-ignore
            oldConsole.log = console.log;
            // @ts-ignore
            oldConsole.info = console.info;
            // @ts-ignore
            oldConsole.debug = console.debug;
            // @ts-ignore
            oldConsole.warn = console.warn;

            const noop = voidFunction;

            globalThis['console']['log'] = noop;
            globalThis['console']['info'] = noop;
            globalThis['console']['debug'] = noop;
            globalThis['console']['warn'] = noop;
        },
    };
})();

(function () {
    Object.keys(featureFlags).map(
        (ff) => (featureFlags[ff as keyof FeatureFlags] = getFlagState(ff as keyof FeatureFlags))
    );

    // Clean out old Flags
    if (typeof localStorage !== 'undefined') {
        const currentFlags = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
        localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify(pick(currentFlags, Object.keys(featureFlags)))
        );
    }

    if (
        !featureFlags.CONSOLE_LOGS_ARE_ACTIVE ||
        process.env['REACT_APP_APPLICATION'] !== 'JAKHUB'
    ) {
        logger.disableLogger();
    }

    if (featureFlags.CONSOLE_LOGS_ARE_ACTIVE) logger.enableLogger();

    noFlagMessages.map((message) => console.warn(message));
})();

export type ProtectedFeature = keyof FeatureFlags;

export { LOCAL_STORAGE_KEY, getFlagsFromLocalStorage, featureFlags };
