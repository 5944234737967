import { typedRoutes } from './utils/module-interfaces';

import { lazy } from 'react';
import { PATHS } from './soft-paths/terms';

export const ROUTES = typedRoutes({
    PURCHASING_TERMS: {
        ...PATHS.PURCHASING_TERMS,
        id: 'PURCHASING_TERMS',
        name: 'Purchasing Terms and Conditions',
        i18nBase: 'terms:module.terms-of-use',
        i18nId: 'terms:module.terms-of-use.menu-label',
        component: lazy(() => import('../components/modules/terms/terms')),
    },
    PURCHASING_TERMS_REGION: {
        ...PATHS.PURCHASING_TERMS_REGION,
        id: 'PURCHASING_TERMS_REGION',
        name: 'Purchasing Terms and Conditions',
        i18nBase: 'terms:module.terms-of-use',
        i18nId: 'terms:module.terms-of-use.menu-label',
        component: lazy(() => import('../components/modules/terms/terms')),
    },
});

export const DEFAULT_ROUTE = ROUTES.PURCHASING_TERMS;
