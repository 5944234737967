import { omit } from 'lodash';
import { isDev } from '../../utils/build-utils';
import { Endpoint } from '../api-interfaces';
import { setValueWithPath } from '../../utils/utils';

export const RESOURCE_LOCATION = isDev()
    ? '/jakhub/static-resources/'
    : '/download-jakhub-json.php?file=';

export const ENVIRONMENT_ID_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/environment-id',
};

export const COUNTRIES_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/countries',
};

export const REGION_PER_COUNTRY_ID_ENDPOINT: Endpoint = {
    api: '/api',
    url: ({ countryCode }) => `/countryRegions/${countryCode}`,
};

export const POWERED_BY_WWISE_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/poweredByWwise/',
    method: 'POST',
    formData: (params) => {
        const { count, platformIds, searchTerm, gameEngine, orderBy, gameTags, pagination } =
            params;
        return {
            count: count,
            pagination,
            filter: {
                platformIds,
                searchTerm,
                gameEngine,
                orderBy,
                gameTags: gameTags.filter((tag: string) => tag !== 'All'),
            },
        };
    },
};

export const POWERED_BY_WWISE_INFO_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/poweredByWwise/info/',
};

export const SCHOOL_TYPES_INFO_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/school/info/',
};

export const CREATORS_DIRECTORY_STATS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/creatorsStats/',
};

export const CREATORS_DIRECTORY_INFO_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/creatorsDirectory/info/',
};

export const CREATORS_DIRECTORY_REQUEST_COURSE_ACCESS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/creatorsDirectory/requestCourseAccess/',
    method: 'POST',
    formData: (formData) => formData,
};

export const CREATORS_DIRECTORY_SEARCH_ENDPOINT: Endpoint = {
    api: '/api',
    url: ({ context }) => `/creatorsDirectory/${context}/`,
    method: 'POST',
    formData: (formData) => omit(formData, ['context']),
};

export const WWISE_SUPPORT_POLICY_STATIC_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/support_tiers/',
};

export const INTEGRATIONS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/integrations/',
};

export const PLATFORMS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/platforms/',
};

export const WWISE_CERTIFICATION_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/certifications/',
    method: 'POST',
};

export const WWISE_PLUGINS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/plugins/',
    method: 'POST',
    formData: () => ({
        filter: { category: 'audiokinetic' },
    }),
};

export const BLOG_POSTS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/blogs/',
    method: 'POST',
    formData: (filter = {}) => ({
        filter,
        count: 3,
    }),
};

export const BLOG_LIVE_INFO: Endpoint = {
    api: '/api',
    url: () => '/blogs/info/',
};

export const COMMUNITY_QA_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/questionsAnswers/?count=3',
};

export const LIBRARY_CARDS_ENDPOINT: Endpoint = {
    api: '/api',
    url: ({ urltag = 'ALL' }) => {
        return urltag === 'ALL' ? '/collections/' : `/collections/?library=${urltag}`;
    },
};

export const ALL_LIBRARY_INFO_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/library-all',
};

export const HOLIDAYS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/holidays/',
};

export const ALIVE_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/isAlive/',
};

export const NEWS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/newsCarousel/',
    method: 'GET',
};

export const GET_COLLECTION_ENDPOINT: Endpoint = {
    api: '/api',
    url: ({ urltag }) => `/collection/${urltag}`,
    method: 'GET',
};

export const NEWS_LIST_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => '/news/',
    method: 'POST',
    formData: (params) => params,
};

export const NEWS_PAGE_ENDPOINT: Endpoint = {
    api: '/api',
    url: ({ urltag }) => `/news/${urltag}`,
    method: 'GET',
};

export const ADMIN_MENU_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => `/adminMenu/`,
    requiredForMenus: true,
    method: 'GET',
};

export const UPCOMING_EVENTS_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => `/events/`,
    method: 'GET',
};

export const UPCOMING_EVENTS_INFO_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => `/events/info/`,
    method: 'GET',
};

export const SCHOOL_TYPE_INFO_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => `/school/info/`,
};

export const CREATE_SCHOOL_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => `/school/create/`,
    method: 'POST',
    formData: (formData) => {
        const newStructuredData = {};
        const sectionKeys = Object.keys(formData).filter((k) => k.indexOf('-') !== -1);
        sectionKeys.forEach((k) =>
            setValueWithPath(newStructuredData, k.split('-').join('.'), formData[k])
        );
        return newStructuredData;
    },
};

export const CONTACT_AUDIOKINETIC_ENDPOINT: Endpoint = {
    api: '/api',
    url: () => `/contacts/submitForm/`,
    method: 'POST',
    formData: (formData) => formData,
};
