import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = `/learning`;

export const PATHS = {
    LEARNING: {
        path: `${moduleRoot}`,
    },
    LEARN_WWISE: {
        path: `${moduleRoot}/learn-wwise`,
    },
    LEARN_WWISE_MORE_INFO: {
        path: `${moduleRoot}/learn-wwise/:slugId`,
    },
    TEACH_WWISE: {
        path: `${moduleRoot}/teach-wwise`,
    },
    TRAINING: {
        path: `${moduleRoot}/training`,
    },
    CREATE_SCHOOL_ACCOUNT: {
        path: `${moduleRoot}/create-school-account`,
    },
    CERTIFICATIONS: {
        path: `${moduleRoot}/certifications`,
    },
    CERTIFICATION_MORE_INFO: {
        path: `${moduleRoot}/certifications/:slugId`,
    },
    VIDEOS: {
        path: `${moduleRoot}/videos`,
        hardPath: HARD_PATHS.LEARNING.VIDEOS,
    },
    SAMPLES: {
        path: `${moduleRoot}/samples`,
    },
    SAMPLE: {
        path: `${moduleRoot}/samples/:sampleId`,
    },
};
