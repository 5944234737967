import { Instance, types } from 'mobx-state-tree';

export const CurrentUserInfo = types.model('CurrentUserInfo', {
    id: 0,
    firstname: '',
    lastname: '',
    displayname: '',
    title: '',
    email: '',
    company: '',
    locale: types.maybeNull(types.string),
});

export type TypeCurrentUserInfoModel = Instance<typeof CurrentUserInfo>;
export default CurrentUserInfo;
