/* eslint-disable no-param-reassign */
import { Instance, types } from 'mobx-state-tree';
import { map, mapKeys, mapValues } from 'lodash';

import { POWERED_BY_WWISE_ENDPOINT, POWERED_BY_WWISE_INFO_ENDPOINT } from '../../api/endpoints';

import ApiActionFactory from '../../api/api-action-factory';
import { isDev } from '../../utils/build-utils';

import ApplicationStore from '../../stores/application-store';
import { PoweredByWwiseInfoModel } from './models/powered-by-wwise-info-model';
import { PoweredByWwiseModel } from './models/powered-by-wwise-model';

import StoreFactory from '../factories/store';
import { lowerSnakeCase, snakeCase, stripNonAlphaNumeric } from '../../utils/utils';
import ApplicationStoreInstance from '../../stores/application-store';

export type PoweredByWwiseFilter = 'DiscoverGaming' | 'DiscoverARVR' | 'DiscoverLBE';
export type PoweredByWwiseMapId = PoweredByWwiseFilter | 'All' | 'RELOAD';

export interface GameListFilter {
    count?: number;
    pagination?: {
        pageNumber: number;
        itemsPerPage: number;
    };
    searchTerm?: string;
    gameEngine?: string;
    mstMapId: PoweredByWwiseMapId;
    gameTags: PoweredByWwiseFilter | string[];
}

const { getUserLocale } = ApplicationStore;

const PoweredByWwiseStore = StoreFactory({
    modelName: 'PoweredByWwiseStore',
    models: [
        ApiActionFactory({
            getPoweredByWwiseInfo: {
                endpoint: POWERED_BY_WWISE_INFO_ENDPOINT,
                target: 'poweredByWwiseInfo',
            },
            getPoweredByWwise: {
                endpoint: POWERED_BY_WWISE_ENDPOINT,
                target: 'poweredByWwiseMap',
                targetMapId: 'mstMapId',
            },
        }),
    ],
    modelStructure: {
        isLoading: false,
        title: 'PoweredByWwiseStore',
        poweredByWwiseInfo: types.maybeNull(PoweredByWwiseInfoModel),
        poweredByWwiseMap: types.map(PoweredByWwiseModel),
    },
})
    .actions((self) => ({
        retrievePoweredByWwiseInfo() {
            if (!self.poweredByWwiseInfo) {
                self.getPoweredByWwiseInfo();
            }
        },
    }))
    .actions((self) => ({
        retrieveGameList(gameFilter: GameListFilter) {
            const { mstMapId } = gameFilter;
            if (mstMapId === 'All' || mstMapId === 'RELOAD') {
                self.poweredByWwiseMap.set(mstMapId, { games: [], gamesCount: null });
            }
            if (
                !self.poweredByWwiseMap.get(gameFilter.mstMapId) ||
                mstMapId === 'All' ||
                mstMapId === 'RELOAD'
            ) {
                self.getPoweredByWwise(gameFilter);
            }
        },
        retrieveGameListV2(hashObject: { searchHash: string; searchForm: any }) {
            const { searchHash, searchForm } = hashObject;

            if (!self.poweredByWwiseMap.get(searchHash)) {
                self.poweredByWwiseMap.set(searchHash, { games: [], gamesCount: null });
                self.getPoweredByWwise({ ...searchForm, mstMapId: searchHash });
            }
        },
    }))
    .views((self) => ({
        getGameCount(mstMapId: string) {
            return self.poweredByWwiseMap.get(mstMapId)?.gamesCount;
        },
        getGameList(mstMapId: string) {
            return self.poweredByWwiseMap.get(mstMapId)?.games;
        },
        get poweredByWwiseLocalizedGameTags() {
            const userLocale = snakeCase(ApplicationStoreInstance.userLocale);
            const localizedGameTags: Record<string, string> = {};

            self.poweredByWwiseInfo?.gameTags.forEach((locale: any, gameTag: any) => {
                localizedGameTags[gameTag] = locale[userLocale];
            });

            return localizedGameTags;
        },
        get poweredByWwiseLocalizedGameEngines() {
            const localizedGameEngines: Record<string, string> = {};

            self.poweredByWwiseInfo?.gameEngines.forEach((gameEngine: any) => {
                localizedGameEngines[`id_${gameEngine.id}`] = gameEngine.name;
            });

            return localizedGameEngines;
        },
        get poweredByWwiseGameTags() {
            const currentLocale = getUserLocale();

            const arrayOfKeys = Array.from(self.poweredByWwiseInfo?.gameTags.keys() || []);
            return mapValues(mapKeys(arrayOfKeys), (key) => ({
                fe_id: key,
                be_id: key,
                label: self.poweredByWwiseInfo?.gameTags.get(key)[currentLocale.replace('-', '_')],
            }));
        },
        get poweredByWwiseGameEngines() {
            return mapKeys(
                map(self.poweredByWwiseInfo?.gameEngines, (engine) => {
                    return {
                        ...engine,
                        label: engine.name,
                        fe_id: lowerSnakeCase(stripNonAlphaNumeric(engine.name)),
                        be_id: engine.id,
                    };
                }),
                (itm) => itm.fe_id
            );
        },
    }));

export type TypePoweredByWwiseStore = Instance<typeof PoweredByWwiseStore>;

const PoweredByWwiseStoreInstance = PoweredByWwiseStore.create();
declare const window: Window &
    typeof globalThis & {
        PoweredByWwiseStore: typeof PoweredByWwiseStoreInstance;
    };

if (window && isDev()) {
    window.PoweredByWwiseStore = PoweredByWwiseStoreInstance;
}

export default PoweredByWwiseStore;
