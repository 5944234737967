import React from 'react';

import { useTranslation } from 'react-i18next';

import { map } from 'lodash';

import { TypeApplicationStore } from '../../../../stores/application-store';
import { TypeProfileStore } from '../../../../stores/profile-store';
import { injectStores } from '../../../../stores/factories/store-utils';
import { isJakhubForChina } from '../../../../utils/utils';

import { useUpdateEffect, useWindowSize } from '../../../../custom-hooks';

import Button from '../../../controls/button/button';
import CurrentUser from '../../menu/current-user/current-user';
import GetStartedDropdown from '../../../controls/dropdowns/get-started-dropdown';
import LanguageSwitcher from '../../menu/language-switcher/language-switcher';
import Messages from '../../menu/messages/messages';
import SwitchLink from '../../../menus/menu/switch-link/switch-link';
import Search from '../../menu/search/search';
import ShoppingCart from '../../menu/shopping-cart/shopping-cart';
import SignInSignUp from '../../../authentication/sign-in-up/sign-in-up';

import { LOGIN_TOOLS, LoginTools } from '../../../../constants';

import '../header.scss';

const { SIGN_IN } = LOGIN_TOOLS;

interface Props {
    ApplicationStore: TypeApplicationStore;
    ProfileStore: TypeProfileStore;
}

function HeaderTools(props: Props) {
    const { t } = useTranslation();
    const {
        ApplicationStore: {
            headerTools: { openedHeaderTool },
            showToolInHeader,
        },
        ProfileStore: {
            userIsLoggedIn,
            userAccount: {
                profile: { itemsInCart },
            },
            userAccountUnreadMessages,
        },
    } = props;
    const { resolution } = useWindowSize();
    useUpdateEffect(() => {
        showToolInHeader('');
    }, [resolution]);

    function isLoginTool(tool: string): tool is LoginTools {
        return map(LOGIN_TOOLS, (t) => t).includes(tool as LoginTools);
    }

    return (
        <div id='header-tools' className='header-tools'>
            <Search />
            <LanguageSwitcher className='mobile-hide' />
            {isJakhubForChina() ? (
                <div className='go-home-button'>
                    <SwitchLink
                        akTestId='HeaderTools|LinkTo|HOME|HOME_LOGIN'
                        to={{ module: 'HOME', subModule: 'HOME_LOGIN' }}
                    >
                        <Button
                            id='header-tools|login-logout'
                            label={t('menu-tools.signup-login.btn-login')}
                            className='mobile-hide'
                            variant='outlined'
                            size='small'
                        />
                    </SwitchLink>
                </div>
            ) : (
                <>
                    {userIsLoggedIn ? (
                        <>
                            <ShoppingCart badgeCount={itemsInCart} />
                            <Messages badgeCount={userAccountUnreadMessages} />
                        </>
                    ) : null}
                    {!userIsLoggedIn && (
                        <Button
                            id='header-tools|login-logout'
                            label={t('menu-tools.signup-login.btn-login')}
                            className='mobile-hide'
                            variant='outlined'
                            size='small'
                            onClick={() => {
                                showToolInHeader(SIGN_IN);
                            }}
                        />
                    )}
                    {isLoginTool(openedHeaderTool) && !userIsLoggedIn && <SignInSignUp />}
                    {userIsLoggedIn ? <CurrentUser className='mobile-hide' /> : null}
                </>
            )}
            <GetStartedDropdown className='mobile-hide' />
        </div>
    );
}

export default injectStores(['ApplicationStore', 'ProfileStore'])(HeaderTools);
