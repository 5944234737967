import { isValidEmail } from '../../../utils/validators';

export const PASSWORD_RULES = {
    required: 'pw_required',
    minLength: { value: 8, message: 'pw_tooShort' },
    validate: {
        passwordEmpty: (p: string) => p?.length > 0 || 'pw_tooShort',
        upperCase: (p: string) => /[A-Z]/.test(p) || 'pw_uppercase',
        lowerCase: (p: string) => /[a-z]/.test(p) || 'pw_lowercase',
        numberCheck: (p: string) => /[0-9]/.test(p) || 'pw_number',
    },
};

export const MINIMAL_PASSWORD_RULES = {
    required: 'pw_required',
};

export const EMAIL_RULES = {
    required: 'email_required',
    validate: {
        emailCheck: (p: string) => isValidEmail(p) || 'email_check',
    },
};
