import React, { Fragment, useEffect } from 'react';
import { SelectProps } from '@material-ui/core/Select';
import { Grid, GridSize } from '@material-ui/core';

import { find } from 'lodash';

import Button from '../../../../../src/components/controls/button/button';
import DatePicker from '../../../../../src/components/controls/date-picker/date-picker';
import { AcceptableValue } from '../editable-user-data';
import { useJakhubTranslation } from '../../../../i18n/jakhub-translation';

export type FactoryDateRangePickerValues = { id: string; value: string }; //Record<string, { id: string; value: string }>;

import styles from './factory-date-range-picker.module.scss';

function dateStringToNumber(dateString?: string) {
    if (!dateString) return 0;
    return parseInt(dateString.split('-').join(''), 10);
}

type FactoryDateRangePickerProps = SelectProps & {
    id: string;
    fieldKey: string;
    values: FactoryDateRangePickerValues[];
    gridColumnSpan: GridSize;
    dictionaryBase?: string;
    onChangeOverride: (id: string, val?: AcceptableValue) => void;
    fieldConfig: any;
};
export default function FactoryDateRangePicker(props: FactoryDateRangePickerProps) {
    const { t, tt } = useJakhubTranslation();
    const { values, fieldKey, gridColumnSpan, dictionaryBase, onChangeOverride, fieldConfig } =
        props;
    const { format, clearable = [false, false], fieldIds } = fieldConfig;
    const formLabelBase = `${dictionaryBase}.formLabels`;

    const startDateFromBackend = find(values, (val) => val.id === 'startDate')?.value;
    const endDate = find(values, (val) => val.id === 'endDate')?.value;

    const startDate = startDateFromBackend === '1900-01-01' ? undefined : startDateFromBackend;
    const endDateBeforeStartDate = dateStringToNumber(startDate) > dateStringToNumber(endDate);

    useEffect(() => {
        if (startDate === undefined) {
            onChangeOverride && onChangeOverride('startDate', '1900-01-01');
        }

        if (endDateBeforeStartDate) {
            onChangeOverride && onChangeOverride('endDate', null);
        }
    }, [endDateBeforeStartDate]);

    return (
        <Fragment key={fieldKey}>
            <Grid
                key={`${fieldKey}|COUNTRY`}
                item
                xs={gridColumnSpan}
                className={styles.dateRangePicker}
            >
                <DatePicker
                    format={format}
                    label={tt(`${formLabelBase}.${fieldIds[0]}.label`)}
                    placeHolder={
                        !startDate
                            ? tt(`${formLabelBase}.startDate.placeHolder`)
                            : (t('labels.select', {
                                  AK_TAG_THING_TO_SELECT: tt(
                                      `${formLabelBase}.${fieldIds[1]}.label`
                                  ),
                              }) as string)
                    }
                    initDate={startDate}
                    clearable={clearable[0]}
                    onChange={(newDate: AcceptableValue) => {
                        onChangeOverride &&
                            onChangeOverride('startDate', newDate?.toString().split('T')[0]);
                    }}
                />
            </Grid>
            <Grid
                key={`${fieldKey}|REGION`}
                item
                xs={gridColumnSpan}
                className={styles.dateRangePicker}
            >
                {startDate ? (
                    <DatePicker
                        format={format}
                        label={tt(`${formLabelBase}.${fieldIds[1]}.label`)}
                        placeHolder={
                            !endDate
                                ? tt(`${formLabelBase}.endDate.selectStartDate`)
                                : (t('labels.select', {
                                      AK_TAG_THING_TO_SELECT: tt(
                                          `${formLabelBase}.${fieldIds[1]}.label`
                                      ),
                                  }) as string)
                        }
                        initDate={endDate}
                        minDate={startDate}
                        clearable={clearable[1]}
                        onChange={(newDate: AcceptableValue) => {
                            onChangeOverride &&
                                onChangeOverride('endDate', newDate?.toString().split('T')[0]);
                        }}
                    />
                ) : (
                    <Button
                        style={{ width: '100%' }}
                        size='small'
                        disabled
                        label={tt(`${formLabelBase}.endDate.selectStartDate`)}
                        variant='outlined'
                    />
                )}
            </Grid>
        </Fragment>
    );
}
