import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = `/`;

export const PATHS = {
    TERMS_OF_USE: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.LEGAL.TERMS_OF_USE,
    },
    PRIVACY_POLICY: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.LEGAL.PRIVACY_POLICY,
    },
    USER_ACCOUNT_POLICY: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.LEGAL.USER_ACCOUNT_POLICY,
    },
    COOKIE_POLICY: {
        path: 'UNKNOWN PATH FOR NOW USING HARDPATH',
        hardPath: HARD_PATHS.LEGAL.COOKIE_POLICY,
    },
    TRADEMARKS: {
        path: `${moduleRoot}trademarks`,
    },
};
