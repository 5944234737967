import React, { ComponentProps, useCallback, useEffect, useState } from 'react';

import 'videojs-contrib-dash';

import videojs from 'video.js';

import 'video.js/dist/video-js.min.css';

import './dash-player.scss';

interface Props extends ComponentProps<'div'> {
    src: string;
    size?: 'fill' | 'fluid' | 'none';
    autoPlay: boolean;
    muted: boolean;
    loop: boolean;
    controls?: boolean;
}

function DashPlayer({
    src,
    size = 'fluid',
    autoPlay = true,
    muted = true,
    loop = false,
    controls = true,
    ...divProps
}: Props) {
    // const [player, setPlayer] = useState<typeof videojs.players>(); // For v^8.0.4
    const [player, setPlayer] = useState<videojs.Player>(); // For v^7.15.4
    const element = useCallback(
        (node) => {
            if (node) {
                const newPlayer = videojs(node, {
                    controls: controls,
                    autoplay: autoPlay,
                    loop: loop,
                    muted: muted, // autoPlay does not work unless you mute the thing.
                    controlBar: {
                        pictureInPictureToggle: false,
                        remainingTimeDisplay: false,
                    },
                    html5: {
                        dash: {
                            updateSettings: {
                                streaming: {
                                    abr: {
                                        limitBitrateByPortal: true,
                                    },
                                },
                            },
                        },
                    },
                });

                newPlayer.ready(() => setPlayer(newPlayer));
            }
        },
        [autoPlay]
    );

    useEffect(() => {
        player?.src({ src });
    }, [player, src]);
    useEffect(() => {
        player?.fluid(size === 'fluid');
        player?.fill(size === 'fill');
    }, [player, size]);

    return (
        <div {...divProps} className={`video-player ${divProps.className ?? ''}`}>
            <video ref={element} className='video-js' />
        </div>
    );
}

export default DashPlayer;
