import { Instance, types } from 'mobx-state-tree';

const CreatorsDirectoryGroupCountModel = types.model('CreatorsDirectoryGroupCountModel', {
    schools: types.integer,
    'content-providers': types.integer,
    'certified-users': types.integer,
});

export type TypeCreatorsDirectoryGroupCountModel = Instance<
    typeof CreatorsDirectoryGroupCountModel
>;

export default CreatorsDirectoryGroupCountModel;
