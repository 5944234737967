import { Instance, types } from 'mobx-state-tree';

export const LoginFormData = types.model('LoginFormData', {
    email: '',
    password: '',
    confirmPassword: types.maybe(types.string),
    loginAttempted: false,
});
export type TypeLoginFormData = Instance<typeof LoginFormData>;

export default LoginFormData;
