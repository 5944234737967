import React from 'react';

import { injectStores } from '../../../stores/factories/store-utils';
import DashPlayer from '../legos/dash-player/dash-player';
import { TypeApplicationStore } from '../../../stores/application-store';
import { ThirdPartyServicesTypes, THIRD_PARTY_SERVICES } from '../../../constants';

export interface Props {
    url: string | string[];
    autoPlay?: boolean;
    muted?: boolean;
    loop?: boolean;
    controls?: boolean;
}

function CascadedVideoPlayer({
    url,
    ApplicationStore,
    autoPlay = false,
    muted = true,
    loop = false,
    controls = true,
}: Props & { ApplicationStore: TypeApplicationStore }) {
    if (typeof url === 'string') {
        return (
            <VideoPlayer
                url={url}
                autoPlay={autoPlay}
                loop={loop}
                muted={muted}
                controls={controls}
            />
        );
    }

    const preferredVideoProvider =
        ApplicationStore.thirdPartyServices?.getVideoProvider() as ThirdPartyServicesTypes;

    if (!preferredVideoProvider) {
        return null;
    }

    const videoProviderInfo = THIRD_PARTY_SERVICES[preferredVideoProvider];
    const preferredVideo = url.find(
        (u) => new URL(u).hostname === new URL(videoProviderInfo.url).hostname
    );
    const fallbackVideo = url.find((u) => u.includes('media.gowwise.com'));

    return (
        <VideoPlayer
            autoPlay={autoPlay}
            muted={muted}
            url={preferredVideo || fallbackVideo || 'https://badurl'}
        />
    );
}

function VideoPlayer({
    url,
    autoPlay = false,
    muted = true,
    loop = false,
    controls = true,
}: {
    url: string;
    autoPlay?: boolean;
    muted?: boolean;
    loop?: boolean;
    controls?: boolean;
    onFail?(): void;
}) {
    if (url === 'TBD') return null;
    const { hostname } = new URL(url);

    switch (hostname) {
        case 'youtu.be':
        case 'www.youtube.com':
            url = url.replace(/youtu.be\/|youtube.com\/watch\?v=/g, 'youtube.com/embed/');
            return <YouTubeIFrame url={url} />;
        case 'player.bilibili.com':
            return <BilibiliIFrame url={url} />;
        default:
            // Assume any other URL is a .mpd manifest OR media.gowwise.com/videos/
            return (
                <DashPlayer
                    src={url}
                    className='player'
                    size='fill'
                    autoPlay={autoPlay}
                    muted={muted}
                    loop={loop}
                    controls={controls}
                />
            );
    }
}

function YouTubeIFrame({ url }: { url: string }) {
    return (
        <iframe
            className='player'
            src={url}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
        />
    );
}

function BilibiliIFrame({ url }: { url: string }) {
    return (
        <iframe
            className='player'
            src={url}
            title='Bilibili video player'
            scrolling='no'
            frameBorder='0'
            allowFullScreen
        />
    );
}

export default injectStores(['ApplicationStore'])(CascadedVideoPlayer);
