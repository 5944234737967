interface UserAccount {
    hasCompany: boolean;
    hasProjects: boolean;
    hasSchool: boolean;
    hasSubscriptions: boolean;
    isVerified: boolean;
}

export default (userAccount: UserAccount) => {
    const { hasCompany, hasProjects, hasSchool, isVerified } = userAccount;
    return [
        {
            target: { module: 'PROFILE', subModule: 'PROFILE' },
            includeInOptionList: true,
            disableOption: false,
        },
        {
            target: { module: 'GENERAL', subModule: 'FCRM_MY_PROJECTS' },
            includeInOptionList: hasProjects,
            disableOption: !isVerified,
        },
        {
            target: { module: 'LICENSES_SUBSCRIPTIONS', subModule: 'LICENSES_SUBSCRIPTIONS' },
            includeInOptionList: true,
            disableOption: !isVerified,
        },
        {
            target: { module: 'GENERAL', subModule: 'FCRM_MY_ORGANIZATIONS' },
            includeInOptionList: hasCompany || hasSchool,
            disableOption: !isVerified,
        },
        // Keeping for posterity
        // {
        //     target: { module: 'GENERAL', subModule: 'FCRM_MY_COMPANY' },
        //     includeInOptionList: hasCompany,
        //     disableOption: !isVerified,
        // },
        // {
        //     target: { module: 'GENERAL', subModule: 'FCRM_MY_SCHOOL' },
        //     includeInOptionList: hasSchool,
        //     disableOption: !isVerified,
        // },
        {
            target: { module: 'GENERAL', subModule: 'FCRM_REGISTERED_PROJECTS' },
            includeInOptionList: true,
            disableOption: !isVerified,
        },
        {
            target: {
                // module: 'GENERAL',
                // subModule: 'FCRM_MY_SUPPORT_TICKETS',
                module: 'CUSTOMERS',
                subModule: 'SUPPORT_TICKETS',
            },
            includeInOptionList: hasProjects,
            disableOption: !isVerified,
        },
        {
            target: { module: 'GENERAL', subModule: 'IN_GAME_CREDITS' },
            includeInOptionList: hasProjects,
            disableOption: !isVerified,
        },
        {
            target: {
                module: 'SETTINGS',
                subModule: 'ACCOUNT_SETTINGS',
            },
            includeInOptionList: true,
            disableOption: false,
        },
    ] as const;
};
