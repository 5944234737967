import React from 'react';
import DivButton from '../../common/legos/div-button/div-button';

import styles from './text-ornaments.module.scss';

export function EmailOrnament() {
    return {
        startAdornment: (
            <i className={`material-icons ${styles.textFieldOrnamentStart}`}>mail_outline</i>
        ),
    };
}

export type PasswordOrnamentProps = {
    passwordVisible: boolean;
    setPasswordVisible: (setPasswordVisible: boolean) => void;
};
export function PasswordOrnament(props: PasswordOrnamentProps) {
    const { passwordVisible, setPasswordVisible } = props;
    return {
        startAdornment: (
            <i className={`material-icons-outlined ${styles.textFieldOrnamentStart}`}>lock</i>
        ),
        endAdornment: (
            <DivButton
                onClick={() => setPasswordVisible(!passwordVisible)}
                className={styles.ornamentButton}
            >
                <i className={`material-icons-outlined ${styles.textFieldOrnamentEnd}`}>
                    {passwordVisible ? 'visibility' : 'visibility_off'}
                </i>
            </DivButton>
        ),
    };
}
