/* eslint-disable no-console */
import { changeLanguage } from 'i18next';
import Cookies from 'js-cookie';

import { loadI18n } from '../i18n/i18n';

import { COOKIE_USER_LOCALE } from '../constants';

import { SupportedLocales, supportedLocales } from '../i18n/supported-locales';

import ApplicationStore from '../stores/application-store';
import { isJakhubForChina, languageToLocale } from '../utils/utils';

const { getBackendEnvironmentId, isBackendAlive, setStoreAttribute, setUserLocale } =
    ApplicationStore;

export const preLoadAppResources = (): void => {
    const langSegment = ((url) => {
        const segments = url.substring(1).split('/');
        if (segments[0]) {
            return supportedLocales
                .map((locale) => locale.split('-')[0])
                .find((lang) => lang === segments[0]);
        }
    })(window.location.pathname);
    const languageCookie = Cookies.get(COOKIE_USER_LOCALE);
    const localeFromLanguage = languageCookie && languageToLocale(languageCookie);

    const defaultUserLanguage = isJakhubForChina() ? SupportedLocales.ZH_CN : supportedLocales[0];
    const userLocale =
        (langSegment && languageToLocale(langSegment)) || localeFromLanguage || defaultUserLanguage;

    console.log('--**********************************************************--');
    console.log('--**                                                      **--');
    console.log('--**  [ userLocale  :  ', userLocale, '                         ]  **--');
    console.log('--**  [       from locale from url segment             ]  **--');
    console.log('--**  [ or    from locale in cookies                   ]  **--');
    console.log('--**  [ or    from locale from language in cookies     ]  **--');
    console.log('--**  [ or    from default locale in supportedLocales  ]  **--');
    console.log('--**                                                      **--');
    console.log('--**********************************************************--');

    setUserLocale(userLocale);

    setStoreAttribute('i18nLoaded', false);
    isBackendAlive();
    getBackendEnvironmentId();

    loadI18n(userLocale, () => {
        console.log('DONE LOADING I18N STUFF');
        console.log('This is where we should stop the loader component!');
        changeLanguage(userLocale);
        setStoreAttribute('i18nLoaded', true);
    });
};
export default preLoadAppResources;
