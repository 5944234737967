import { Instance, types } from 'mobx-state-tree';

export const UserProfile = types.model('UserProfile', {
    displayName: '',
    hasCompany: false,
    hasProjects: false,
    hasSchool: false,
    hasSubscriptions: false,
    isVerified: false,
    locale: 'en_US',
    itemsInCart: 0,
    unreadMessages: 0,
});

export type TypeUserProfileModel = Instance<typeof UserProfile>;
export default UserProfile;
