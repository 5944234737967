import { Instance, types } from 'mobx-state-tree';

export enum VIDEO_PROVIDERS {
    YOUTUBE = 'YOUTUBE',
    BILIBILI = 'BILIBILI',
    NONE = 'NONE',
}

const InformationModel = types.model('ExternalInformationModel', {
    id: 0,
    title: '',
    url: '',
    publishedDate: types.optional(types.string, ''),
    locale: '',
    tags: types.array(types.string),
    description: types.maybeNull(types.string),
    author: types.maybeNull(
        types.model('author', {
            authorName: '',
            authorUrl: '',
        })
    ),
    imageUrl: types.maybeNull(types.string),
    provider: types.maybeNull(
        types.enumeration<VIDEO_PROVIDERS>('VIDEO_PROVIDERS', Object.values(VIDEO_PROVIDERS))
    ),
    videoUrl: types.maybeNull(types.string),
    requiredService: types.maybeNull(types.string),
    views: 0.0,
});
const ExternalInformationModel = types.snapshotProcessor(InformationModel, {
    preProcessor(sn: TypeInformationModel) {
        return {
            ...sn,
            provider: sn?.provider?.toUpperCase(),
            publishedDate: sn.publishedDate?.split('-')?.join('/'),
        } as TypeInformationModel;
    },
});

type TypeInformationModel = Instance<typeof InformationModel>;
export type TypeExternalInformationModel = Instance<typeof ExternalInformationModel>;

export default ExternalInformationModel;
