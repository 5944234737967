import { HARD_PATHS } from '../utils/hard-paths';

const moduleRoot = `/solutions`;

export const PATHS = {
    SOLUTIONS: {
        path: `${moduleRoot}`,
    },
    GAMING: {
        path: `${moduleRoot}/gaming`,
    },
    AUTOMOTIVE: {
        path: `${moduleRoot}/automotive`,
    },
    LBE: {
        path: `${moduleRoot}/lbe`,
    },
    SIMULATION: {
        path: `${moduleRoot}/simulation`,
    },
    CONSUMER_ELECTRONICS: {
        path: `${moduleRoot}/consumer-electronics`,
    },
    CREATIVE_SERVICES: {
        path: `${moduleRoot}/creative_services`,
        hardPath: HARD_PATHS.SOLUTIONS.CREATIVE_SERVICES,
    },
    DISCOVER: {
        path: `${moduleRoot}/discover`,
        hardPath: HARD_PATHS.SOLUTIONS.DISCOVER,
    },
    DISCOVER_LBE: {
        id: 'DISCOVER_LBE',
        path: `${moduleRoot}/discover/wwise-in-lbe/`,
        hardPath: HARD_PATHS.SOLUTIONS.DISCOVER_LBE,
    },
};
