import { Instance, types } from 'mobx-state-tree';

const NewsListPageModel = types.model('NewsListPagesModel', {
    creationTime: types.string,
    url: types.string,
    title: types.string,
    body: types.string,
    summary: types.string,
});

export const NewsPagesModel = types.model('NewsPagesModel', {
    content: types.string,
    url: types.string,
});

const NewsListModel = types.model('NewsListModel', {
    news: types.array(NewsListPageModel),
    newsCount: types.maybeNull(types.number),
});
export type TypeNewsListModel = Instance<typeof NewsListModel>;
export type TypeNewsListPageModel = Instance<typeof NewsListPageModel>;
export type TypeNewsPagesModel = Instance<typeof NewsPagesModel>;

export default NewsListModel;
