// should depricate these & use SupportedLocales
export const EN_US = 'en-US';
export const FR_CA = 'fr-CA';
export const JA_JP = 'ja-JP';
export const KO_KR = 'ko-KR';
export const ZH_CN = 'zh-CN';

export enum SupportedLocales {
    EN_US = 'en-US',
    FR_CA = 'fr-CA',
    JA_JP = 'ja-JP',
    KO_KR = 'ko-KR',
    ZH_CN = 'zh-CN',
}

/*
 *
 * The first language specified
 * in this list will be used as THE
 * default locale throughout the
 * application
 *
 */
export const supportedLocales = [
    SupportedLocales.EN_US,
    SupportedLocales.FR_CA,
    SupportedLocales.JA_JP,
    SupportedLocales.KO_KR,
    SupportedLocales.ZH_CN,
];

export const supportedLanguages = supportedLocales.map((l) => l.split('-')[0]);

export type SupportedLocale = typeof supportedLocales[number];
export type SupportedLanguages = typeof supportedLanguages[number];
