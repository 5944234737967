import React from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { find, includes, map, pickBy } from 'lodash';

import {
    Module,
    ModuleRoute,
    ModuleId,
    RouteId,
    TargetType,
} from '../../modules/utils/module-interfaces';
import { getSelectedModules, getPredicatedModules } from '../../modules/utils/module-utils';
import { MODULES } from '../../modules/modules';

import { TypeApplicationStore } from '../../stores/application-store';
import { injectStores } from '../../stores/factories/store-utils';

import SwitchLink from '../menus/menu/switch-link/switch-link';
import ModuleExpander from './module-expander';

import '../menus/footer/footer.scss';
import '../menus/menu/main-menu/menu.scss';

const getLocalizedModules = (modules: any, userLocale: string) =>
    find(modules, 'supportedLocales')
        ? pickBy(modules, (link) => includes(link.supportedLocales, userLocale))
        : modules;

interface MainLinkProps {
    children: JSX.Element | null;
    module: Module;
    menuState: Record<string, string>;
    suppressParent: boolean;
    onLinkClick?: () => void;
    isMobileMenu: boolean;
    expandedModule: string;
    toggleExpandedModule: () => void;
}

function MainLink(props: MainLinkProps) {
    const { t } = useTranslation();
    const {
        children,
        module,
        menuState,
        suppressParent,
        isMobileMenu,
        expandedModule,
        toggleExpandedModule,
    } = props;

    const { currentModuleId } = menuState;

    const className = classNames('link', { link__selected: module.id === currentModuleId });

    const modnavClassName = classNames('modnav-main-link', { 'mobile-menu-item': isMobileMenu });

    return (
        <div className='modnav-main'>
            {!suppressParent && (
                <div className={modnavClassName}>
                    <SwitchLink
                        akTestId={`MainLink|LinkTo|${module.id}|${module.defaultRoute.id}`}
                        to={{ module: module.id, subModule: module.defaultRoute.id }}
                        className={className}
                        key={`${module.id}|${module.id}`}
                    >
                        {t(module.i18nId || 'no-module-id')}
                    </SwitchLink>
                    <ModuleExpander
                        onClick={() => toggleExpandedModule()}
                        isExpanded={module.id === expandedModule}
                    />
                </div>
            )}
            {!isMobileMenu || module.id === expandedModule ? children : null}
        </div>
    );
}

interface SubLinksProps<M extends ModuleId> {
    routes: ModuleRoute<RouteId<M>>[];
    module: Module<M>;
    menuState: Record<string, string>;
    showParentModuleName: boolean;
    isMobileMenu: boolean;
}

function SubLinks<M extends ModuleId>(props: SubLinksProps<M>) {
    const { t } = useTranslation();
    const { routes, module, menuState, showParentModuleName, isMobileMenu } = props;
    const { currentSubModuleId } = menuState;

    const subLinkClass = classNames('modnav-main-sub-link', {
        'modnav-main-sub-link__mobile-menu': isMobileMenu,
    });

    const parentNameClass = classNames(
        'modnav-main-sub-link-parent',
        { 'modnav-main-sub-link-parent__active': showParentModuleName },
        { selected: currentSubModuleId === '' }
    );

    return (
        <div className={subLinkClass}>
            <SwitchLink
                akTestId={`SubLinks|LinkTo|${module.id}|${module.defaultRoute.id.toString()}`}
                to={{ module: module.id, subModule: module.defaultRoute.id } as TargetType<M>}
                className={parentNameClass}
                key={`${module.id}|${module.id}`}
            >
                {t(module.i18nId || 'no-module-id')}
            </SwitchLink>
            {map(routes, (route) => {
                const className = classNames('link', {
                    link__selected: route.id === currentSubModuleId,
                });

                return (
                    <SwitchLink
                        akTestId={`SubLinks|LinkTo|${module.id}|${route.id.toString()}`}
                        to={{ module: module.id, subModule: route.id } as TargetType<M>}
                        className={className}
                        key={`${module.id}|${String(route.id)}`}
                    />
                );
            })}
        </div>
    );
}

export interface Props {
    ApplicationStore: TypeApplicationStore;
    selectedModules?: ModuleId[];
    predicate?: (module: ModuleRoute) => boolean;
    menuState?: Record<string, string>;
    className: string;
    goDeep?: boolean;
    isMobileMenu?: boolean;
    showParentModuleName?: boolean;
}

function ModuleNavigation(props: Props) {
    const {
        ApplicationStore: {
            headerTools: { openedHeaderTool },
            userLocale,
            showToolInHeader,
        },
        className = '',
        selectedModules,
        predicate = () => true,
        menuState = { currentModuleId: '', currentSubModuleId: '' },
        goDeep = true,
        isMobileMenu = false,
        showParentModuleName = false,
    } = props;

    let modulesToRender = getSelectedModules(MODULES, selectedModules);

    // Prefix the URLs in the menu with the current language slug:
    modulesToRender = getLocalizedModules(modulesToRender, userLocale);

    if (!selectedModules) {
        modulesToRender = getPredicatedModules(modulesToRender, predicate);
    }

    return (
        <div className={className}>
            {map(modulesToRender, (module: Module) => (
                <MainLink
                    key={`main-link|${module?.id || 'no-module-id'}`}
                    module={module}
                    isMobileMenu={isMobileMenu}
                    suppressParent={!!selectedModules}
                    menuState={menuState}
                    expandedModule={openedHeaderTool}
                    toggleExpandedModule={() => showToolInHeader(module.id)}
                >
                    {goDeep ? (
                        <SubLinks
                            module={module}
                            isMobileMenu={isMobileMenu}
                            menuState={menuState}
                            showParentModuleName={showParentModuleName}
                            routes={getLocalizedModules(
                                getPredicatedModules(module.routes, predicate),
                                userLocale
                            )}
                        />
                    ) : null}
                </MainLink>
            ))}
        </div>
    );
}

export default injectStores(['ApplicationStore'])(ModuleNavigation);
