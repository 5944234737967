import React from 'react';
import { map } from 'lodash';
import { FieldErrors, FieldValues } from 'react-hook-form';

import { useJakhubTranslation } from '../../../i18n/jakhub-translation';

import styles from './error-message.module.scss';

type Props = {
    fieldId: string;
    fieldValue?: any;
    errors: FieldErrors<FieldValues>;
    instructions?: string[];
    submitAttempted: boolean;
};
export default function ErrorMessages(props: Props) {
    const { t } = useJakhubTranslation();
    const { fieldId, errors, instructions = [], submitAttempted, fieldValue } = props;

    const fieldErrors = new Set(map(errors?.[fieldId]?.types, (t) => t));
    const fieldInstructions = new Set([...instructions, ...Array.from(fieldErrors)]);

    return (
        <div key={`${fieldId}_error_container`} className={styles.errorMessages}>
            {Array.from(fieldInstructions).map((inst) => {
                const messageStyle = `
                    ${styles.errorMessage}
                    ${!fieldErrors.has(inst) && !!fieldValue ? styles.errorMessageOk : ''}
                    ${fieldErrors.has(inst) && submitAttempted ? styles.errorMessageError : ''}
                `;
                return (
                    <div key={`${fieldId}_${inst}`} className={messageStyle}>
                        {t(`general:fieldValidation.${inst}`)}
                    </div>
                );
            })}
        </div>
    );
}
