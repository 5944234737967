import { types } from 'mobx-state-tree';

export const ChangeEmailConfirmationModel = types.model('ChangeEmailConfirmationModel', {
    code: types.maybeNull(types.number),
    err: types.maybeNull(types.string),
    random: types.maybeNull(types.string),
    jwt: types.maybeNull(types.string),
    redirect: types.maybeNull(types.string),
    specialAction: types.maybeNull(types.boolean),
});

export default ChangeEmailConfirmationModel;
