import * as en from './en-US/index';
import * as fr from './fr-CA/index';
import * as ja from './ja-JP/index';
import * as ko from './ko-KR/index';
import * as zh from './zh-CN/index';

export const locales = {
    'en-US': en,
    'fr-CA': fr,
    'ja-JP': ja,
    'ko-KR': ko,
    'zh-CN': zh,
};
