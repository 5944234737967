import { Instance, types } from 'mobx-state-tree';

import AddressModel from '../../models/address-model';

const RelatedEntityModel = types.model('RelatedEntityModel', {
    address: AddressModel,
    companyLogoUrl: '',
    jobTitle: '',
    name: '',
    orgType: '',
    publiclyVisible: true,
    role: '',
    wwiseAmbassador: false,
});

export type TypeRelatedEntityModel = Instance<typeof RelatedEntityModel>;
export default RelatedEntityModel;
