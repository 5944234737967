import { Instance, types } from 'mobx-state-tree';
import { lowerSnakeCase, stripNonAlphaNumeric } from '../../../utils/utils';

const ItemModel = types.model('CarouselItemsModel', {
    id: types.string,
    title: types.string,
    description: types.maybeNull(types.string),
    category: types.maybeNull(types.string),
    iconUrl: types.string,
    certificateUrl: types.maybeNull(types.string),
    ProdUrl: 'TemporaryProdUrlHolder',
    url: '',
    price: types.maybeNull(types.string),
});
export type TypeItemModel = Instance<typeof ItemModel>;

const CarouselItemsModel = types.snapshotProcessor(ItemModel, {
    preProcessor(sn: TypeItemModel) {
        const imageLocation = `${sn.iconUrl.split('/2022/')[0]}/2022/`;

        //  From BE will change eventually -> New learning course page
        //  education/learn-wwise#wwise101 -> learning/learn-wwise/wwise101
        //  remove this once FCRM-4801 is deployed
        return {
            ...sn,
            url: sn.url
                .replace('education/learn-wwise#', 'learning/certifications/')
                .replace('#', '/'),
            id: sn.id || lowerSnakeCase(stripNonAlphaNumeric(sn.title)),
            certificateUrl: `${imageLocation}ak_user_certificate_${sn.id}.png`,
        };
    },
});

export type TypeCarouselItemsModel = Instance<typeof CarouselItemsModel>;

export default CarouselItemsModel;
