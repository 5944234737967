import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { injectStores } from '../../../stores/factories/store-utils';
import { ContentRendererProps } from '../../common/editable-user-data/editable-user-data';
import { TypeProfileStore } from '../../../stores/profile-store';

import { isHttpLink } from '../../../utils/utils';

type Props = ContentRendererProps & {
    ProfileStore: TypeProfileStore;
};
function ProfileStoreRedirectDetector(props: Props) {
    const {
        ProfileStore: {
            profileStoreWantsToGoTo: { goto },
            clearGoto,
        },
    } = props;
    const history = useHistory();

    useEffect(() => {
        clearGoto();
    }, []);

    if (goto && goto !== 'STAY_AT_URL') {
        isHttpLink(goto) ? (window.location.href = goto) : history.push(goto);
    }

    return null;
}

export default injectStores(['ProfileStore'])(ProfileStoreRedirectDetector);
