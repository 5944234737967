import { Instance, types } from 'mobx-state-tree';

const SupportPlanModel = types.model('SupportPlanModel', {
    expirationDate: types.string,
    isUnlimited: types.boolean,
    planId: types.number,
    planType: types.string,
    ticketsAllowed: types.number,
    ticketsLeft: types.number,
});
export type TypeSupportPlanModel = Instance<typeof SupportPlanModel>;

export const ProjectModel = types.model('ProjectModel', {
    id: 0,
    projectMask: '',
    projectName: '',
    projectId: 0,
    codeName: '',
    description: '',
    supportPlans: types.array(SupportPlanModel),
    activeSupportPlan: types.maybeNull(SupportPlanModel),
});

export type TypeProjectModel = Instance<typeof ProjectModel>;
export default ProjectModel;
