import StoreFactory from '../factories/store';

// import ApiActionFactory from '../../api/api-action-factory';

import { Instance, types } from 'mobx-state-tree';

import { LibraryModel } from './models/library-model';

const LibraryStore = StoreFactory({
    modeName: 'LibraryStore',
    models: [],
    modelStructure: {
        libraries: types.array(LibraryModel),
    },
});

export type TypeLibraryStore = Instance<typeof LibraryStore>;
export default LibraryStore;
