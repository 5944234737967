/* eslint-disable no-param-reassign */
import { Instance } from 'mobx-state-tree';

import { isDev } from '../../utils/build-utils';

import StoreFactory from '../factories/store';

const JobStore = StoreFactory({
    modelName: 'JobStore',
    models: [],
    modelStructure: {
        isLoading: false,
        title: 'JobStore',
    },
});

export type TypeJobStore = Instance<typeof JobStore>;

const JobStoreInstance = JobStore.create();
declare const window: Window &
    typeof globalThis & {
        JobStore: typeof JobStoreInstance;
    };

if (window && isDev()) {
    window.JobStore = JobStoreInstance;
}

export default JobStore;
