import { Instance, types } from 'mobx-state-tree';

export enum MediaType {
    IMAGE = 'image',
    VIDEO = 'video',
}

export const MediaModel = types.model('MediaModel', {
    name: types.string,
    type: types.enumeration<MediaType>('MediaType', Object.values(MediaType)),
    url: types.maybe(
        types.union(
            types.string,
            types.model({
                highRes: types.string,
                lowRes: types.string,
                thumbnail: types.string,
            })
        )
    ),
});

export type TypeMediaModel = Instance<typeof MediaModel>;
export default MediaModel;
