import { Instance, types } from 'mobx-state-tree';

const BannerMessageModel = types.model('BannerMessageModel', {
    publishDateStart: '1970-01-01',
    publishDateEnd: '1970-01-01',
    cookieResetDate: '2099-12-31',
    cookieExpiry: 30,
    message_en_US: '',
    message_fr_CA: '',
    message_ja_JP: '',
    message_ko_KR: '',
    message_zh_CN: '',
});
export type TypeBannerMessageModel = Instance<typeof BannerMessageModel>;
export default BannerMessageModel;
